import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, ChevronUp, Search } from "lucide-react";

const PayorInformationForm = ({ formData, setFormData, payorList }) => {
  const [payorOptions, setPayorOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allPayors, setAllPayors] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const paymentOption = formData.paymentType || "";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const formattedPayors = Object.entries(payorList).map(([key, value]) => ({
      value: key,
      label: value.toString(),
    }));

    setAllPayors(formattedPayors);

    const randomPayors = [...formattedPayors]
      .sort(() => 0.5 - Math.random())
      .slice(0, 5);

    setPayorOptions(randomPayors);
  }, [payorList]);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    if (searchValue.trim() === "") {
      const randomPayors = [...allPayors]
        .sort(() => 0.5 - Math.random())
        .slice(0, 5);
      setPayorOptions(randomPayors);
    } else {
      const filteredPayors = allPayors.filter((payor) =>
        payor.label.toString().toLowerCase().includes(searchValue.toLowerCase())
      );
      setPayorOptions(filteredPayors);
    }
  };

  const handlePayorOptionChange = (e) => {
    const value = e.target.value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      paymentType: value,
      payorName: value === "self" ? "self" : "",
    }));
  };

  const handlePayorSelection = (selectedPayor) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      payorName: selectedPayor,
    }));
    setIsOpen(false);
  };

  return (
    <div className="space-y-4">
      <h1 className="text-xl font-bold text-center">Payor Information</h1>

      <label className="block mb-2">Member Id</label>
      <input
        type="text"
        placeholder="Member Id"
        name="memberId"
        value={formData.memberId}
        onChange={(e) => setFormData({ ...formData, memberId: e.target.value })}
        className="w-full px-3 py-2 border border-gray-300 rounded-md"
      />

      <label className="block mb-2">Payor Name</label>
      <select
        name="paymentOption"
        value={paymentOption}
        onChange={handlePayorOptionChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="">Select Payment Option</option>
        <option value="self">Self</option>
        <option value="payor">Payor</option>
      </select>

      {paymentOption === "self" && (
        <div className="text-red-500">
          <span className="font-bold">*</span> You have to pay by yourself,
          because you chose self.
        </div>
      )}

      {paymentOption === "payor" && (
        <div className="relative" ref={dropdownRef}>
          <label className="block mb-2">Select Payor</label>
          <div
            className="w-full px-3 py-2 border border-gray-300 rounded-md cursor-pointer flex justify-between items-center bg-white"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="truncate">
              {formData.payorName || "Select a Payor"}
            </span>
            {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>

          {isOpen && (
            <div
              className="left-0 right-0 z-50 mt-1 bg-white border border-gray-300 rounded-md shadow-lg"
              style={{ maxHeight: "300px", overflow: "scroll" }}
            >
              <div className="sticky top-0 bg-white p-2 border-b border-gray-200 z-50">
                <div className="relative">
                  <Search
                    className="absolute left-2 top-2.5 text-gray-400"
                    size={16}
                  />
                  <input
                    type="text"
                    placeholder="Search payors..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>

              <div className="overflow-y-auto" style={{ maxHeight: "240px" }}>
                {payorOptions.map((option) => (
                  <div
                    key={option.value}
                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handlePayorSelection(option.label)}
                  >
                    {option.label}
                  </div>
                ))}
                {payorOptions.length === 0 && (
                  <div className="px-3 py-2 text-gray-500 text-center">
                    No payors found
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PayorInformationForm;
