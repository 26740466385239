import React from "react";
import {
  Activity,
  AlertCircle,
  Shield,
  Users,
  TestTube,
  HeartPulse,
  Clock,
} from "lucide-react";
import { Link } from "react-router-dom";
import { AnimatedButton } from "./Animatedbtn";
import Influenzaimg from "../../images/influenza.jpg";

function InfoBox({ title, children, icon: Icon }) {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow border border-gray-700">
      <div className="flex items-center gap-3 mb-4">
        <Icon className="w-6 h-6 text-purple-400" />
        <h3 className="text-xl font-bold text-white">{title}</h3>
      </div>
      <div className="text-gray-300">{children}</div>
    </div>
  );
}

function Influenza() {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center py-24"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Influenzaimg})`,
        }}
      >
        <div className="container mx-auto px-6">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-purple-400">
            Understanding Influenza A & B: More than Just a Seasonal Threat
          </h1>
          <p className="text-xl md:text-2xl max-w-3xl text-gray-300">
            Recognizing the critical differences between influenza types and the
            importance of early detection.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-6 py-12">
        {/* Introduction */}
        <div className="prose prose-invert max-w-none mb-12">
          <p className="text-xl text-gray-300 leading-relaxed">
            When we talk about "the flu," we're actually referring to a
            sophisticated family of viruses that have been evolving alongside
            humans for centuries. Influenza A and B represent the two main types
            that cause seasonal epidemics, and understanding their distinct
            characteristics could make all the difference in how quickly you
            seek treatment and recover.
          </p>
        </div>

        {/* Symptoms Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          <InfoBox title="Initial Phase (24 Hours)" icon={Clock}>
            <ul className="list-disc list-inside space-y-2">
              <li>Intense fatigue</li>
              <li>Chills and fever</li>
              <li>Severe muscle aches</li>
              <li>Headache behind eyes</li>
              <li>Dry cough</li>
            </ul>
          </InfoBox>

          <InfoBox title="Progressive Symptoms" icon={Activity}>
            <ul className="list-disc list-inside space-y-2">
              <li>High fever (101°F-104°F)</li>
              <li>Increased respiratory symptoms</li>
              <li>Worsening body aches</li>
              <li>Gastrointestinal symptoms</li>
              <li>Extreme exhaustion</li>
            </ul>
          </InfoBox>

          <InfoBox title="Emergency Warning Signs" icon={AlertCircle}>
            <ul className="list-disc list-inside space-y-2">
              <li>Difficulty breathing</li>
              <li>Chest pain or pressure</li>
              <li>Confusion</li>
              <li>Severe muscle pain</li>
              <li>Bluish face or lips</li>
            </ul>
          </InfoBox>
        </div>

        {/* Benefits Section */}
        <div className="bg-gray-800 rounded-xl p-8 mb-12">
          <h2 className="text-3xl font-bold mb-6 text-purple-400">
            Why Early Detection Matters
          </h2>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 mb-10 gap-6">
            <div className="flex gap-4">
              <Shield className="w-8 h-8 text-purple-400 flex-shrink-0" />
              <p className="text-gray-300">
                Reduced severity and duration of symptoms
              </p>
            </div>
            <div className="flex gap-4">
              <TestTube className="w-8 h-8 text-purple-400 flex-shrink-0" />
              <p className="text-gray-300">
                Access to effective antiviral treatments
              </p>
            </div>
            <div className="flex gap-4">
              <Users className="w-8 h-8 text-purple-400 flex-shrink-0" />
              <p className="text-gray-300">Protect vulnerable family members</p>
            </div>
          </div>

          {/* <AnimatedButton>
                <Link to="/form/influenza" className="w-full sm:w-auto">
                    <div className="flex items-center gap-2">
                        <HeartPulse size={20} />
                        <span>Get Tested</span>
                        
                    </div>
                </Link>
            </AnimatedButton> */}
          <AnimatedButton>
            <Link to="/form/influenza" className="w-full sm:w-auto">
              <div className="flex items-center gap-2">
                <HeartPulse size={20} />
                <span>Get Tested</span>
              </div>
            </Link>
          </AnimatedButton>
        </div>

        {/* High Risk Groups */}
        <div className="bg-gray-800 rounded-xl p-8">
          <h2 className="text-3xl font-bold mb-6 text-purple-400">
            High Risk Groups
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            <ul className="list-disc list-inside space-y-3 text-gray-300">
              <li>Children under 5, especially under 2</li>
              <li>Adults 65 and older</li>
              <li>Pregnant women</li>
              <li>People with chronic health conditions</li>
              <li>Healthcare and essential workers</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Influenza;
