import React from "react";

function ErrorMessage({ message }) {
  return (
    <div className="w-full max-w-md mx-auto  mb-4 p-4 bg-red-100 border border-red-500 text-red-700 rounded-lg shadow-lg ">
      <h3 className="font-semibold text-lg">Error</h3>
      <p>{message}</p>
    </div>
  );
}

export default ErrorMessage;
