import React from "react";

const resourcesLinks = [
  { href: "feedback", text: "Feedback" },
  { href: "contact", text: "Contact us" },
];

const legal = [
  { href: "tcpa-compliance", text: "TCPA Compliance" },
  { href: "privacy-policy", text: "Privacy Policy" },
  { href: "terms-of-service", text: "Terms of Service" },
];

const info = [
  { href: "about", text: "About us" },
  { href: "guide", text: "Guide" },
];

function Footer() {
  return (
    <footer className="flex justify-center mt-10 border-t py-10 border-blue-900 bg-transparent bg-opacity-30 backdrop-blur-md">
      <div className="grid grid-cols-2 justify-center items-center lg:grid-cols-3 gap-32">
        <div>
          <h3 className="text-lg font-semibold text-white mb-4">Resources</h3>
          <ul className="space-y-4">
            {resourcesLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href} className="text-white hover:text-white">
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-semibold text-white mb-4">Legal</h3>
          <ul className="space-y-4">
            {legal.map((link, index) => (
              <li key={index}>
                <a href={link.href} className="text-white hover:text-white">
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-semibold text-white mb-4">Information</h3>
          <ul className="space-y-4">
            {info.map((link, index) => (
              <li key={index}>
                <a href={link.href} className="text-white hover:text-white0">
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
