import React from "react";
import spinnerGif from "../../images/heartbeat.gif";

function LoadingSpinner() {
  return (
    <div className="flex items-center justify-center h-full">
      <img src={spinnerGif} alt="Loading..." className="h-20 w-20" />
    </div>
  );
}

export default LoadingSpinner;
