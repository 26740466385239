import React, { useRef } from "react";
import icon from "../../images/icon.png";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import formDatas from "../questionData/formDatas.js";

function Tests() {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -500,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 500,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <div className="relative z-10">
        <div className="relative text-center z-10 p-4">
          <span className="h-6 text-lg text-gray-100 font-roman overflow-hidden font-bold uppercase">
            -------------- &nbsp; Our Featured Tests &nbsp; --------------
          </span>
        </div>
      </div>

      <div className="flex items-center justify-center mt-5 lg:mt-5">
        <button
          onClick={scrollLeft}
          className="relative z-10 text-gray-200 p-4 text-md hover:bg-blue-900/20 rounded-full"
        >
          <FaChevronLeft />
        </button>

        <div
          ref={scrollRef}
          className="flex overflow-x-auto overflow-y-hidden scrollbar-hide scroll-smooth gap-4 max-w-[calc(100vw-120px)]"
          style={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {Object.keys(formDatas).map((key) => (
            <div key={key} className="flex-shrink-0 w-[300px]">
              <div className="bg-transparent bg-opacity-30 tracking-wide backdrop-blur-md border border-blue-300 rounded-lg p-4 flex flex-col justify-between items-start h-full shadow-sm shadow-blue-600 transition-transform hover:scale-105">
                <div className="flex mb-2 items-center">
                  <img src={icon} alt="Lab Test Icon" className="h-10 w-10" />
                </div>
                <div className="w-full space-y-2">
                  <h5 className="text-[20px] font-semibold font-sans overflow-x-auto w-full inline-block whitespace-normal overflow-hidden overflow-ellipsis line-clamp-1 text-gray-100">
                    {key.charAt(0).toUpperCase() + key.slice(1)} (
                    {formDatas[key][0]["testfullname"]})
                  </h5>
                </div>
                <Link
                  to={`/form/${key}`}
                  className="mt-4 text-gray-100 border border-blue-400 hover:border-blue-900 py-2 px-4 rounded-md"
                >
                  Submit form
                </Link>
              </div>
            </div>
          ))}
        </div>

        <button
          onClick={scrollRight}
          className="relative z-10 text-gray-200 p-4 text-md hover:bg-blue-900/20 rounded-full"
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
}

export default Tests;
