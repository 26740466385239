import React, { useState, useEffect } from "react";
import formDataJsons from "../questionData/formDatas.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Calendar } from "lucide-react";
function DynamicForm({ testopt, formData, setFormData }) {
  const questions = formDataJsons[testopt] || [];
  const [otherVisible, setOtherVisible] = useState({});

  const handleDateChange = (date, questionId) => {
    const formattedDate = date
      ? `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      : null;

    setFormData((prevData) => ({
      ...prevData,
      [questionId]: formattedDate,
    }));
  };

  const isDatePassedOrPresent = (date) => {
    const today = new Date();
    return date <= today;
  };

  useEffect(() => {
    const initialFormData = { ...formData };
    const initialOtherVisible = {};

    questions.forEach((form) => {
      form.question?.forEach((q) => {
        if (!initialFormData[q.questionId]) {
          initialFormData[q.questionId] = "NA";
        }
        if (
          initialFormData[q.questionId] === "Yes" ||
          (typeof initialFormData[q.questionId] === "string" &&
            initialFormData[q.questionId].includes("Others"))
        ) {
          initialOtherVisible[q.questionId] = true;
        }

        q.subQuestions?.forEach((subQ) => {
          if (subQ.subQuestionType === "checkbox") {
            initialFormData[q.questionId] = initialFormData[q.questionId] || [];
          }

          if (Array.isArray(subQ.subQuestionNeedFurtherInfo)) {
            subQ.subQuestionNeedFurtherInfo.forEach((furtherQ) => {
              const furtherKey = `${q.questionId}_${furtherQ.needFurtherInfoId}`;
              if (!initialFormData[furtherKey]) {
                initialFormData[furtherKey] = "NA";
              }
            });
          }
        });
      });
    });

    setFormData(initialFormData);
    setOtherVisible(initialOtherVisible);
  }, [questions]);

  const getDisplayValue = (value, type) => {
    if (type === "text" && value === "NA") {
      return "";
    }
    return value || "";
  };

const separateMedicalStrings = (inputString) => {
  const results = [];

  let parenthesesCount = 0;
  let currentString = "";

  for (let i = 0; i < inputString.length; i++) {
    const char = inputString[i];

    if (char === "(") parenthesesCount++;
    if (char === ")") parenthesesCount--;

    if (char === "," && parenthesesCount === 0) {
      if (inputString[i + 1] === " ") {
        const trimmedString = currentString.trim();
        if (trimmedString) results.push(trimmedString);
        currentString = "";
        i++;
      }
    } else {
      currentString += char;
    }
  }

  const trimmedString = currentString.trim();
  if (trimmedString) results.push(trimmedString);

  return results;
};

const clearFurtherInfoValues = (questionId, subQuestions) => {
  const updatedFormData = { ...formData };

  subQuestions?.forEach((subQ) => {
    if (Array.isArray(subQ.subQuestionNeedFurtherInfo)) {
      subQ.subQuestionNeedFurtherInfo.forEach((furtherQ) => {
        const furtherKey = `${questionId}_${furtherQ.needFurtherInfoId}`;
        updatedFormData[furtherKey] = "NA";
      });
    }
  });
  return updatedFormData;
};

  const handleChange = (e, key, option) => {
    const isChecked = e.target.checked;
    const currentValues = separateMedicalStrings(formData[key] || "");

    let newValues = [...currentValues];
    let updatedFormData = { ...formData };

    if (option === "None of the above") {
      if (isChecked) {
        newValues = ["None of the above"];
      } else {
        newValues = currentValues.filter(
          (item) => item !== "None of the above"
        );
      }
    } else {
      if (currentValues.includes("None of the above")) {
        newValues = newValues.filter((item) => item !== "None of the above");
      }

      if (isChecked) {
        newValues.push(option);
      } else {
        newValues = newValues.filter((item) => item !== option);
        if (option === "Others") {
          const question = questions
            .flatMap((form) => form.question)
            .find((q) => q.questionId === key);

          if (question) {
            updatedFormData = clearFurtherInfoValues(
              key,
              question.subQuestions
            );
          }
        }
      }
    }

    updatedFormData[key] = newValues.filter((item) => item !== "NA").join(", ");
    setFormData(updatedFormData);

    if (option === "Others") {
      const newOtherVisible = isChecked;
      setOtherVisible((prev) => ({
        ...prev,
        [key]: newOtherVisible,
      }));
    }
  };

const handleInputChange = (e, key) => {
  const { value } = e.target;
  const newValue = value.trim() === "" ? "NA" : value;
  setFormData((prevData) => ({
    ...prevData,
    [key]: newValue,
  }));
};

  const handleRadioChange = (e, key) => {
    const value = e.target.value;
    let updatedFormData = { ...formData };

    updatedFormData[key] = value;
    if (formData[key] === "Yes" && value !== "Yes") {
      const question = questions
        .flatMap((form) => form.question)
        .find((q) => q.questionId === key);

      if (question) {
        question.subQuestions?.forEach((subQ) => {
          if (Array.isArray(subQ.subQuestionNeedFurtherInfo)) {
            subQ.subQuestionNeedFurtherInfo.forEach((furtherQ) => {
              const furtherKey = `${key}_${furtherQ.needFurtherInfoId}`;
              updatedFormData[furtherKey] = "NA";
            });
          }
        });
      }
    }

    setFormData(updatedFormData);
    setOtherVisible((prev) => ({
      ...prev,
      [key]: value === "Yes",
    }));
  };

  const renderDatePicker = (q, subQ) => {
    const selectedDate =
      formData[q.questionId] && !isNaN(Date.parse(formData[q.questionId]))
        ? new Date(formData[q.questionId])
        : null;

    return (
      <div className="relative">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => handleDateChange(date, q.questionId)}
          dateFormat="MM/dd/yyyy"
          placeholderText={subQ?.subQuestionText || "Select Date"}
          className="w-full px-2 py-2 pr-12 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          filterDate={isDatePassedOrPresent}
        />
        <Calendar
          className="absolute right-auto left-56 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
          size={20}
        />
      </div>
    );
  };
  const renderFurtherInfoQuestions = (subQ, q) => {
    if (!Array.isArray(subQ.subQuestionNeedFurtherInfo)) {
      return null;
    }

    return subQ.subQuestionNeedFurtherInfo.map((subQuestionForOtherOption) => {
      const uniqueKey = `${q.questionId}_${subQuestionForOtherOption.needFurtherInfoId}`;
      const questionText =
        subQuestionForOtherOption.needFurtherInfoHeading ||
        subQuestionForOtherOption.needFurtherInfoText;

      switch (subQuestionForOtherOption.needFurtherInfoQuestionType) {
        case "number":
          return (
            <div key={uniqueKey} className="space-y-2">
              {questionText && <p className="m-2">{questionText}</p>}
              <input
                type="number"
                placeholder={`  ${
                  subQuestionForOtherOption.needFurtherInfoPlaceholder ||
                  "Please specify"
                } `}
                value={getDisplayValue(formData[uniqueKey], "number")}
                onChange={(e) => handleInputChange(e, uniqueKey)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
          );
        case "text":
          return (
            <div key={uniqueKey} className="space-y-2">
              {questionText && <p className="m-2">{questionText}</p>}
              <input
                type="text"
                placeholder={"Please specify"}
                value={getDisplayValue(formData[uniqueKey], "text")}
                onChange={(e) => handleInputChange(e, uniqueKey)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
          );

        case "radio":
          return (
            <div key={uniqueKey} className="space-x-2">
              {questionText && <p className="mb-2">{questionText}</p>}
              {subQuestionForOtherOption.options?.map((option, index) => (
                <label key={index} className="inline-flex items-center">
                  <input
                    type="radio"
                    name={uniqueKey}
                    value={option}
                    checked={formData[uniqueKey] === option}
                    onChange={(e) => handleRadioChange(e, uniqueKey)}
                    className="mr-2"
                  />
                  {option}
                </label>
              ))}
            </div>
          );

        case "dropdown":
          return (
            <div key={uniqueKey} className="space-y-2">
              {questionText && <p className="mb-2">{questionText}</p>}
              <select
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                value={formData[uniqueKey] || ""}
                onChange={(e) => handleInputChange(e, uniqueKey)}
              >
                <option value="">Select an option</option>
                {subQuestionForOtherOption.options?.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          );

        case "checkbox":
          return (
            <div key={uniqueKey} className="space-y-2">
              {questionText && <p className="mb-2">{questionText}</p>}
              {subQuestionForOtherOption.options?.map((option, index) => (
                <label key={index} className="inline-flex items-center">
                  <input
                    type="checkbox"
                    id={`${uniqueKey}_${option}`}
                    name={option}
                    checked={formData[uniqueKey]?.includes(option) || false}
                    onChange={(e) => handleChange(e, uniqueKey, option)}
                    className="mr-2"
                  />
                  {option}
                </label>
              ))}
            </div>
          );

        case "date":
          const selectedDate =
            formData[uniqueKey] && !isNaN(Date.parse(formData[uniqueKey]))
              ? new Date(formData[uniqueKey])
              : null;

          return (
            <div key={uniqueKey} className="space-y-2">
              {questionText && <p className="mb-2">{questionText}</p>}
              <div className="relative">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => handleDateChange(date, uniqueKey)}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select Date"
                  className="w-full px-2 py-2 pr-12 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  filterDate={isDatePassedOrPresent}
                />
                <Calendar
                  className="absolute right-auto sm:right-2 left-56 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                  size={20}
                />
              </div>
            </div>
          );

        default:
          return null;
      }
    });
  };

  return (
    <div className="space-y-4">
      {questions.map((form) => (
        <div key={form.formName} className="form-group">
          <h2 className="text-xl font-bold">{form.formName}</h2>
          {form.question?.map((q) => (
            <div key={q.questionId} className="question-group">
              {q.questionHeading && (
                <h3 className="text-lg font-bold">{q.questionHeading}</h3>
              )}
              <p className="text-xl py-2">{q.questionText}</p>

              {q.subQuestions?.map((subQ) => (
                <div key={subQ.subQuestionId} className="space-y-2">
                  {subQ.subQuestionType === "checkbox" && (
                    <div className="space-y-2">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          id={`${q.questionId}_${subQ.subQuestionHeading}`}
                          name={subQ.subQuestionHeading}
                          checked={
                            formData[q.questionId]?.includes(
                              subQ.subQuestionHeading
                            ) || false
                          }
                          onChange={(e) =>
                            handleChange(
                              e,
                              q.questionId,
                              subQ.subQuestionHeading
                            )
                          }
                          disabled={
                            formData[q.questionId]?.includes(
                              "None of the above"
                            ) && subQ.subQuestionHeading !== "None of the above"
                          }
                          className="mr-2"
                        />
                        <span>{subQ.subQuestionHeading}</span>
                      </label>
                      {subQ.subQuestionHeading === "Others" &&
                        otherVisible[q.questionId] &&
                        renderFurtherInfoQuestions(subQ, q)}
                    </div>
                  )}
                  {subQ.subQuestionType === "text" && (
                    <input
                      key={`${q.questionId}`}
                      type="text"
                      placeholder={subQ.subQuestionText}
                      value={getDisplayValue(
                        formData[`${q.questionId}`],
                        "text"
                      )}
                      onChange={(e) => handleInputChange(e, `${q.questionId}`)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md "
                    />
                  )}
                  {subQ.subQuestionType === "number" && (
                    <input
                      key={`${q.questionId}`}
                      type="number"
                      placeholder={subQ.subQuestionText}
                      value={getDisplayValue(
                        formData[`${q.questionId}`],
                        "number"
                      )}
                      onChange={(e) => handleInputChange(e, `${q.questionId}`)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    />
                  )}
                  {subQ.subQuestionType === "radio" && (
                    <div key={subQ.subQuestionId}>
                      <div className="space-x-4">
                        {subQ.options?.map((option, optionIndex) => (
                          <label
                            key={optionIndex}
                            className="inline-flex items-center"
                          >
                            <input
                              type="radio"
                              name={q.questionId}
                              value={option}
                              checked={formData[q.questionId] === option}
                              onChange={(e) =>
                                handleRadioChange(e, q.questionId)
                              }
                              className="mr-2"
                            />
                            {option}
                          </label>
                        ))}
                      </div>
                      {formData[q.questionId] === "Yes" &&
                        otherVisible[q.questionId] &&
                        renderFurtherInfoQuestions(subQ, q)}
                    </div>
                  )}
                  {subQ.subQuestionType === "dropdown" && (
                    <select
                      key={subQ.subQuestionId}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      name={q.questionId}
                      value={formData[q.questionId] || ""}
                      onChange={(e) => handleInputChange(e, q.questionId)}
                    >
                      <option value="">Select an option</option>
                      {subQ.options?.map((option, optionIndex) => (
                        <option key={optionIndex} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  )}
                  {subQ.subQuestionType === "date" && renderDatePicker(q, subQ)}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default DynamicForm;