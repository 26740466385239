import React from "react";

function TermsAndConditionsModal({
  isVisible,
  onClose,
  // formData,
  // setFormData,
}) {
  if (!isVisible) return null;

  // const handleChange = (event) => {
  //   const { name, value, checked, type } = event.target;
  //   console.log(`Changing ${name} to ${type === "checkbox" ? checked : value}`);

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: type === "checkbox" ? checked : value,
  //   }));
  // };

  return (
    <div className="flex shadow-lg rounded-2xl justify-center overflow-hidden p-0 w-full h-[75%] bg-blue-100">
      <div
        className="flex fixed inset-0 bg-red bg-opacity-50 justify-center items-center z-999"
        onClick={onClose}
      >
        <div
          className="bg-slate-700 p-6 rounded-2xl shadow-lg"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-col items-start max-h-[40vh] overflow-y-auto">
            {/* Patient Consent Section */}
            <div className="text-white mb-8">
              <h4 className="font-semibold mb-2 text-center underline">
                Patient Consent
              </h4>
              <p className="mb-4">
                I, the undersigned, hereby consent to the collection, use, and
                disclosure of my personal, insurance, and medical history
                details as required for the purpose of conducting the designated
                lab testing. By providing my signature, I confirm the following:
              </p>

              <div className="space-y-2 pl-4">
                <p>
                  <span className="font-bold">Authorization for Testing:</span>{" "}
                  I authorize the laboratory to perform the requested tests on
                  the sample(s) provided by me.
                </p>

                <p>
                  <span className="font-bold">Informed Acknowledgment:</span> I
                  have been informed about the benefits, risks, and limitations
                  of the testing procedures.
                </p>

                <p>
                  <span className="font-bold">Data Privacy and Usage:</span> I
                  understand that my information will be securely handled in
                  compliance with HIPAA.
                </p>

                <p>
                  <span className="font-bold">Results Disclosure:</span> I
                  understand that the test results may be shared with my
                  healthcare providers or insurance company as necessary.
                </p>

                <p>
                  <span className="font-bold">Voluntary Participation:</span> My
                  participation in this testing is voluntary.
                </p>
              </div>
            </div>

            {/* Terms Section */}
            <div className="text-white">
              <h4 className="font-semibold mb-2 text-center underline">
                Terms and Conditions
              </h4>
              <div className="space-y-2 pl-4">
                <p>
                  <span className="font-bold">Compliance with Laws:</span> The
                  laboratory complies with all relevant U.S. federal and state
                  laws.
                </p>

                <p>
                  <span className="font-bold">Data Security:</span> All personal
                  information will be securely stored and used exclusively for
                  testing purposes.
                </p>

                <p>
                  <span className="font-bold">Limitation of Liability:</span>{" "}
                  The laboratory is not responsible for misuse or
                  misinterpretation of test results.
                </p>

                <p>
                  <span className="font-bold">Insurance Coverage:</span>{" "}
                  Submission of insurance details does not guarantee coverage.
                </p>

                <p>
                  <span className="font-bold">Sample Retention:</span> The
                  laboratory may retain unused portions of the sample for
                  quality assurance.
                </p>

                <p>
                  <span className="font-bold">Amendments:</span> Terms may be
                  updated without prior notice, in compliance with laws.
                </p>
              </div>
            </div>
          </div>

          <button
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditionsModal;
