import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TermsAndConditionsModal from "../termsAndConditionsModal/TermsAndConditionsModal";

function PersonalInformationForm({
  formData,
  setFormData,
  invalidFields = {},
  setInvalidFields,
}) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;

    if (name === "email") {
      const validValue = value.replace(/[^A-Za-z0-9.]/g, "");

      setFormData((prevData) => ({
        ...prevData,
        [name]: validValue,
      }));
    } else if (name === "firstName" || name === "lastName") {
      const alphabetOnly = value.replace(/[^A-Za-z\s]/g, "");
      setFormData((prevData) => ({
        ...prevData,
        [name]: alphabetOnly,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }));
    }

    if ((type === "checkbox" && checked) || (type !== "checkbox" && value)) {
      setInvalidFields((prev) => {
        return Object.fromEntries(
          Object.entries(prev).filter(([key]) => key !== name)
        );
      });
    }
  };

  useEffect(() => {
    const fullAddress = `${formData.Street}, ${formData.City}, ${formData.State}, ${formData.Zip}`;

    if (
      !formData.Street.trim() &&
      !formData.City.trim() &&
      !formData.State.trim() &&
      !formData.Zip.trim()
    ) {
      setFormData((prevData) => ({
        ...prevData,
        Address: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        Address: fullAddress,
      }));
    }
  }, [
    formData.Street,
    formData.City,
    formData.State,
    formData.Zip,
    setFormData,
  ]);

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9]+(?:[._-][A-Za-z0-9]+)*@gmail\.com$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (event) => {
    const { value } = event.target;

    const validValue = value.replace(/[^A-Za-z0-9.]/g, "");

    setFormData((prev) => ({
      ...prev,
      email: validValue + "@gmail.com",
    }));

    const isValidEmail = validateEmail(validValue + "@gmail.com");
    setInvalidFields((prev) => ({
      ...prev,
      email: !isValidEmail,
    }));
  };

  const getDisplayEmail = (email) => {
    return email ? email.replace("@gmail.com", "") : "";
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = formatDateToDDMMYYYY(date);

      setFormData((prevData) => ({
        ...prevData,
        dateOfBirth: formattedDate,
      }));

      setInvalidFields((prev) => {
        return Object.fromEntries(
          Object.entries(prev).filter(([key]) => key !== "dateOfBirth")
        );
      });
    } else {
      return;
    }
  };

  const formatDateToDDMMYYYY = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };
  const isDatePassedOrPresent = (date) => {
    const today = new Date();
    return date <= today;
  };
  const getInputClassName = (fieldName, baseClass) => {
    return `${baseClass} ${
      invalidFields[fieldName]
        ? "border-red-500 focus:ring-red-500"
        : "border-gray-300 focus:ring-blue-500"
    }`;
  };

  const baseInputClass =
    "w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2";

  return (
    <div className="space-y-4">
      <h1 className="text-xl font-bold text-center">Personal Information</h1>
      <div className="flex flex-col sm:flex-row gap-4">
        <input
          className={getInputClassName("firstName", baseInputClass)}
          type="text"
          placeholder="*First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required={true}
          pattern="[A-Za-z\s]+"
        />
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required={true}
          pattern="[A-Za-z\s]+"
        />
      </div>
      <div className="flex  gap-4">
        <div className="relative flex-grow">
          <DatePicker
            selected={formData.dateOfBirth}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            placeholderText="*DOB (mm/dd/yyyy)"
            className={getInputClassName(
              "dateOfBirth",
              "w-full basis-1/4 px-3 py-2 pr-2 md:pr-12 border rounded-md focus:outline-none focus:ring-2"
            )}
            filterDate={isDatePassedOrPresent}
            required
          />
        </div>
        <select
          className={getInputClassName(
            "gender",
            "w-1/2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2"
          )}
          name="gender"
          value={formData.gender}
          onChange={handleChange}
          required
        >
          <option value="">*Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="notToSay">Prefer not to say</option>
          <option value="other">Other Specify</option>
        </select>
      </div>
      {formData.gender === "other" && (
        <input
          className={getInputClassName("otherGender", baseInputClass)}
          type="text"
          placeholder="Specify Gender"
          name="otherGender"
          value={formData.otherGender}
          onChange={handleChange}
        />
      )}
      <div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="relative flex-grow flex items-center">
            <input
              type="text"
              className={getInputClassName("email", baseInputClass)}
              placeholder="*Email Id"
              id="email"
              name="email"
              value={getDisplayEmail(formData.email)}
              onChange={handleEmailChange}
              minLength={6}
              maxLength={30}
              required={true}
              pattern="^[A-Za-z0-9]{6,30}$"
              autoComplete="off"
            />
            <span className="text-gray-600 absolute right-3 pointer-events-none">
              @gmail.com
            </span>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <span
          onClick={toggleModal}
          className="underline text-red-800 active:underline cursor-pointer mt-4 hover:text-blue-600 active:text-blue-700"
        >
          Click to read terms and conditions
        </span>
      </div>
      <div className="flex items-start sm:items-center">
        <label
          htmlFor="termsAndConditions"
          className={`block  ${
            invalidFields.termsAndConditions ? "text-red-500" : ""
          }`}
        >
          <input
            type="checkbox"
            id="termsAndConditions"
            name="termsAndConditions"
            checked={formData.termsAndConditions}
            onChange={handleChange}
            className="mr-2"
          />
          *I have read and agree to the above terms and conditions
        </label>
      </div>

      <div className="flex items-start sm:items-center">
        <div>
          <input
            type="checkbox"
            id="wantToGetInTouch"
            name="wantToGetInTouch"
            checked={formData.wantToGetInTouch}
            onChange={(e) =>
              setFormData({ ...formData, wantToGetInTouch: e.target.checked })
            }
            className="mr-2"
          />
        </div>
        <label htmlFor="wantToGetInTouch">
          I want to get in touch with the lab
        </label>
      </div>
      {formData.wantToGetInTouch && (
        <>
          <div>
            <label className="block mb-2">Address:</label>

            <div className="flex flex-col sm:flex-row gap-4 mb-4">
              <input
                type="text"
                placeholder="City"
                name="City"
                value={formData.City}
                onChange={handleChange}
                maxLength={120}
                className="w-full sm:w-1/3 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />

              <input
                type="text"
                placeholder="State"
                name="State"
                value={formData.State}
                onChange={handleChange}
                maxLength={120}
                className="w-full sm:w-1/3 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />

              <input
                type="number"
                placeholder="Zip"
                name="Zip"
                value={formData.Zip}
                onChange={handleChange}
                maxLength={30}
                className="w-full sm:w-1/3 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <input
              type="text"
              placeholder="Street"
              name="Street"
              value={formData.Street}
              onChange={handleChange}
              maxLength={120}
              className="w-full px-3 py-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label className="block mb-2">Preferred Contact Method:</label>
            <div className="space-x-4">
              {["Mobile", "Email"].map((value) => (
                <label key={value} className="inline-flex items-center">
                  <input
                    type="radio"
                    name="contactPreference"
                    value={value}
                    checked={formData.contactPreference === value}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        contactPreference: e.target.value,
                      })
                    }
                    className="mr-2"
                  />
                  {value.charAt(0).toUpperCase() + value.slice(1)}
                </label>
              ))}
            </div>
          </div>

          {formData.contactPreference === "Mobile" && (
            <div>
              <input
                type="tel"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber}
                maxLength={10}
                onChange={(e) => {
                  const value = e.target.value;

                  if (/^\d*$/.test(value)) {
                    handleChange(e);
                  }
                }}
              />
            </div>
          )}
        </>
      )}

      <TermsAndConditionsModal
        isVisible={modalVisible}
        onClose={toggleModal}
        formData={formData}
        setFormData={setFormData}
      />
    </div>
  );
}

export default PersonalInformationForm;
