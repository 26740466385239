import React, { useEffect, useState } from "react";

export function AnimatedButton({
  children,
  onClick,
  variant = "primary",
  size = "md",
}) {
  const [bubbles, setBubbles] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setBubbles((current) => {
        const newBubbles = current
          .filter((bubble) => document.getElementById(`bubble-${bubble.id}`))
          .concat({
            id: Date.now(),
            left: Math.random() * 100,
          });
        return newBubbles;
      });
    }, 600);

    return () => clearInterval(interval);
  }, []);

  const baseStyles =
    "relative inline-flex items-center justify-center overflow-hidden rounded-lg font-medium transition-all duration-300 ease-out transform hover:scale-105 active:scale-95";

  const sizeStyles = {
    sm: "px-4 py-2 text-sm",
    md: "px-6 py-3 text-base",
    lg: "px-8 py-4 text-lg",
  };

  const variantStyles = {
    primary:
      "bg-gradient-to-r from-blue-500 to-indigo-600 text-white hover:from-blue-600 hover:to-indigo-700 shadow-lg hover:shadow-xl",
    secondary:
      "bg-gradient-to-r from-gray-100 to-gray-200 text-gray-800 hover:from-gray-200 hover:to-gray-300 shadow-md hover:shadow-lg",
  };

  return (
    <button
      onClick={onClick}
      className={`
        ${baseStyles}
        ${sizeStyles[size]}
        ${variantStyles[variant]}
      `}
    >
      <span className="relative z-10">{children}</span>
      {bubbles.map(({ id, left }) => (
        <span
          key={id}
          id={`bubble-${id}`}
          className="absolute bottom-0 rounded-full bg-white/30 animate-bubble pointer-events-none"
          style={{
            left: `${left}%`,
            width: "8px",
            height: "8px",
          }}
        />
      ))}
    </button>
  );
}
