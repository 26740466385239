import React from 'react';

const ResponsiveTcpa = () => {
  return (
    <div className="min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl overflow-hidden">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 px-6 py-8 sm:px-8">
            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white">
              TCPA Disclosure and Terms for Marketing Research Survey
            </h1>
            <p className="mt-2 text-blue-100">Effective Date: December 19, 2024</p>
          </div>

          {/* Content Sections */}
          <div className="p-6 sm:p-8 space-y-8">
            {/* Marketing Communications Section */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">
                1. Marketing Communications Authorization
              </h2>
              
              <div className="space-y-6">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    1.1 Product Information
                  </h3>
                  <p className="text-gray-700 mb-4">
                    We provide comprehensive information about our products and services, including new testing kit launches, 
                    detailed product specifications, and technical innovations. Our communications include scientific research, 
                    validation studies, and important industry certifications that demonstrate our commitment to quality and accuracy.
                  </p>
                  <p className="text-gray-700">
                    Stay informed about usage guidelines and best practices to ensure optimal results from our testing kits 
                    and related products. Receive regular updates about our latest technical improvements and product line expansions.
                  </p>
                </div>

                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    1.2 Promotional Content
                  </h3>
                  <p className="text-gray-700 mb-4">
                    As a valued customer, you'll receive exclusive access to early product releases, limited-time offers, 
                    and seasonal promotions. Our loyalty program provides special benefits including bundle deals, 
                    discounts, and referral rewards.
                  </p>
                  <p className="text-gray-700">
                    Get invited to special events and receive personalized offers based on your interests and previous interactions 
                    with our products and services.
                  </p>
                </div>
              </div>
            </section>

            {/* Communication Channels Section */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">
                2. Communication Channels
              </h2>
              
              <div className="space-y-6">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    2.1 Digital Communications
                  </h3>
                  <p className="text-gray-700 mb-4">
                    We utilize various digital channels to keep you informed, including email newsletters, SMS/MMS messages, 
                    and social media communications. Our mobile app and web browser notifications ensure you never miss 
                    important updates about our products and services.
                  </p>
                  <p className="text-gray-700">
                    Access real-time updates through our customer portal and receive customized notifications based on 
                    your preferences and interaction history.
                  </p>
                </div>

                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    2.2 Communication Frequency
                  </h3>
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-semibold text-gray-900 mb-2">Email Marketing Schedule:</h4>
                      <p className="text-gray-700">
                        Receive our weekly newsletter, bi-monthly product updates, and educational content. Flash sale 
                        notifications are limited to 4 times per month to ensure you don't miss our best offers.
                      </p>
                    </div>
                    <div>
                      <h4 className="font-semibold text-gray-900 mb-2">Text Message Schedule:</h4>
                      <p className="text-gray-700">
                        Get up to 6 promotional alerts per month, 4 flash sale notifications, and 2 survey invitations 
                        to help us improve our services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Opt-Out Section */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">
                3. Opt-Out Management
              </h2>
              
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                  3.1 Digital Opt-Out Options
                </h3>
                <p className="text-gray-700 mb-4">
                  You can manage your communication preferences or opt-out at any time. To update your preferences 
                  or unsubscribe from our communications, please contact us at:
                </p>
                <p className="text-lg font-semibold">
                  <a href="mailto:contactus@examinemyhealth.com" 
                     className="text-blue-600 hover:text-blue-800 transition-colors">
                    contactus@examinemyhealth.com
                  </a>
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveTcpa;