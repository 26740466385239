import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import Landing from "./components/landingPage/Landing.js";
import MultiStepRegistrationForms from "./components/multiStepRegistrationForms/MultiStepRegistrationForms.js";
import formDataJsons from "./components/questionData/formDatas.js";
import Contact from "./components/contact/Contact.js";
import FeedbackInfo from "./components/feedBackPage/FeedBackInfo.js";
import Privacypolicy from "./components/privacyPolicy/Privacypolicy.js";
import Guide from "./components/guide/Guide.js";
import About from "./components/about/About.js";
import Tcpa from "./components/tcpa/Tcpa.js";
import TermsOfService from "./components/termsofservice/TermsOfService.js";
import WellnessGuide from "./components/wellnessGuide/WellnessGuide.js";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Landing />} />
      {Object.keys(formDataJsons).map((key) => (
        <Route
          key={key}
          path={`/form/${key}`}
          element={<MultiStepRegistrationForms testopt={key} />}
        />
      ))}
      <Route path="/contact" element={<Contact />} />
      <Route path="/feedback" element={<FeedbackInfo />} />
      <Route path="/privacy-policy" element={<Privacypolicy />} />
      <Route path="/tcpa-compliance" element={<Tcpa />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/wellness-guide" element={<WellnessGuide />} />
      <Route path="/guide" element={<Guide />} />
      <Route path="/about" element={<About />} />
    </>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
