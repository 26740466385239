import React from "react";
import Covid from "./Covid";
import Rsv from "./Rsv";
import Influenza from "./Influenza";

function WellnessGuide() {
  return (
    <div>
      <Covid />
      <Rsv />
      <Influenza />
    </div>
  );
}

export default WellnessGuide;
