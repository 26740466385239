import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner.js";

const FeedbackInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    type: "feedback",
    name: "",
    comment: "",
  });

  AWS.config.update({
    region: "ap-south-1",
    credentials: new AWS.Credentials({
      accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_LAMBDA_ACCESS_SECRET,
    }),
  });

  const lambda = new AWS.Lambda();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      const alphabetOnly = value.replace(/[^A-Za-z\s]/g, "");
      setFormData({
        ...formData,
        [name]: alphabetOnly,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    setFormData({
      type: "feedback",
      name: "",
      comment: "",
    });

    const params = {
      FunctionName: "insert_surveyform_response",
      Payload: JSON.stringify(formData),
    };

    try {
      const response = await lambda.invoke(params).promise();
      console.log(response);

      if (response.FunctionError) {
        throw new Error(
          JSON.parse(response.Payload).errorMessage ||
            "An error occurred while processing your submission."
        );
      }

      setIsLoading(false);
      navigate("/");
    } catch (error) {
      alert("Error is occurred");
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-400 p-4">
      <div className="bg-slate-200 shadow-lg rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">
          Feedback
        </h2>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-10">
            <LoadingSpinner />
          </div>
        )}
        {/* Form */}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-semibold text-gray-600"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              minLength={6}
              maxLength={30}
              pattern="[A-Za-z\s]{6,30}+"
              required
              className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="comment"
              className="block text-sm font-semibold text-gray-600"
            >
              Your Comments
            </label>
            <textarea
              id="comment"
              name="comment"
              required
              value={formData.comment}
              onChange={handleInputChange}
              minLength={6}
              maxLength={120}
              pattern="{6,120}+"
              rows="4"
              className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 transition duration-200"
          >
            Submit Feedback
          </button>
        </form>
      </div>
    </div>
  );
};

export default FeedbackInfo;
