import React, { useRef, useState } from "react";
import feediconwomen from "../../images/feediconwomen.jpg";
import feediconmen from "../../images/feediconmen.jpg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const feedback = [
  {
    text: "The lab tests were accurate and provided fast results. I couldn't be happier with the service! The lab tests were accurate and provided fast results. I couldn't be happier with the service!",
    user: "- Catherine Garcia",
    company: "Healthcare Solutions",
    image: feediconwomen,
  },
  {
    text: "Excellent service! The staff was professional and made the testing process easy and comfortable.",
    user: "- David Young",
    company: "Family Clinic",
    image: feediconmen,
  },
  {
    text: "I appreciate the clarity of the results and the timely follow-up. Highly recommend their lab services!",
    user: "- Williom Foster",
    company: "Wellness Center",
    image: feediconwomen,
  },
];

function Review() {
  const scrollRef = useRef(null);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -500,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 500,
        behavior: "smooth",
      });
    }
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="relative pb-2">
      <div className="relative z-10 text-center p-4">
        <h2 className="text-3xl sm:text-5xl lg:text-6xl mt-10 lg:mt-20 tracking-wide">
          <span className="font-semibold font-roman bg-gradient-to-r from-blue-500 via-blue-700 to-purple-700 text-gray-200 bg-clip-text">
            What People Are Saying
          </span>
        </h2>
      </div>
      <div className="flex items-center justify-center mt-10 lg:mt-20">
        <button
          onClick={scrollLeft}
          className="relative z-10 text-gray-200 p-4 text-md"
        >
          <FaChevronLeft />
        </button>
        <div
          ref={scrollRef}
          className="flex flex-nowrap overflow-x-auto scrollbar-hide w-full"
        >
          {feedback.map((item, index) => (
            <div
              key={index}
              className="flex-shrink-0  w-full mx-2 my-2 sm:w-1/2 lg:w-1/3 p-2 overflow-hidden"
            >
              <div className="bg-transparent bg-opacity-30 tracking-wide backdrop-blur-md border border-blue-300 rounded-lg p-4 flex flex-col justify-between items-start shadow-lg transition transform hover:scale-105 w-auto h-auto">
                <div className="flex-grow">
                  <p
                    className={`text-lg text-[20px] text-gray-100 mb-2 tracking-wide ${
                      expandedIndex !== index ? "line-clamp-3" : ""
                    }`}
                  >
                    {expandedIndex === index
                      ? item.text
                      : `${item.text.substring(0, 65)}...`}
                  </p>
                  {expandedIndex !== index && (
                    <button
                      onClick={() => toggleExpand(index)}
                      className="text-gray-100 hover:underline mt-1"
                    >
                      read more
                    </button>
                  )}
                </div>
                {expandedIndex === index && (
                  <button
                    onClick={() => toggleExpand(index)}
                    className="text-gray-100 hover:underline mt-2"
                  >
                    read less
                  </button>
                )}
                <div className="flex items-start mt-4">
                  <div className="ml-2">
                    <div className="text-gray-100 tracking-wide font-semibold text-md">
                      {item.user}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={scrollRight}
          className="relative z-10 text-gray-200 p-4 text-md"
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
}

export default Review;
