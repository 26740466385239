import React from "react";
import {
  Activity,
  AlertCircle,
  Shield,
  Users,
  Stethoscope,
  HeartPulse,
  Clock,
} from "lucide-react";
import { Link } from "react-router-dom";
import { AnimatedButton } from "./Animatedbtn";
import Rsv from "../../images/rsv.jpg";

function InfoBox({ title, children, icon: Icon }) {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow border border-gray-700">
      <div className="flex items-center gap-3 mb-4">
        <Icon className="w-6 h-6 text-purple-400" />
        <h3 className="text-xl font-bold text-white">{title}</h3>
      </div>
      <div className="text-gray-300">{children}</div>
    </div>
  );
}

function RSV() {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center py-24"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Rsv})`,
        }}
      >
        <div className="container mx-auto px-6">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-purple-400">
            RSV: Understanding the Silent Respiratory Threat
          </h1>
          <p className="text-xl md:text-2xl max-w-3xl text-gray-300">
            Recognizing and managing Respiratory Syncytial Virus to protect
            vulnerable populations.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-6 py-12">
        {/* Introduction */}
        <div className="prose prose-invert max-w-none mb-12">
          <p className="text-xl text-gray-300 leading-relaxed">
            When your child develops what seems like a common cold, you might
            not immediately think of Respiratory Syncytial Virus (RSV). However,
            this seemingly ordinary respiratory infection deserves our focused
            attention, especially given its potential to develop into something
            more serious, particularly in infants, young children, and older
            adults.
          </p>
        </div>

        {/* Symptoms Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          <InfoBox title="Early Phase (Days 1-2)" icon={Clock}>
            <ul className="list-disc list-inside space-y-2">
              <li>Slight runny nose</li>
              <li>Decrease in appetite</li>
              <li>Mild coughing</li>
            </ul>
          </InfoBox>

          <InfoBox title="Breathing Changes" icon={Activity}>
            <ul className="list-disc list-inside space-y-2">
              <li>Rapid or labored breathing</li>
              <li>"Belly breathing"</li>
              <li>Wheezing during exhalation</li>
              <li>Nostrils flaring</li>
            </ul>
          </InfoBox>

          <InfoBox title="Warning Signs" icon={AlertCircle}>
            <ul className="list-disc list-inside space-y-2">
              <li>Pauses in breathing</li>
              <li>Blue color around lips</li>
              <li>Severe coughing spells</li>
              <li>Decreased wet diapers</li>
            </ul>
          </InfoBox>
        </div>

        {/* Benefits Section */}
        <div className="bg-gray-800 rounded-xl p-8 mb-12">
          <h2 className="text-3xl font-bold mb-6 text-purple-400">
            Why Early Detection Matters
          </h2>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 mb-10 gap-6">
            <div className="flex gap-4">
              <Shield className="w-8 h-8 text-purple-400 flex-shrink-0" />
              <p className="text-gray-300">
                Prevent progression to severe respiratory distress
              </p>
            </div>
            <div className="flex gap-4">
              <Stethoscope className="w-8 h-8 text-purple-400 flex-shrink-0" />
              <p className="text-gray-300">
                Enable targeted treatment approaches
              </p>
            </div>
            <div className="flex gap-4">
              <Users className="w-8 h-8 text-purple-400 flex-shrink-0" />
              <p className="text-gray-300">Protect vulnerable family members</p>
            </div>
          </div>

          <AnimatedButton>
            <Link to="/form/rsv" className="w-full sm:w-auto">
              <div className="flex items-center gap-2">
                <HeartPulse size={20} />
                <span>Get Tested</span>
              </div>
            </Link>
          </AnimatedButton>
        </div>
      </div>
    </div>
  );
}

export default RSV;
