const payorNames = [
  "1199 National Benefit Fund",
  "1st Auto & Casualty",
  "21st Century Health and Benefits",
  "21st Century Insurance",
  "3P Admin",
  "6 Degrees Health, Inc.",
  "8th District Elec",
  "90 Degree Benefits",
  "A & I Benefit Plan Administrators",
  "A-G Administrators LLC",
  "A.W. Holdings LLC Dba Benchmark - CORVEL",
  "AAA Automobile",
  "AAA Northern California, Nevada & Utah Insurance Exchange",
  "AAG - American Administrative Group",
  "AAG Benefit Plan Administrators, Inc.",
  "AARP Dental Insurance Plan",
  "AARP Hospital Indemnity Plans insured by UnitedHealthcare Insurance Company",
  "AARP Medicare Supplement Plans insured by UnitedHealthcare Insurance Company",
  "AARP MedicareComplete insured through UnitedHealthcare (formerly AARP MedicareComplete from SecureHorizons)",
  "AARP MedicareComplete insured through UnitedHealthcare / Oxford Medicare Network -  AARP MedicareComplete",
  "AARP MedicareComplete insured through UnitedHealthcare / Oxford Mosaic Network - AARP MedicareComplete  Mosaic",
  "AARP MedicareComplete through UHC / Oxford Medicare Network",
  "AARP MedicareComplete through UHC / Oxford Mosaic Network",
  "AARP by UnitedHealthcare",
  "ABC Const. Company",
  "ABRAZO Advantage Health Plan",
  "ACIG Insurance Company",
  "ACS Benefit Services, Inc.",
  "ACTIN Care Groups / Clifton Health Systems",
  "AD-COMP - CORVEL",
  "ADVENTIST HEALTH CARE NETWORK, INC",
  "AFTRA Health & Retirement Funds",
  "AGIA, Inc.",
  "AHF PHC California Medi-Cal",
  "AHF Ryan White Grants",
  "AHP Provider Network",
  "AHRMA (IL only) - CORVEL",
  "AIG",
  "AKM Medical Group",
  "AL-MED HCLA",
  "ALICARE",
  "ALL American Medical Group",
  "ALTA Health & Life Insurance",
  "AMA Insurance",
  "AMBETTER (Centene)",
  "AMCO Ins Co",
  "AMERICAN MEDICAL SECURITY (AMS)",
  "AMG IPA - Ava Medical Group",
  "AMPS - CX",
  "AMPS America",
  "AMPS",
  "AMVI - Prospect Health Network",
  "AMVI Medical Group",
  "APAC (Pinnacle Health Resources)",
  "APWU Health Plan",
  "ARAZ",
  "ARC Administrators",
  "ARCMI Claims Management",
  "ARM, Ltd",
  "ASONET",
  "ASR Health Benefits",
  "ASRM LLC",
  "AXA Assistance_USA",
  "AblePay Health",
  "Abri Healthplan",
  "Absolute Total Care (Centene)",
  "Acadia Insurance",
  "Access Administrators",
  "Access Behavioral Care",
  "Access Community Health Network",
  "Access IPA",
  "Access Integra",
  "Access Medical Group",
  "Access Primary Care Medical Group (APCMG)",
  "Access Santa Monica",
  "Accident Fund",
  "Acclaim IPA (MHCAC)",
  "Acclaim IPA",
  "Acclaim",
  "Accountable Healthcare IPA (AHCIPA)",
  "Accuride Corporation",
  "Ace Property & Casualty Ins Co",
  "Ace Property & Casualty Ins Co Medicare Supplement",
  "ActivHealthCare",
  "Active Care, Inc",
  "Activia Benefit Services",
  "Acuity, A Mutual Insurance Company",
  "Adelanto Correctional Facility (CA) - CORVEL",
  "AdminOne",
  "Administaff",
  "Administration Systems Research Corp",
  "Administration Systems Research Health Benefits",
  "Administrative Claim Service, Inc.",
  "Administrative Concepts Inc.",
  "Administrative Services Inc",
  "Advance America Cash Advance Centers - CORVEL",
  "Advance Services, Inc.",
  "Advanced Medical Doctors of CA",
  "Advanced Physicians IPA",
  "Advanstaff, Inc.",
  "Advantage Care IPA",
  "Advantage Health Solutions (Careington)",
  "Advantage Medical Group",
  "Advantage Preferred Plus",
  "Advantage by Bridgeway Health Solutions",
  "Advantek Benefit Administrators (SANTE)",
  "Advantica Benefits",
  "Advantica",
  "Advantra Freedom",
  "Advent Health Medical Corporation",
  "Adventist Health Hanford AHP",
  "Adventist Health Plan",
  "Adventist Health System West",
  "Adventist White Memorial - Southland San Gabriel Valley",
  "Advisory Health Administrators",
  "Advocare MCO, Inc",
  "Advocate Health Centers",
  "Advocate Health Partners",
  "Aegis Administrative Services",
  "Aetna Advantage",
  "Aetna Affordable Health Choices - SRC/Boone",
  "Aetna Better Health California",
  "Aetna Better Health Florida",
  "Aetna Better Health Illinois MMAI",
  "Aetna Better Health Illinois",
  "Aetna Better Health Kansas",
  "Aetna Better Health Kentucky",
  "Aetna Better Health Louisiana",
  "Aetna Better Health Maryland",
  "Aetna Better Health Michigan",
  "Aetna Better Health New Jersey",
  "Aetna Better Health New York",
  "Aetna Better Health Ohio",
  "Aetna Better Health Oklahoma",
  "Aetna Better Health Pennsylvania",
  "Aetna Better Health Texas CHIP",
  "Aetna Better Health Texas",
  "Aetna Better Health Virginia",
  "Aetna Better Health West Virginia",
  "Aetna Better Health of Oklahoma",
  "Aetna Choice Plans",
  "Aetna DMO",
  "Aetna Employee Assistance Program EAP",
  "Aetna Encounters",
  "Aetna Health, Inc.",
  "Aetna HealthFund",
  "Aetna Healthcare",
  "Aetna Life & Casualty Company",
  "Aetna Life Insurance Company",
  "Aetna Long Term Care",
  "Aetna Medicaid Plans",
  "Aetna Medicare",
  "Aetna Retiree Medical Plan - Administrator",
  "Aetna Senior Plans",
  "Aetna Senior Supplemental",
  "Aetna Signature Administrators (ASA)",
  "Aetna Student Health",
  "Aetna TX Medicaid and CHIP",
  "Aetna US Healthcare - AUSHC",
  "Affiliated Doctor's of Orange County (ADOC) (Regal)",
  "Affiliated Partners IPA SCAN/CleverCare HP Only",
  "Affiliated Partners IPA",
  "Affiliated Physicians IPA",
  "Affinity Health Plan",
  "Affinity Medical Group",
  "Aflac Medicare Supplement",
  "Aftra Health Fund",
  "Agate Resources, Inc. (LIPA)",
  "AgeRight Advantage",
  "AgeWell New York",
  "Agency Services Inc.",
  "Aither Health",
  "Alameda Alliance for Health",
  "Alamitos IPA",
  "Alarm Contractors",
  "Alaska Children''s Services, Inc. Group #P68",
  "Alaska Electrical Health & Welfare Fund",
  "Alaska Laborers Construction Industry Trust Group # F23",
  "Alaska National Insurance",
  "Alaska Pipe Trades Local 375 Group # F24",
  "Alaska UFCW Health & Welfare Trust Group # F45",
  "Albuquerque Public Schools",
  "Alcoa Fasteners Systems",
  "Align Senior Care (FL)",
  "Align Senior Care (MI)",
  "Align Senior Care (VA)",
  "Align Senior Care CA",
  "Aligned Community Physicians",
  "Alignis",
  "Alignment Health Care (Fee For Service)",
  "Alignment Health Plan (Fee For Service)",
  "Alignment Health",
  "Alivi Health",
  "Alivi Therapy Network",
  "All Care Medical Group",
  "All Savers Life Insurance",
  "All United Medical Group Clever Care HP Only",
  "All United Medical Group",
  "AllCare CCO Dental",
  "AllCare IPA",
  "AllCare PACE",
  "AllCare PEBB",
  "Allegeant",
  "Allegiance Benefit Plan Management Inc.",
  "Allegiance Health Management",
  "Allen Canning",
  "Alliance Behavioral Health",
  "Alliance Coal Health Plan",
  "Alliance Health Systems",
  "Alliance Healthcare - Stones River Regional IPA",
  "Alliance Physicians High Desert",
  "Alliance Physicians Medical Group",
  "Alliant Energy",
  "Alliant Health Plans of Georgia",
  "Allianz Resolution Management (ARM)",
  "Allianz",
  "Allied Benefit Systems, Inc.",
  "Allied Claims Administration, Inc.",
  "Allied Healthcare",
  "Allied Pacific of California",
  "Allied Physicians Medical Group",
  "Allied Property and Casualty Ins Co",
  "Allina Health Aetna",
  "Allmerica Financial Benefits Insurance Company",
  "Allstate Insurance",
  "Aloha Care",
  "Alpha Care Medical Group NMM",
  "Alta Bates Medical Group",
  "AltaMed",
  "Altais Care Network",
  "Alternative Insurance Resources, Inc.",
  "Alternative Opportunities",
  "Alterwood Advantage",
  "Altus Dental",
  "Alvey Systems (AON Risk)",
  "AmFed Companies LLC (Amfed National Insurance)",
  "AmGuard Insurance Company - Guard DBA",
  "Amada Health South",
  "Amada Health",
  "Amalgamated Insurance Company",
  "Amalgamated Life IPA Alicare",
  "Amalgamated Life",
  "Ambetter from Meridian",
  "Ambetter of Arkansas (Centene)",
  "Ambetter of Missouri (Centene)",
  "Ambetter of New Hampshire (Centene)",
  "Ambetter of Tennessee (Centene)",
  "AmeriChoice - New York Medicaid",
  "AmeriChoice - Pennsylvania Medicaid",
  "AmeriChoice Community and State",
  "AmeriChoice of New Jersey",
  "AmeriHealth Administrator",
  "AmeriHealth Administrators",
  "AmeriHealth Caritas / VIP Care Plus - Michigan",
  "AmeriHealth Caritas / VIP Care",
  "AmeriHealth Caritas Aligned",
  "AmeriHealth Caritas Delaware",
  "AmeriHealth Caritas District of Columbia",
  "AmeriHealth Caritas Florida",
  "AmeriHealth Caritas Louisiana",
  "AmeriHealth Caritas New Hampshire",
  "AmeriHealth Caritas Next Delaware",
  "AmeriHealth Caritas Next Florida",
  "AmeriHealth Caritas Next North Carolina",
  "AmeriHealth Caritas North Carolina",
  "AmeriHealth Caritas Northeast",
  "AmeriHealth Caritas Ohio Medicaid (Radiology Only)",
  "AmeriHealth Caritas Ohio Medicaid",
  "AmeriHealth Caritas PA CHC",
  "AmeriHealth Caritas Pennsylvania Community HealthChoices",
  "AmeriHealth Caritas Pennsylvania",
  "AmeriHealth Caritas VIP Care - Delaware DSNP",
  "AmeriHealth Caritas VIP Care - Florida DSNP",
  "AmeriHealth Delaware (Non-HMO)",
  "AmeriHealth Delaware - HMO/POS",
  "AmeriHealth Mercy Health Plan",
  "AmeriHealth New Jersey (Non-HMO)",
  "AmeriHealth New Jersey - HMO/POS",
  "AmeriHealth Pennsylvania - HMO/POS/ERISA",
  "Ameriben Solutions, Inc.",
  "America First",
  "America's Choice Healthplan",
  "America's Health Choice",
  "America's PPO/America's TPA",
  "American Administrative Group",
  "American Airlines",
  "American Behavioral",
  "American Claims Management (ACM)",
  "American Coin Merchandising, Inc.",
  "American Community Mutual",
  "American Country Insurance Company / American Service Insurance Company, Inc.",
  "American Cyanamid",
  "American Family Health Providers",
  "American Family Insurance",
  "American Family Life Assurance Co. (AFLAC)",
  "American Family Mutual Insurance Co",
  "American Family PPO Policies",
  "American General Life & Accident",
  "American Health Advantage of FL",
  "American Health Advantage of LA",
  "American Health Advantage of MS",
  "American Health Advantage of TN",
  "American Health Advantage of TX",
  "American Health Advantage of UT",
  "American Healthcare Alliance",
  "American Home Craft",
  "American Income Life Insurance Company",
  "American Insurance Administrators",
  "American Insurance Company of Texas",
  "American Liberty Insurance Company",
  "American Life Care",
  "American Medical Security",
  "American National Insurance Co.",
  "American National Insurance Co Medicare Supplement",
  "American National Life Insurance Company of Texas",
  "American National Life Insurance Co of Texas Medicare Supplement",
  "American National",
  "American Network Insurance",
  "American Plan Administrators",
  "American Postal Workers Union (APWU)",
  "American Primary Care Alliance IPA",
  "American Public Life (APL)",
  "American Republic Insurance Company (ARIC)",
  "American Republic Insurance",
  "American Specialty Companies",
  "American Specialty Health Plan",
  "American Specialty Ins. / MIB",
  "American Therapy Association (ATA) - Florida",
  "American Trust Administrators, Inc.",
  "American West Health Care Solution",
  "American Worker Health Plan",
  "Americas Health Plan",
  "Americo Financial Life",
  "Americo Financial Life & Annuity Insurance Co Medicare Supplement",
  "Americo IPA",
  "Amerigroup (GA, NM, DC)",
  "Amerigroup of Nevada (Unicare)",
  "Amerigroup of Nevada",
  "Amerihealth Caritas Northeast",
  "Amerihealth Caritas Ohio Medicaid",
  "Amerihealth Caritas Pennsylvania",
  "Amerihealth Casualty Services",
  "Ameriprise Auto & Home Insurance",
  "Ameriprise",
  "Amerisafe Risk Services",
  "Amerisafe",
  "Amerisure Insurance",
  "Ameritas Life Insurance Corp.",
  "Ameritas Life Insurance Corporation",
  "Ameritas",
  "Amfirst Insurance Company",
  "Amica Mutual Insurance Company",
  "Amida Care",
  "Amtrust Insurance Company of Kansas",
  "Anaheim Memorial IPA (MHCAN)",
  "Anchor Benefit Consulting Inc.",
  "Anchor Benefit Consulting",
  "Anchor Glass Container Corporation",
  "Angel Medical Group",
  "Angeles IPA",
  "Angle Insurance Company of Utah",
  "Anheuser Busch",
  "Antares Management Solutions",
  "Antelope Valley Medical Associates (Regal)",
  "Anthem Blue Cross CA Encounters",
  "Anthem Blue Cross New York (Anthem)",
  "Anthem Blue Cross of California (BCCA)",
  "Anthem Dental (P.O. Box 1115 MN)",
  "Anthem HealthKeepers Plus",
  "Anthem Healthy Indiana Plan (HIP)",
  "Anthem Hoosier HealthWise",
  "Anthem Medicaid - Indiana",
  "Anthem Medicaid - Wisconsin",
  "Anthem Medicaid of Ohio",
  "Anthem NV Medicaid",
  "Anthem OMHS",
  "Apex Benefit Services",
  "ApexHealth",
  "ApolloCare Partners of Nevada",
  "Apostrophe",
  "AppleCare Medical Management",
  "Arbor Health Plan",
  "Arcadia Healthcare Solutions - IPG",
  "Arcadia Healthcare Solutions - NPA",
  "Arcadia Methodist IPA",
  "Arcadian Management Services",
  "Arch Health Partners/Centre for Healthcare",
  "ArchCare Advantage",
  "Archbold Medical Center",
  "Areas USA, Inc.",
  "Argonaut Insurance Company (ARGO)",
  "Argus Dental Plans",
  "Argus Vision",
  "Arise Health Plan (WPS)",
  "Arizona Complete Care (Centene)",
  "Arizona Complete Health (Centene)",
  "Arizona Priority Care Plus",
  "Arkansas Superior Select",
  "Arkansas Total Care (Centene)",
  "ArmadaCare",
  "Arnett Health Plan",
  "Arnot Ogden Medical Center (UCS)",
  "Arrowhead Regional Medical Center",
  "Arrowpoint",
  "Arroyo Vista Family Health Center",
  "Ascend IPA",
  "Asian American Medical Group",
  "Asian Community Medical Group, Inc.",
  "Aspen Medical Associates",
  "Aspire Health Plan",
  "Aspirus Medicare Advantage",
  "Asserta Health",
  "Assist Health Group",
  "Associated Dignity Medical Group Professional Corp",
  "Associated Hispanic Physicians of Southern California",
  "Associated Hispanic Physicians",
  "Associated Hyspanic Physicians",
  "Associates for Health Care, Inc. (AHC)",
  "Assurant Employee Benefits (Now Sun Life)",
  "Assurant Health Self-Funded (Allied Benefit)",
  "Assured Benefits Administrators",
  "Astiva Health Encounters",
  "Astiva Health",
  "Asuris Northwest Health",
  "Athens",
  "Atlantic Coast Life Insurance",
  "Atlantic Coast Life",
  "Atlantic Dental Inc",
  "Atlantic Medical",
  "Atlantic PPO to Mercury Insurance - Auto",
  "Atlantis Eyecare",
  "Atlas Life Ins Co (NFIC Plan Only)",
  "Atrio Health Plans",
  "AudioNet - National Elevator",
  "AudioNet America (AUDIOCS)",
  "Audionet - UAW Ford Retirees/GM Employees (AUDIOSVS)",
  "Audionet - UAW GM retirees/Chrysler Emps & Retirees (AUDIOABS)",
  "AultCare/PrimeTime Health Plan",
  "Aultra Administrative Group",
  "Austin Mutual Insurance",
  "Auto Club Insurance Association",
  "Auto One Insurance",
  "Auto Owners Insurance Company",
  "Auto-Owners Life Insurance Company - CORVEL",
  "Auto-Owners Home-Owners Insurance Company - CORVEL",
  "Auto-Owners Owners Insurance Company - CORVEL",
  "Auto-Owners Property-Owners Insurance Co - CORVEL",
  "Auto-Owners Southern-Owners Company - CORVEL",
  "Automated Benefit Services",
  "Automated Group Administration Inc. (AGA)",
  "Automobile Dealers Insurance Company Inc",
  "Auxiant",
  "AvMed",
  "Avalon Administrative Services - BCBSNC",
  "Avalon Healthcare Solutions Capital Blue Cross",
  "Avalon Healthcare Solutions",
  "Avalon IPA",
  "Avanta IPA",
  "Avera Health Plans for John Morrell",
  "Avera Health Plans",
  "Avesis LLC",
  "Axminster Medical Group",
  "Ayers Trucking",
  "BARInet",
  "BC California (Anthem)",
  "BC Idaho",
  "BC Illinois",
  "BC Pennsylvania (Capital Blue/CAIC)",
  "BCBS Alabama",
  "BCBS Arizona Advantage",
  "BCBS Arizona",
  "BCBS Arkansas",
  "BCBS Colorado (Anthem)",
  "BCBS Connecticut (Anthem)",
  "BCBS Delaware (Highmark)",
  "BCBS Delaware",
  "BCBS District of Columbia (CareFirst)",
  "BCBS District of Columbia (Carefirst)",
  "BCBS Empire New York",
  "BCBS Florida (Florida Blue)",
  "BCBS Florida Medicare Advantage (Florida Blue)",
  "BCBS Georgia (Anthem)",
  "BCBS Hawaii (HMSA)",
  "BCBS Illinois (HCSC)",
  "BCBS Illinois - Subrogation",
  "BCBS Illinois Medicare Advantage (HCSC)",
  "BCBS Indiana (Anthem)",
  "BCBS Iowa",
  "BCBS Kansas City",
  "BCBS Kansas",
  "BCBS Kentucky (Anthem)",
  "BCBS Louisiana Advantage Plus Network",
  "BCBS Louisiana",
  "BCBS Maine (Anthem)",
  "BCBS Maryland (CareFirst)",
  "BCBS Maryland (Carefirst)",
  "BCBS Maryland Medicare Advantage (CareFirst)",
  "BCBS Massachusetts",
  "BCBS Medicaid Ohio (Anthem)",
  "BCBS Michigan (Dental Only)",
  "BCBS Michigan",
  "BCBS Minnesota",
  "BCBS Mississippi",
  "BCBS Missouri (Anthem)",
  "BCBS Montana (HCSC)",
  "BCBS Montana Medicare Advantage (HCSC)",
  "BCBS Mountain States",
  "BCBS Nebraska",
  "BCBS Nevada (Anthem)",
  "BCBS New Hampshire (Anthem)",
  "BCBS New Jersey (Horizon)",
  "BCBS New Mexico (HCSC)",
  "BCBS New Mexico Medicare Advantage (HCSC)",
  "BCBS New York - Excellus (Central Region)",
  "BCBS New York - Excellus (Rochester Region)",
  "BCBS New York - Excellus (Utica Region)",
  "BCBS New York - Excellus (Utica Watertown Region)",
  "BCBS North Carolina",
  "BCBS North Dakota",
  "BCBS Ohio (Anthem)",
  "BCBS Oklahoma (HCSC)",
  "BCBS Oklahoma Medicare Advantage (HCSC)",
  "BCBS Oregon (Regence)",
  "BCBS Pennsylvania (Highmark)",
  "BCBS Pennsylvania Northeast",
  "BCBS Pennsylvania Northwest",
  "BCBS Puerto Rico",
  "BCBS Rhode Island",
  "BCBS South Carolina (FEP - Federal Employee Plan)",
  "BCBS South Carolina (Preferred Blue)",
  "BCBS South Carolina (State Health Plan)",
  "BCBS South Carolina",
  "BCBS South Dakota",
  "BCBS Tennessee",
  "BCBS Texas (HCSC)",
  "BCBS Texas - Medicaid",
  "BCBS Texas - Subrogation",
  "BCBS Texas Medicaid STAR CHIP",
  "BCBS Texas Medicaid STAR Kids",
  "BCBS Texas Medicare Advantage (HCSC)",
  "BCBS Utah (Regence)",
  "BCBS Vermont",
  "BCBS Virgin Islands",
  "BCBS Virginia (Anthem)",
  "BCBS Vivity (Anthem)",
  "BCBS West Virginia",
  "BCBS Western New York (Highmark)",
  "BCBS Western New York - (FEP - Federal Employee Program)",
  "BCBS Western New York Medicaid/CHP",
  "BCBS Western New York",
  "BCBS Wisconsin (Anthem)",
  "BCBS Wisconsin FEP (Anthem)",
  "BCBS Wyoming",
  "BCBSMN Blue Plus Medicaid",
  "BCN - NON JVHL Network - JVHL",
  "BIENVIVIR",
  "BMC HealthNet Plan",
  "BMW North America",
  "BS California - FEP",
  "BS California Promise Health Plan Encounters",
  "BS California Promise Health Plan",
  "BS California",
  "BS Idaho (Regence)",
  "BS Northeastern New York",
  "BS Pennsylvania (Camp Hill)",
  "BS Washington (Regence)",
  "BSI Companies",
  "BadgerCare Plus (CCHP)",
  "Bakersfield Family Medical Center (BFMC) (Regal)",
  "Bakery and Confectionery Union and Industry International Health",
  "Balance Staffing Workforce",
  "Baldwin & Lyons, Inc. (Previous Name Protective Insurance Co) - CORVEL",
  "Bank of America - CORVEL",
  "Bankers Fidelity Life Insurance Company",
  "Bankers Life (ERA Only)",
  "Bankers Life and Casualty",
  "Bankers Reserve/Wellcare",
  "Banner - University Family Care / AHCCCS Complete Care (B- UFC/ACC) and Banner Medicare Advantage Dual",
  "Banner - University Family Care/ Arizona Long Term Care System (B-UFC/ALTCS)",
  "Banner Health (Healthnet Senior)",
  "Banner Health (UHC Medicare Advantage)",
  "Banner Health and Aetna Health Insurance Company",
  "Banner Health",
  "Banner Medicare Advantage Plus PPO",
  "Banner Medicare Advantage Prime HMO",
  "Banner Plan Administration (Choice Plus and Select 500)",
  "Banner University Family Care",
  "Banner University Family Care/ALTCS",
  "Baptist Health South Florida",
  "Baptist Healthcare Systems, Inc - CORVEL",
  "Barnes",
  "Barrett Business Services Inc (BBSI)",
  "Basic Plus (TCC)",
  "Bass Administrators, Inc.",
  "Bass Pro Group, LLC - CORVEL",
  "Bay Bridge Administrators, LLC",
  "BayCare Select Health Plans Inc",
  "BayCare Select Health Plans, Inc.",
  "Beacon Mutual",
  "Beacon of Life (AcuteCare)",
  "Beall's Inc",
  "Beaver Medical Group",
  "Bed Bath & Beyond - CORVEL",
  "Behavioral Health Systems",
  "Belk, Inc. - CORVEL",
  "Bella Vista Medical Group",
  "Benchmark Insurance",
  "BeneBay",
  "BeneCare Dental Plan",
  "BeneSys Inc.",
  "Benefit & Risk Management Services (BRMS)",
  "Benefit Administration Services LTD (BAS LTD)",
  "Benefit Administrative Systems (BAS Health)",
  "Benefit Coordinators Corporation",
  "Benefit Management Admin (BMA)",
  "Benefit Management Group-NV",
  "Benefit Management Inc - Joplin Claims",
  "Benefit Management Inc - KS",
  "Benefit Management Services of Louisiana",
  "Benefit Management Services",
  "Benefit Plan Administrators - Arnold, MD",
  "Benefit Plan Administrators",
  "Benefit Plan Administrators, Inc.",
  "Benefit Plan Services",
  "Benefit Solutions, Inc.",
  "Benefit Support, Inc.",
  "Benefit Systems & Services, Inc. (BSSI)",
  "Benveo - First Health Network",
  "Benveo - MultiPlan",
  "Benveo",
  "Berkley Industrial Comp",
  "Berkley Mid-Atlantic Group",
  "Berkley National Insurance Company",
  "Berkley Net Underwriters, LLC",
  "Berkley Regional Insurance Company",
  "Berkley Risk Administrators Company, LLC",
  "Berkley Specialty Underwriters",
  "Berkshire Hathaway Direct Insurance Company",
  "Berkshire Hathaway Homestate Companies (Member of BHHC)",
  "Berwind Corporation",
  "Best Life & Health Insurance Company",
  "Better Health Plans of Tennessee",
  "Beverly Alianza IPA",
  "Beverly Hospital - Imperial Health Holdings",
  "Beverly Hospital BEVAHISP",
  "Bind (PreferredOne)",
  "Bind",
  "Bituminous Fire and Marine",
  "Black Hawk",
  "Block Vision - Eye Specialists",
  "Blue Advantage",
  "Blue Benefit Administrators of MA",
  "Blue Card Program",
  "Blue Care Family Plan",
  "Blue Care Network Advantage of Michigan",
  "Blue Care Network of Michigan",
  "Blue Choice of South Carolina",
  "Blue Cross (Cal-Optima - Medi-Cal)",
  "Blue Cross Community Centennial (BCBS NM)",
  "Blue Cross Community ICP / FHP",
  "Blue Cross Community MMAI",
  "Blue Cross Community Options of Illinois",
  "Blue Cross Complete of Michigan",
  "Blue Cross Medicare Advantage PPO/HMO",
  "Blue Medicare Advantage (Anthem)",
  "Blue Medicare of North Carolina",
  "Blue Shield of California (P.O. Box 272540)",
  "Blue Shield of California (P.O. Box 272590)",
  "BlueChoice HealthPlan South Carolina Medicaid",
  "Blueride Non-Emerg Transportation",
  "Boilermakers National Health & Welfare Fund",
  "Boler Company",
  "Boncura Health Solutions",
  "Boon Chapman Administrators, Inc.",
  "Boston Medical Center Health Plan (BMCHP)",
  "Boulder Administration Services",
  "Brand New Day (FFS)",
  "Brand New Day Encounters",
  "Braven Health",
  "BreckPoint",
  "Brickstreet",
  "BridgeSpan",
  "Bridgefield Casualty",
  "Bridgefield Employers",
  "Bridgeport Dental Service",
  "Bridgeview Company",
  "Bridgeway Arizona",
  "Bridgeway Health Solutions (Centene)",
  "Bright Health Medicare Advantage",
  "Bright Health Plan",
  "Brighten Health Plan Solutions",
  "Bristol Park Medical Group",
  "Bristol West",
  "Broadspire (Auto)",
  "Broadspire, a Crawford Company",
  "Brokerage Concepts",
  "Brookshire IPA",
  "Brotherhood Mutual Insurance",
  "Broward County Board of Commissioners (FL)",
  "Broward Regional Health Planning Council",
  "Brown & Toland Health Services",
  "Brown & Toland Medical Group",
  "Brown & Toland Sutter Select",
  "Brownyard Group Inc. (Arch Insurance)",
  "Brunswick Corporation",
  "Buckeye Community Health (Centene)",
  "Buckeye Ohio Medicaid",
  "BuenaVentura Affiliated Physicians",
  "Builders Insurance Group (GA only)",
  "Builders Mutual Insurance",
  "Bunch and Associates",
  "Bureau For Children With Medical Handicaps",
  "Bureau Of Prisons-Apprio",
  "Burlington Coat Factory Warehouse",
  "Business First",
  "Businessmens Assurance",
  "Butler America Holdings, INC.- Corvel - CORVEL",
  "Butler Benefit",
  "C & O Employees Hospital Association",
  "C&S Wholesale Grocers",
  "C. L. Frates and Company",
  "CAREMORE",
  "CAS Coastal Administrative Services",
  "CASD/Sanford  (Chiropractic Associates of SD)",
  "CBA BLUE",
  "CBHNP - HealthChoices",
  "CC-Development Group, Inc. - CORVEL",
  "CCMSI",
  "CDO Technologies",
  "CDS Group Health",
  "CGS Health",
  "CHA Health",
  "CHAMPVA - HAC",
  "CHAMPVA",
  "CHC Cares of South Carolina",
  "CHC Texas",
  "CHCS Services, Inc.",
  "CHOC Health Alliance",
  "CHORUS Community Health Plan (CCHP)",
  "CHP Direct",
  "CHRISTUS Health Plan Texas HIX",
  "CIGNA - PPO",
  "CIGNA For Seniors",
  "CIGNA Health Plan",
  "CIGNA HealthCare",
  "CIGNA Healthcare for Seniors (Medicare)",
  "CIGNA Healthcare",
  "CIGNA PPA",
  "CIGNA Premier Plus",
  "CIGNA Private",
  "CIGNA",
  "CIM Insurance Corporation",
  "CM Providers Medical Group PC",
  "CMFG Life Insurance",
  "CNA Insurance (Auto only)",
  "CNA Insurance",
  "CNS Healthcare",
  "CPR Share Plans",
  "CSAA Insurance Company",
  "CSEA Employee Benefit Fund",
  "CSI Life Insurance Company Medicare Supplement",
  "CSI NETWORK SERVICES",
  "CVS Pharmacy (CAREMARK)",
  "CWI Benefits, Inc.",
  "CYPRESS BENEFIT ADMINISTRATORS",
  "Caduceus Medical Group (Regal)",
  "Cahaba GBA (Medicare Part A Rural)",
  "Cal Care IPA Encounters",
  "Cal Care IPA",
  "Cal Viva Health",
  "Cal Water (California Water Service)",
  "Cal-Viva",
  "CalOptima LTC",
  "CalOptima",
  "CalPortland Company - CORVEL",
  "California Care (Humboldt Del Norte)",
  "California Eye Care",
  "California Fair Services Authority",
  "California Health and Wellness (Centene)",
  "California Health and Wellness",
  "California Hospital Medical Center",
  "California IPA (Capital MSO)",
  "California Medical Center",
  "California Pacific Medical Center (CPMC)",
  "California Pacific Physicians Medical Group, Inc.",
  "California Rural Indian Health Board (CRIHB) Cares",
  "California Rural Indian Health Board (CRIHB) Options",
  "Calvos SelectCare",
  "Cambridge Integrated Services",
  "Cameron Manufacturing & Design, Inc. (UCS)",
  "Camp Lejeune Family Member Program",
  "Cannon Cochran Management Services",
  "Cap Management",
  "CapRock Health Plan - Verity",
  "CapRock HealthPlans",
  "Capistrano Unified School District (CA) - CORVEL",
  "Capital Administrators",
  "Capital Blue Cross",
  "Capital Dental",
  "Capital District Physicians Health Plan (CDPHP)",
  "Capital Health Partners",
  "Capital Health Plan",
  "Capitol Administrators",
  "Capitol Dental Care WVCH",
  "Capitol Indemnity",
  "Capitol Insurance Companies - CORVEL",
  "Cardiovascular Care Providers",
  "Care 1st Health Plan Arizona (Centene)",
  "Care 1st Health Plan of Arizona (Centene)",
  "Care Advantage",
  "Care Improvement Plus (CIP) / XL Health",
  "Care Logic",
  "Care Management Company",
  "Care N' Care",
  "Care N' Care- Southwestern Health Resources",
  "Care Wisconsin Health Plan",
  "Care1st Health Plan of Arizona",
  "CareATC",
  "CareCentrix",
  "CareCore National LLC (Aetna Radiology)",
  "CareCore National LLC (Oxford Radiology)",
  "CareCore National",
  "CareCore/WCNY RAD",
  "CareFactor",
  "CareFirst BCBS Community Health Plan Maryland",
  "CareFirst BCBS of Maryland",
  "CareMore Encounters",
  "CareMore Value Plus (CVP)",
  "CareOregon Inc.",
  "CarePartners of Connecticut",
  "CarePartners",
  "CarePlus Health Plan",
  "CareSource Arkansas",
  "CareSource North Carolina",
  "CareSource Ohio Medicaid",
  "CareSource of Georgia",
  "CareSource of Ohio",
  "CareWorks",
  "Careington Benefit Solutions",
  "Carelon Behavioral Health (Medical Benefits)",
  "Carelon Behavioral Health",
  "Carelon Health IPA of California",
  "Carelon Health – Palliative Care",
  "Caremark WPAS, Inc., Grp# P62",
  "Careplus (Encounters)",
  "Careplus Health Plan",
  "Caresource (FHM)",
  "Caresource Health Plan of Oregon",
  "Caresource West Virginia",
  "Caresource of Indiana",
  "Caresource of Kentucky",
  "Caresource of Michigan Medicaid",
  "Caresource of Ohio",
  "Carewest",
  "Careworks",
  "Carisk Behavioral Health",
  "Cariten Healthcare",
  "Cariten Senior Health",
  "Carolina Behavioral Health Alliance",
  "Carolina Care Plan Inc.",
  "Carolina Complete (Centene)",
  "Carolina SeniorCare",
  "Carpenters Health & Welfare (FCHN)",
  "Carpenters Health & Welfare Fund of Philadelphia & Vicinity",
  "Carpenters Health & Welfare Trust Fund of St. Louis",
  "Carpenters Trust of Western Washington",
  "Caryn Health",
  "Cascade Health Alliance, Inc",
  "Casitas Municipal Water District (CA) - CORVEL",
  "Castlepoint Insurance Company",
  "Castlepoint National Insurance Company",
  "Catholic Life Insurance",
  "Catholic Mutual",
  "Catholic United Financial",
  "Cedar Valley Community HealthCare (CVCH)",
  "Cedars Towers Surgical Medical Group  (Encounters Only, Payer ID Required)",
  "Cedars-Sinai Medical Group (CSHS) - Capitated/Encounters",
  "Cedars-Sinai Medical Group (CSHS) - Fee for Service",
  "CeltiCare Health Plan (Centene)",
  "Celtic Insurance",
  "Celticare Health Plan (Centene)",
  "Cement Masons & Plasterers Health & Welfare Trust Group #F16",
  "CenCal Health",
  "Cendant Corporation",
  "Cenpatico Behavioral Health",
  "Centene Medicare",
  "Center Care PPO (Meritain)",
  "Center For Sight",
  "Center IPA",
  "Center for Elders Independence (CEI)",
  "CenterLight Healthcare",
  "Centers Plan for Healthy Living",
  "Centinela Valley IPA Encounters",
  "Centinela Valley IPA",
  "Centivo",
  "Central Benefits Life",
  "Central Benefits Mutual",
  "Central Benefits National",
  "Central Benefits",
  "Central CA Phys Partners Astrana",
  "Central California Alliance for Health (CCAH)",
  "Central California Alliance for Health ECM/CS",
  "Central California Alliance for Health",
  "Central California Physician Partners (Encounters)",
  "Central California Physician Partners",
  "Central Coast Alliance Health",
  "Central Contra Costa Transit Authority - CORVEL",
  "Central Florida Health",
  "Central Garden & Pet Company - CORVEL",
  "Central Health MSO",
  "Central Health Medicare Plan",
  "Central Hockey League",
  "Central Pennsylvania Teamsters Fund",
  "Central Reserve Life Insurance Company (Medicare Supplement)",
  "Central Reserve Life",
  "Central States Funds",
  "Central States Health & Life",
  "Central States Health & Welfare Funds",
  "Central States Health & Life Co of Omaha Medicare Supplement",
  "Central States Indemnity Medicare Supplement",
  "Central Valley Medical Group",
  "Central Valley Medical Providers CVMEDPRO",
  "Centurion LLC",
  "Centurion",
  "Centurypho",
  "Chaffey Joint Union H. S. District (CA) - CORVEL",
  "Chaffey Medical Group",
  "Champion Payer Solutions",
  "Charming Shoppes, Inc.",
  "Cherokee Insurance",
  "Cherokee Nation Comprehensive Care Agency",
  "Chesapeake Life Insurance Co.",
  "Chesterfield Services, Inc.",
  "Chesterfield Technologies",
  "Chicago Transit Authority - CORVEL",
  "Childhealth Plus by Healthfirst (CHP)",
  "Children First Medical Group",
  "Children of Women Vietnam Veterans - VA HAC",
  "Children's Medical Alliance",
  "Children's Mercy PCN",
  "Children's Physicians Medical Group (CPMG)",
  "Children's Specialists of San Diego II",
  "Children’s Hospital Orange County (CHOC) Health Alliance",
  "Chinese Community Health Plan",
  "Chireno ISD",
  "ChiroCare",
  "Chiropractic Care of Minnesota",
  "Choice Medical Group",
  "Choice Physicians Net CoachellaMed",
  "Choice Physicians Network - Amada Health",
  "Choice Physicians Network - Live Well",
  "Choice Physicians Network - MD Senior Care",
  "Choice Physicians Network Advent Health Medical Corporation",
  "Choice Physicians Network All United Medical Group",
  "Choice Physicians Network Horizon Valley Medical Group",
  "Choice Physicians Network Nivano Physicians",
  "Choice Physicians Network",
  "Christian Brothers Services",
  "Christian Care Ministries (MediShare)",
  "Christian Health Aid",
  "Christiana Care VBR",
  "Christus Health - USFHP",
  "Christus Health Medicare Advantage",
  "Christus Health New Mexico HIX",
  "Christus Health Plan",
  "Christus Spohn Health Plan",
  "Chubb Services",
  "Church Mutual Insurance Company (Auto)",
  "Church Mutual Insurance Company",
  "Cigna HealthSpring",
  "Cigna Healthspring",
  "Cincinnati Insurance",
  "Citizens Choice Health Plan (Fee For Service)",
  "Citizens Insurance Company of America",
  "Citizens Security Life Insurance Company",
  "Citizens of Humanity - CORVEL",
  "Citrus Memorial Health Foundation Inc.",
  "Citrus Valley Health Partners",
  "Citrus Valley IPA",
  "Citrus Valley Physicians Group (CVPG)",
  "Citrus World, Inc.",
  "City Of Arlington",
  "City Of Lake Charles (La) - CORVEL",
  "City of Adelanto",
  "City of Amarillo Group Health Plan",
  "City of Austin",
  "City of Banning (CA) - CORVEL",
  "City of Baytown - CORVEL",
  "City of Beverly Hills - CORVEL",
  "City of Blythe (CA) - CORVEL",
  "City of Cambridge",
  "City of Desert Hot Springs (CA)",
  "City of El Paso",
  "City of Fort Worth (TX)",
  "City of Fountain Valley (CA) - CORVEL",
  "City of Gardena (CA) - CORVEL",
  "City of Glendale (AZ)",
  "City of La Mesa 11 (CA) - CORVEL",
  "City of Laguna Hills (CA) - CORVEL",
  "City of Miami Beach (FL)",
  "City of Milwaukee (WI) - CORVEL",
  "City of Minneapolis",
  "City of Norfolk (VA) - CORVEL",
  "City of Ocoee",
  "City of Omaha (NE) - CORVEL",
  "City of Perris (CA) - CORVEL",
  "City of Pompano Beach (FL)",
  "City of Raleigh - CORVEL",
  "City of Redding (CA)",
  "City of Rialto, CA - CORVEL",
  "City of San Diego",
  "City of San Jacinto (CA) - CORVEL",
  "City of St. Paul (MN)",
  "City of Stanton (CA) - CORVEL",
  "City of Topeka (KS) - CORVEL",
  "City of Torrance",
  "City of Venice",
  "City of Virginia Beach (VA) - CORVEL",
  "City of West Palm Beach (FL)",
  "Clackamas County Mental Health",
  "Clackamas MHO General Fund",
  "Claimchoice Administrators (formerly AmeraPlan)",
  "Claims Administrative Services (CAS)",
  "Claims Choice Administrators LLC",
  "Claims Development Corporation",
  "Claims Management Service Incorporated",
  "ClaimsBridge Custom Provider Network",
  "Claimsware, Inc. DBA ManageMed",
  "Classic Residence By Hyatt",
  "Clear Health Alliance",
  "Clear One Health Plans",
  "Clear Spring Health",
  "Clever Care Health Plan Encounters",
  "Clever Care Health Plan",
  "Clinica Medica San Miguel",
  "Clinical Specialties, Inc",
  "Clinicas del Camino Real",
  "Clover Health (formerly CarePoint Health Plan)",
  "Co-ordinated Benefit Plans LLC",
  "CoMed - CIGNA",
  "CoPatient",
  "Coach USA",
  "Coachella Valley Water District (CA) - CORVEL",
  "CoachellaMed",
  "Coastal Administrative Services",
  "Coastal Communities Physician Network (CCPN) (Regal)",
  "Coastal TPA",
  "Coca-Cola Enterprises, Inc.",
  "Cochran Canon Management Service, Inc. (CCMSI)",
  "Coeur Plan Services, LLC",
  "Cofinity - Group Resources",
  "Collective Health",
  "Colonial Medical",
  "Colonial Penn Life",
  "Colorado Access",
  "Colorado Behavioral Healthcare Inc",
  "Colorado Casualty",
  "Colorado Community Health Alliance",
  "Colorado Health Insurance Cooperative Inc.",
  "Colorado Kaiser Permanente",
  "Columbia Mutual Insurance Company",
  "Columbia Sussex Corporation",
  "Columbine Health Plan",
  "ComPsych",
  "Combined Insurance Company",
  "Commerce & Industry/Greenwood",
  "Commerce Benefits Group",
  "Common Ground Healthcare",
  "Commonwealth Care Alliance (CCA)",
  "Commonwealth Care Alliance - Medicare Advantage",
  "Commonwealth Care Alliance",
  "Commonwealth Health Alliance (CHA)",
  "Commonwealth of Massachusetts GIC",
  "CommuniCare Advantage",
  "Community Care Alliance of IL",
  "Community Care Alliance of Illinois",
  "Community Care Associates",
  "Community Care BHO",
  "Community Care IPA",
  "Community Care Inc. (Wisconsin)",
  "Community Care Inc. - Family Care (Wisconsin)",
  "Community Care Managed Health Care Plans of Oklahoma",
  "Community Care Plan (Broward Health PPUC)",
  "Community Care Plan (Commercial)",
  "Community Care Plan (Florida Healthy Kids)",
  "Community Care Plan (Medicaid)",
  "Community Care Plan (Palm Beach District)",
  "Community Development Commission (CA) - CORVEL",
  "Community Family Care Health Plan",
  "Community First",
  "Community Health Alliance of Tennessee",
  "Community Health Alliance",
  "Community Health Center Network - CHCN",
  "Community Health Choice - Health Insurance Marketplace",
  "Community Health Choice",
  "Community Health First Medicare Advantage",
  "Community Health Group of SD (CHGSD) - Capitated Claims",
  "Community Health Group of SD (CHGSD) - FFS Claims",
  "Community Health Group of San Diego",
  "Community Health Plan District of Columbia",
  "Community Health Plan of Washington (CHP of WA)",
  "Community Health Plan of Washington",
  "Community Life",
  "Community Medical Centers (CA) - CORVEL",
  "Community Medical Group of the West Valley (Regal)",
  "Community Transit (WA only) - CORVEL",
  "CommunityCare Oklahoma (CCOK)",
  "CompDent / CompBenefits",
  "CompManagement Health",
  "Companion Property and Casualty Insurance Company",
  "Companion",
  "Compass Group USA, Inc.",
  "Complementary Healthcare Plan (CHP)",
  "Complete Care IPA",
  "Complimentary Healthcare Plans",
  "Comprehensive Care Management",
  "Comprehensive Care Services",
  "CompuSys Inc",
  "Concentrix Insurance Solutions",
  "Concierge HMO IPA",
  "Concierge TPA",
  "Concordia Care, Inc",
  "Confederation Administration Services & Life Insurance",
  "Conifer (Workers Comp)",
  "Conifer Health Solutions",
  "Connecticare (Commercial)",
  "Connecticare - Medicare Advantage (Emblem)",
  "Connecticare Medicare",
  "Connecticare",
  "Connecticut Carpenters Health Fund",
  "Connecticut General Life",
  "Connecticut General",
  "Connecticut Indemnity Company",
  "Consociate Group",
  "Consolidated Associates Railroad",
  "Consolidated Health Plans",
  "Consumer Mutual of Michigan",
  "Consumers Choice Health SC",
  "Consumers Life Insurance Company",
  "Container Graphics Corporation",
  "Contech Construction Products",
  "Contessa Health - Security Health Plan",
  "Contessa Health",
  "Continental Airlines",
  "Continental Benefits",
  "Continental General Insurance Company",
  "Contra Costa Behavioral Health Plan",
  "Contra Costa Health Plan",
  "Contractors, Laborers, Teamsters & Engineers (Local 14B)",
  "Convergys Corporation",
  "Cook Childrens Health Plan",
  "Cook Childrens Star Plan",
  "Cook County Health - Behavioral",
  "Cook County Health - Medical",
  "Cook Group Health Plan",
  "Cook Group Solutions",
  "Cook Medical Group",
  "Cooperative Benefit Administrators (CBA)",
  "Coordinated Care (Centene)",
  "Coordinated Medical Specialists",
  "Copperpoint Mutual Insurance Company",
  "Core Administrative Services",
  "Core Benefits / Multimatic Tennesse, LLC",
  "Core Management Resources Group",
  "CoreCivic",
  "CoreFive (Mega Life & Health)",
  "CoreFive (SelectCare)",
  "CoreSource (MD, PA, IL, IA)",
  "CoreSource (NC, IN)",
  "CoreSource - Detroit",
  "CoreSource - Little Rock",
  "CoreSource - OH",
  "CoreSource KC (FMH)",
  "Corizon Inc (Formerly Correctional Medical Services)",
  "Cornerstone Benefit Administrators",
  "Cornerstone Preferred Resources",
  "Corporate Benefit Service (CBSA)",
  "Corporate Benefits Service, Inc. (NC)",
  "CorrectCare Integrated Health (CA Prison Health Care Services)",
  "Correctcare (GA Correctional Healthcare Adult Health)",
  "Correctcare Integrated Health (Non-LA Jails)",
  "Correction Health Partners",
  "Correctional Management Service",
  "Correctional Medical Services, Inc. (Now known as Corizon Inc)",
  "Corrections Corporation of America (CCA)",
  "Corrigan-Camden ISD",
  "Corvel",
  "Cottingham & Butler Claim Services, Inc.",
  "Cottingham and Butler (Equian)",
  "Country Casualty Insurance Company",
  "Country Financial Medicare Supplement",
  "Country Financial Modern Service Casualty Insur Co - CORVEL",
  "Country Insurance MSI Preferred Insurance Company",
  "Country Insurance Modern Service Casualty Ins Co",
  "Country Insurance Mountain States Insurance - CORVEL",
  "Country Life Insurance Company",
  "Country Mutual Insurance Company",
  "Country Preferred Insurance Company",
  "Countryway Insurance Company",
  "County Care Health Plan",
  "County Medical Services Program (CMSP)",
  "County Of San Diego",
  "County of Chesterfield, VA",
  "County of Fresno",
  "County of Kern - CORVEL",
  "County of Kern POS",
  "County of Los Angeles (1000)",
  "County of Los Angeles (2000)",
  "County of Los Angeles (3000)",
  "County of Los Angeles (4000/5000)",
  "County of Sacramento - EMSF",
  "County of Sacramento - Healthy Partners",
  "County of Shasta (CA)",
  "County of Tulare (CA) - CORVEL",
  "Covenant Administrators Inc.",
  "Covenant Management System Employee Benefit Plan",
  "Covenant Management Systems",
  "Coventry Health Care Carelink Medicaid",
  "Coventry Health Care USA (HCUSA)",
  "Coventry Health Care of  MO, MS, AK, TN(00184)",
  "Coventry Health Care of Georgia",
  "Coventry Health Care of Louisiana",
  "Coventry National/University of MO(00250)",
  "Coventry National/University of MO(COVON)",
  "Coventry Wellpath Select",
  "CoventryCares of West Virginia",
  "Coworx Staffing, LLC",
  "Cox Health Plan",
  "Craftworks Holdings - CORVEL",
  "Create Healthcare",
  "Crescent Health Solutions",
  "Crestbrook Insurance Company",
  "Crossway HealthShare",
  "Crown Life - Great West",
  "Croy - Hall Management, Inc.",
  "Crum & Forster Indemnity Co",
  "Crum & Forster Medicare Supplement",
  "Crystal Run Health Plans",
  "Culinary Health Fund",
  "Cumberland Insurance Group",
  "CuraeChoice",
  "Curative Health",
  "Custody Medical Services Program (CMSP)",
  "Custom Benefit Administrators",
  "Custom Design Benefits, Inc.",
  "Cypress Security (Member of BHHC)",
  "DBi Services LLC - CORVEL",
  "DC Health Ryan White Program",
  "DC Risk Solutions",
  "DCHP Texas Medicaid",
  "DDIC",
  "DH Cook Associates",
  "DHMN - Preferred IPA Hospital Risk",
  "DHMN Santa Cruz",
  "DHR Provider Management",
  "DMERC Region A Medicare",
  "DMERC Region B Medicare",
  "DMERC Region C Medicare",
  "DMERC Region D Medicare",
  "Dairyland Insurance Company",
  "Dameron Hospital Association",
  "Darigold - CORVEL",
  "Dart Management Corporation",
  "Dawson ISD",
  "Dean Health Plan (Medica)",
  "Dean Health Plan",
  "Definity Health",
  "Del Taco, LLC",
  "Delano Regional Medical Group (Managed Care Systems)",
  "Delaware First Health (Centene)",
  "Dell Childrens Health Plan",
  "Dell, Inc. - CORVEL",
  "Delta Dental (Northeast - ME, NH, VT)",
  "Delta Dental Classic",
  "Delta Dental Clear",
  "Delta Dental Encara",
  "Delta Dental Ins. Co. - Alabama",
  "Delta Dental Ins. Co. - Florida",
  "Delta Dental Ins. Co. - Georgia",
  "Delta Dental Ins. Co. - Louisiana",
  "Delta Dental Ins. Co. - Mississippi",
  "Delta Dental Ins. Co. - Texas",
  "Delta Dental Ins. Co. - Utah",
  "Delta Dental Of Alaska",
  "Delta Dental Texas Childrens Medicaid Services",
  "Delta Dental of Arizona",
  "Delta Dental of Arkansas",
  "Delta Dental of California",
  "Delta Dental of Colorado",
  "Delta Dental of Connecticut",
  "Delta Dental of Delaware",
  "Delta Dental of Georgia",
  "Delta Dental of Idaho",
  "Delta Dental of Illinois - Individual",
  "Delta Dental of Illinois",
  "Delta Dental of Iowa - DWP",
  "Delta Dental of Iowa",
  "Delta Dental of Kentucky",
  "Delta Dental of Maryland",
  "Delta Dental of Massachusetts",
  "Delta Dental of Missouri",
  "Delta Dental of Montana",
  "Delta Dental of Nevada",
  "Delta Dental of New Jersey",
  "Delta Dental of New York",
  "Delta Dental of Oklahoma",
  "Delta Dental of Pennsylvania",
  "Delta Dental of Puerto Rico",
  "Delta Dental of Rhode Island",
  "Delta Dental of South Carolina in Missouri",
  "Delta Dental of South Dakota",
  "Delta Dental of Texas",
  "Delta Dental of Virginia",
  "Delta Dental of WA",
  "Delta Dental of Washington DC",
  "Delta Dental of Washington State",
  "Delta Dental of West Virginia",
  "Delta Dental of Wisconsin",
  "Delta Dental of Wyoming",
  "Delta Health Systems",
  "DeltaCare USA - Claims",
  "DeltaCare USA - Encounters",
  "DentaQuest -Government",
  "DentaQuest",
  "Dental Benefit Providers",
  "Dental Choice Plan Plus",
  "Dental Choice Plan",
  "Dental Health & Wellness",
  "Dental Select",
  "Dentegra",
  "Denti-Cal DTI Encounter",
  "Denti-Cal",
  "Denver Health Medical Plan Inc.",
  "Denver Health Medical Plan",
  "Denver Health Medical Plan, In. - Medicare Choice",
  "Denver Health and Hospital Authority",
  "Department Rehabilitative Services - Oklahoma",
  "Department of Corrections - Oklahoma",
  "Department of Social and Health Services (DSHS)",
  "Depositors Insurance Co",
  "Deseret Mutual Benefit Administrators",
  "Desert Medical Group (Regal)",
  "Desert Oasis Healthcare (DOHC) (Regal)",
  "Desert Valley Medical Group",
  "Detego Health",
  "Detroit Medical Center",
  "Devoted Health",
  "Diabetes Heart Care CSNP",
  "Diamond Bar Medical Group",
  "Diamond Insurance Group, LTD",
  "Dignity Global",
  "Dignity HCLA",
  "Dignity Health - Mercy Medical Group / Woodland Clinic",
  "Dignity Health - Sequoia Physicians Network",
  "Dignity Health MSO",
  "Dignity Health Medical Group - Inland Empire",
  "Dignity Health Medical Network - Central California",
  "Dignity Health Medical Network - Santa Cruz",
  "Dignity Health Plan",
  "Dimension Benefit Management",
  "Diploma Joint Insurance Fund (SEL)",
  "Direct Care Administrators",
  "Direct Dental Administrators",
  "DirectCARE",
  "Director's Guild of America - Producer Health Plans",
  "Directors Guild",
  "Discount Tire",
  "Disneyland Resorts (Conduent - Formerly Bunch CareSolutions)",
  "District 9 Machinists Welfare Trust",
  "District No. 9. I. A. of M. & A.W. Welfare Trust",
  "Diversified Administration Corporation",
  "Diversified Group Administrators",
  "Diversified Group Brokerage",
  "Doctors Choice Medical Group",
  "Doctors Healthcare Plans",
  "Doctors Managed IPA",
  "Doctor’s Professional Services Consultants",
  "Dominion Dental (115 S. Union St., Ste. 300, Alexandria, VA 22314)",
  "Downey Select IPA",
  "Dreyer Health",
  "Dreyer Medical Clinic",
  "Driscoll Childrens Health Plan",
  "Dunn & Associates Benefits Administrators, Inc",
  "Dupage Medical Group",
  "EBA&M; Employee Benefits Administration & Management",
  "EBC (Buffalo Rock) Birmingham",
  "EHI, EHIC",
  "EMAEHIPA",
  "EMCASCO Insurance Co - WC ONLY",
  "EMHS Employee Health Plan",
  "EMI Health",
  "EMI-KP Ambulance Claims",
  "EMPHESYS",
  "ENTRUST",
  "EPN (Seton Health Plan)",
  "ERMC",
  "ES Beveridge & Associates",
  "ESIS Auto",
  "ESIS Workers Comp",
  "Early Intervention Central Billing",
  "East Bay Drainage Drivers Security Fund",
  "East West Administrators",
  "EastGUARD Insurance Company - Guard DBA",
  "EastPointe Behavioral Health",
  "Eastern Iowa Community Healthcare (EICH)",
  "Eastern Oregon CCO",
  "Eastland Medical Group IPA (Regal)",
  "Eastman Kodak Company - CORVEL",
  "Easy Access Care IPA",
  "Easy Care MSO LLC",
  "Easy Choice Health Plan of California (Fee for Service) - ECHP",
  "Easy Choice Health Plan of California",
  "EasyAccess Care IPA (ProCare MSO)",
  "Eberle Vivian (Workers Comp)",
  "Eberle Vivian",
  "Ebix Health Administration Exchange (EHAE)",
  "Edinger Medical Group",
  "Edison Health Solutions",
  "Edwards Claim Administration",
  "El Camino Health Alliance",
  "El Camino Health Medical Network",
  "El Paso Community College (TX)",
  "El Paso First - CHIP",
  "El Paso First Health Plans Premier Plan STAR Medicaid HMO",
  "El Paso Water Utilities TX",
  "El Proyecto De Barrio",
  "ElderPlan, Inc.",
  "Elderwood Health",
  "Electric Insurance Company",
  "Electrical Welfare Trust Fund",
  "Electronic Practice Solutions, Inc.",
  "Element Care, Inc.",
  "Elips Life Insurance Company Medicare Supplement",
  "ElmCare LLC",
  "Elmira Savings Bank (UCS)",
  "Emanate Health IPA (NMM)",
  "Emanate Health IPA",
  "Emanate Health Med Center NMM",
  "Emanate Health Med Center PDT MSO",
  "Emblem Dental (DentaQuest)",
  "Emergency Medical Service Fund (EMSF)",
  "Emeritus Corporation",
  "Empire Dental - Blue Cross Blue Shield (Anthem)",
  "Empire Healthcare IPA",
  "Employee Benefit Concepts",
  "Employee Benefit Corporation",
  "Employee Benefit Logistics (EBL)",
  "Employee Benefit Management Corp (EBMC)",
  "Employee Benefit Management Services (EBMS)",
  "Employee Benefit Management, Inc. (EBSI)",
  "Employee Benefit Services  - Ft. Mill, South Carolina",
  "Employee Benefit Services (EBS of San Antonio)",
  "Employee Benefits Consultants",
  "Employee Benefits Plan Administration (EBPA)",
  "Employee Benefits administrators (EBA)",
  "Employee Health Coalition",
  "Employee Plans, LLC",
  "Employer Direct Healthcare",
  "Employer Driven Insurance Services",
  "Employer's Direct Health - Self",
  "Employers Assurance Company",
  "Employers Compensation Insurance Company",
  "Employers Direct Health",
  "Employers General Insurance Group",
  "Employers Health Coalition (Healthscope)",
  "Employers Health Insurance of Wisconsin",
  "Employers Health Network",
  "Employers Insurance (Bunch CareSolutions)",
  "Employers Insurance Group (EIG)",
  "Employers Life Insurance Corporation",
  "Employers Mutual Casualty - AUTO ONLY",
  "Employers Mutual Casualty - LIAB ONLY",
  "Employers Mutual Casualty - WC ONLY",
  "Employers Preferred Insurance Company",
  "Empower Arkansas",
  "Encircle PPO",
  "Encompass (Subsidiary of Allstate)",
  "Encompass Specialty Surgery Network",
  "Encore Health Network",
  "Enhancedcare MD",
  "Enstar Natural Gas, Group # P61",
  "Enterprise Group Planning",
  "Envolve Vision (Formerly known as OptiCare Managed Vision)",
  "Eon Health",
  "Epic Assistance",
  "Epic Specialty Benefits (WPS)",
  "Equian",
  "Equicor - PPO",
  "Equicor",
  "Equitable Plan Services",
  "Ergon, Inc. - CORVEL",
  "Erie Insurance Medicare Supplement",
  "Erin Group Administrators",
  "Erisa Administrative Services",
  "Esperanza IPA",
  "Essence Healthcare",
  "Essential Health Partners IPA",
  "Essential Health Partners",
  "Essex Dental Benefits",
  "Esurance Insurance Company",
  "EverNorth Behavioral Health",
  "Everence",
  "Everest Medicare Supplement",
  "Evergreen Health Cooperative",
  "Evergreen Health",
  "Everpointe",
  "Evolutions Healthcare Systems",
  "Evry Health",
  "Exceedent LLC",
  "Excel MSO UHC PPO",
  "Excel MSO",
  "Exceptional Care Medical Group (ECMG)",
  "Exclusicare",
  "Exemplar Health Insurance Company",
  "Exemplar Health",
  "Express, LLC - CORVEL",
  "Extended MLTC",
  "ExxonMobil Corporation - CORVEL",
  "Eye Management Inc.",
  "EyeQuest",
  "Eyecare 2020",
  "Eyenez Eye Institute",
  "F40 Alaska Carpenters Trust",
  "FABOH (CHP RPU)",
  "FCCI",
  "FCE Benefit Administrators",
  "FCS Administrators",
  "FEP BlueDental (International)",
  "FKI Industries, Inc.",
  "FMI",
  "Facey Medical Foundation",
  "Factory 2 U",
  "Fair Price",
  "Fairchild Fasteners - CORVEL",
  "Fairfax County Government - CORVEL",
  "Fairmont Specialty Insurance Company - TX",
  "Fairview Health Services",
  "Falling Colors (BHSD STAR)",
  "Fallon Community Health",
  "Fallon Health (Transplant Claims ONLY)",
  "Fallon Total Care",
  "Fallon Transplant",
  "Family Care Medicaid",
  "Family Care Specialists (FCS)",
  "Family Choice Medical Group",
  "Family Dental Care, Inc.",
  "Family Health Alliance",
  "Family Health Network",
  "Family Medical Network",
  "Family Practice Medical Group",
  "Family Seniors Medical Group",
  "Farm Bureau Advantage HMO",
  "Farm Bureau Health Plans MAPD",
  "Farm Bureau Health Plans",
  "Farm Bureau Mutual Insurance Company",
  "Farmer's Insurance (Commercial)",
  "Farmers Insurance Exchange",
  "Farmers Insurance",
  "Farmers Civic Property and Casualty Company",
  "Farmers Exact Property and Casualty Company",
  "Farmers Fire Insurance Exchange",
  "Farmers Mid-Century Insurance Company",
  "Farmers Neighborhood Spirit Property and Casualty Company",
  "Farmers Truck Insurance Exchange",
  "Farmers of Flemington",
  "Farmland Mutual Insurance Co",
  "Federal Employee Plan of South Carolina",
  "Federal Employees Plan WA (Regence Blue Shield)",
  "Federal Mogul - CORVEL",
  "Federated Mutual Insurance Company (WC only)",
  "Federated Mutual Insurance Company",
  "Federated Rural Electric (Tx)",
  "Federated Services Insurance Company",
  "Fenix Medical Group",
  "Fenyx Health",
  "Fidelis (CenterCare)",
  "Fidelis Care New York",
  "Fidelity Mohawk",
  "Fidelity Security Life Insurance",
  "Firefly",
  "Fireman's Fund Insurance Company",
  "First Ameritas Life Insurance Corp. of NY",
  "First Carolina Care",
  "First Choice Health Administrators (FCHA)",
  "First Choice Health Network (FCHN)",
  "First Choice MA Plans",
  "First Choice Medical Group/Meritage",
  "First Choice Next A Product of Select Health of SC, Inc.",
  "First Choice VIP Care Plus SC",
  "First Choice VIP Care",
  "First Community Health Plan",
  "First Dental Health (CA)",
  "First Great West Life & Annuity Ins. Co.",
  "First Health Network (f.k.a. CCN Managed Care Inc. & PPO Oklahoma)",
  "First Keystone Risk Retention Group",
  "First Managed Care Option, Inc",
  "First Medical Health Plan (Reform)",
  "First Medical Network",
  "First Priority Health (FPH) - Highmark",
  "First Priority Life Insurance Company (FPLIC) - Highmark",
  "First Reliance Standard",
  "First Responder Joint Insurance Fund",
  "First Source (Endeavor)",
  "First United American Insurance Company",
  "FirstMedicare Direct",
  "FirstNation Health",
  "FirstSolutions",
  "Firstcare (Medicaid)",
  "Firstcare and Firstcare Medicaid",
  "Firstcomp",
  "Fitzharris & Company, Inc.",
  "Florida Blue HMO",
  "Florida Community Care",
  "Florida Complete Care",
  "Florida Health Administrators",
  "Florida Health Care Plan",
  "Florida Healthcare Plan",
  "Florida Hospital Orlando VBR",
  "Florida Hospital Waterman",
  "Florida Pace Centers Inc",
  "Florida True Health",
  "Florists Insurance Company",
  "Florists Mutual Insurance Company",
  "Flour Bluff ISD",
  "Flume Health, Inc",
  "Focus Plan NC",
  "Food, Bakery, Confection Workers Fund of So Cal",
  "Foothill Eye Care Services",
  "Ford Motor Company",
  "Forest County Potawatomi Insurance Department",
  "Forethought",
  "Foundation Health HMO",
  "Foundation for Medical Care of Tulare & Kings County",
  "Fountain Valley IPA (FVIPA)",
  "Fountain Valley Premier IPA",
  "Fox Valley Medicine Site 199",
  "Franciscan Alliance - CORVEL",
  "Frankenmuth Mutual",
  "Franklin Mutual",
  "Freedom Blue",
  "Freedom Claims Management",
  "Freedom Health Incorporated",
  "Freedom Health Plan",
  "Freedom Life Insurance Company",
  "Fresno PACE",
  "Friday Health Plans",
  "Friedkin Companies, Inc - CORVEL",
  "Fringe Benefit Group Inc (Employer Plan Services, Inc. - Austin)",
  "Fringe Benefit Group Inc (Employer Plan Services, Inc. - Houston)",
  "Fringe Benefit Group",
  "Fringe Benefit Management (FBMC)",
  "Fringe Benefits Coordinators",
  "Fringe Benefits",
  "FrontPath Health Coalition",
  "Fullerton Union High School District (CA) - CORVEL",
  "GE Auto - CORVEL",
  "GEHA – ASA",
  "GEHA – Aetna Signature Administrators",
  "GEMCare Medical Group (Managed Care Systems)",
  "GHA Orthopedic Providers Inc.",
  "GHI Emblem Health",
  "GI Innovative Management",
  "GIC Indemnity Plan",
  "GMAC Insurance Management Corporation",
  "GMMI CC",
  "GMP Employers Retiree Trust",
  "GMR Healthcare",
  "GRAVIE",
  "Gainsco",
  "Gallagher Bassett Services, Inc.",
  "Galveston County Indigent Health Care",
  "Garden State Life Insurance Company",
  "Garden State Life Insurance Medicare Supplement",
  "Garretson Resolution Group",
  "Gateway Health Plan (Medicaid PA)",
  "Gateway Health Plan - Medicare Assured",
  "Gateway Health Plan",
  "Gateway IPA (Pinnacle Health Resources)",
  "Gateway to Better Health Plan - MO Medicaid Plan FFS",
  "Geico Insurance",
  "Geisinger Health Plan",
  "Geisinger Health Plans",
  "GemCare Health Plan, Kern County EPO",
  "GemCare",
  "Genelco / Gencare Health",
  "General Hospital (Humboldt Del Norte)",
  "General Parts International",
  "General Vision",
  "Generations Healthcare",
  "Genesis Healthcare (EMG)",
  "Genesis Medical Group",
  "Georges Inc. - CORVEL",
  "Georgia Health Advantage",
  "Georgia Medicaid - Grady Healthcare, Inc.",
  "Giatros Health IPA",
  "Gillette Childrens Specialty Healthcare - CORVEL",
  "Gilsbar",
  "Glendale Adventist Medical Center",
  "Glendale Memorial Group (Regal)",
  "Glendale Physicians Alliance (Regal)",
  "Global Benefits Group",
  "Global Care Inc.",
  "Global Care Medical Group IPA",
  "Global Excel Management",
  "Global Health",
  "Global Healthcare Alliance",
  "Global Liberty Insurance Company of New York",
  "Global Life and Accident Insurance Company",
  "Global One Ventures",
  "Global TBSP",
  "Global Women's Health Providers Inc.",
  "Globe Life & Accident Insurance Company",
  "Gloria Gates Care",
  "Gold Coast",
  "Gold Kidney Health Plan",
  "Golden Bay Health Plan",
  "Golden Coast MSO",
  "Golden Eagle",
  "Golden Physicians Medical Group",
  "Golden State Medicare Health Plan",
  "Golden Triangle Physician Alliance (GTPA)",
  "Golden West Dental (888 W. Vantura Blvd., Camarillo, CA)",
  "Good Samaritan Hospital (GSH)",
  "Good2go Insurance (aka Personal Service Insurance)",
  "Goodfellow Top Grade Construction LLC",
  "Goodrich",
  "Government Employees Hospital Association (GEHA)",
  "Grady Healthcare - Georgia Medicaid",
  "Grand River Insurance (Auto only)",
  "Grand River Insurance (WC only)",
  "Grange Mutual Casualty Company",
  "Grange WC",
  "Granite State (Centene)",
  "Gravie Inc",
  "Great American Alliance Ins Co.",
  "Great American Assurance Co.",
  "Great American Ins. Co. of NY",
  "Great American Ins. Co.",
  "Great American Life Insurance Company - Medicare Supplement",
  "Great Divide Insurance",
  "Great Plains Medicare Advantage",
  "Great Southern Life Insurance Co Medicare Supplement",
  "Great States Health IICT",
  "Great West Health Care",
  "Great-West Life & Annuity Insurance Company",
  "Greater Covina Medical Group (GCMG) (Regal)",
  "Greater L.A. Healthcare",
  "Greater Newport Physicians MemorialCare (formerly known as Greater Newport Physicians)",
  "Greater Orange Medical Group",
  "Greater Oregon Behavioral Health Inc",
  "Greater San Gabriel Medical Group",
  "Greater San Gabriel Valley Physicians",
  "Greater Tri-Cities IPA",
  "Green Diamond WA - CORVEL",
  "Greentree Administrators",
  "Grinnell Reinsurance Company",
  "Grinnell Select - CORVEL",
  "Group Administrators Ltd.",
  "Group Benefit Services",
  "Group Benefit Services, Inc. (Missouri)",
  "Group Benefits - Louisiana",
  "Group Health Cooperative (GHC)",
  "Group Health Cooperative of Eau Claire",
  "Group Health Cooperative of South Central Wisconsin (Claims)",
  "Group Health Cooperative of South Central Wisconsin (Encounters)",
  "Group Health Cooperative",
  "Group Health Incorporated",
  "Group Health Options (Pacific Northwest)",
  "Group Health, Inc. (GHI HMO) (Emblem)",
  "Group Health, Inc. (GHI PPO) (Emblem)",
  "Group Insurance Service Center",
  "Group Management Services, Inc. (GMS)",
  "Group Marketing Services, Inc.",
  "Group Plan Solutions",
  "Group Practice Affiliates",
  "Group Resources",
  "Group and Pension Administrators",
  "GroupLink",
  "Guaranty Fund Management Services",
  "Guard",
  "Guardian Life (The Guardian)",
  "Guidant Health Plan",
  "GuideOne Elite Insurance",
  "GuideOne Mutual Insurance",
  "Guidewell Mutual Holding - CORVEL",
  "Guildnet",
  "Gulf Guaranty or MedPlus",
  "Gulf Quest IPA HMO Blue",
  "Gulf South",
  "Gulf Stream (CoreSource)",
  "Guy Yocomo Construction",
  "H & A  Administrators",
  "H & R Block",
  "H.E.R.E Health Trust",
  "HAA Preferred Partners",
  "HAP Caresource MI Health Link (Medicare-Medicaid Plan)",
  "HAP Midwest Health Plans",
  "HAP/AHL/Curanet",
  "HCA - Hospital Corporation of America",
  "HCC Life Insurance",
  "HCH Administration - Illinois",
  "HCH Administration, Inc.",
  "HCS - Health Claims Service",
  "HDPC - Premier Care (High Desert Primary Care Premier)",
  "HEALTHY BLUE DUAL ADVANTAGE LOUISIANA",
  "HEALTHe Exchange",
  "HHS National Disaster Medical System-Apprio",
  "HIP Healthplan of NY",
  "HK Administrative Partners LLC",
  "HMA Hawaii",
  "HMC HealthWorks",
  "HMO LA Blue Advantage",
  "HMO Louisiana Blue Advantage",
  "HMS Vineyards - CORVEL",
  "HMSA Quest",
  "HMSHost - CORVEL",
  "HN1 Therapy Network",
  "HNS WellCare SC",
  "HPI Vanderbilt",
  "HSA Health Insurance Company",
  "HSI Care Services",
  "Halcyon Behavioral Health",
  "Hamaspik Choice",
  "Hannaford",
  "Hanover Insurance Company",
  "Hanover Lloyd's Insurance Company",
  "Harbor Health Plan (ProCare)",
  "Harleysville Insurance Company",
  "Harmony Health Plan",
  "Harrington Health - Kansas (Formerly Fiserv/Willis)",
  "Harrington Health Non-EPO",
  "Hartford Accident and Indemnity Company",
  "Hartford Casualty Insurance Company",
  "Hartford Insurance Company of the Midwest",
  "Hartford Life Insurance Company",
  "Hartford Life and Accident Insurance Company",
  "Hartford Life and Annuity Insurance Company",
  "Hartford Underwriters Insurance Company",
  "Harvard Community Health Plan (AKA Harvard Pilgrim Stride)",
  "Harvard Pilgrim Health Care",
  "Harvard Pilgrim Passport Connect",
  "Hawaii Employers' Mutual Insurance Co., Inc",
  "Hawaii Mainland Administrators",
  "Hawaii Management Alliance Association (HMAA)",
  "Hawaii Medical Alliance Association (HMAA)",
  "Hawaii Medical Assurance Association (HMAA)",
  "Hawaii Medical Service Association",
  "Hawaii Western Management Group (HWMG)",
  "Hawkeye",
  "Health 2 Business",
  "Health Alliance Medical Plan of Illinois",
  "Health Alliance Medical Plan",
  "Health Alliance Plan of Michigan",
  "Health Alliance Plan",
  "Health Care District Palm Beach",
  "Health Care Facilities of Missouri, Inc. - CORVEL",
  "Health Care LA IPA (HCLA)",
  "Health Choice Generations",
  "Health Choice Pathway",
  "Health Choice of Arizona",
  "Health Choice of Utah",
  "Health Choices",
  "Health Comp",
  "Health Cost Solutions (HCS)",
  "Health Design Plus - Hudson, Ohio",
  "Health EOS",
  "Health Economic Livelihood Partnership (HELP)",
  "Health Economics Group Inc.",
  "Health Equity",
  "Health Excel IPA",
  "Health First Colorado (Medicaid)",
  "Health First Health Plan",
  "Health First Health Plans - Oscar",
  "Health First Health Plans",
  "Health In Tech",
  "Health Insurance Plan (HIP) of Greater New York (Emblem)",
  "Health Net - California",
  "Health Net - Missouri",
  "Health Net - Oregon",
  "Health Net - Washington",
  "Health Net Commercial",
  "Health Net Medicare Advantage (Centene)",
  "Health Net National",
  "Health Net of the Northeast",
  "Health Network One",
  "Health Network Solutions (HNS) - Absolute Total Care SC",
  "Health Network Solutions (HNS) - BCBS NC",
  "Health Network Solutions (HNS) - CIGNA NC",
  "Health Network Solutions (HNS) - CIGNA SC",
  "Health Network Solutions (HNS) - HealthSpring NC",
  "Health Network Solutions (HNS) - HealthSpring SC",
  "Health Network Solutions (HNS) - MedCost NC",
  "Health Network Solutions (HNS) - MedCost SC",
  "Health Network Solutions (HNS) - Select Health SC",
  "Health Network Solutions (HNS) HealthTeam Advantage NC",
  "Health New England",
  "Health Now New York",
  "Health Options (Florida Blue)",
  "Health Partners Pennsylvania",
  "Health Partners Plans (Philadelphia, PA)",
  "Health Partners of Pennsylvania",
  "Health Payment Systems, Inc.",
  "Health Plan Management",
  "Health Plan Nevada",
  "Health Plan Services",
  "Health Plan of Michigan",
  "Health Plan of Nevada - Encounters",
  "Health Plan of Nevada",
  "Health Plan of San Joaquin",
  "Health Plan of San Mateo Care Advantage (HMO)",
  "Health Plan of San Mateo",
  "Health Plans, Inc.",
  "Health Plus Physician Organization (HPPO)",
  "Health Resources, Inc.",
  "Health Services for Children with Special Needs (HSCSN)",
  "Health Share CCO Clackamas County",
  "Health Share of Oregon CCO (CareOregon Behavioral Health/SUD)",
  "Health Share/Care Oregon",
  "Health Source MSO",
  "Health Special Risk, Inc.",
  "Health Systems International - ECOH",
  "Health Texas Medical Group",
  "Health York Network",
  "HealthBridge",
  "HealthCare Partners IPA - New York",
  "HealthCare Partners of Nevada",
  "HealthCare Resources NW",
  "HealthCare Solutions Group",
  "HealthCare's Finest Network (HFN)",
  "HealthChoice Connecticut",
  "HealthChoice Dept of Corrections & Rehab",
  "HealthChoice Oklahoma",
  "HealthChoice Select",
  "HealthChoice of Arizona",
  "HealthChoice",
  "HealthComp",
  "HealthCosmos Medical Group LLC",
  "HealthCosmos of Nevada PLLC",
  "HealthCosmos of New Mexico LLC",
  "HealthEQ Westlake Medical Center",
  "HealthEZ",
  "HealthEdge Administrators",
  "HealthFirst (HMO)",
  "HealthFirst TPA",
  "HealthGroup Limited",
  "HealthNet – Gov’t Program",
  "HealthNow",
  "HealthPartners (MN)",
  "HealthPlan Services",
  "HealthPlan of San Mateo (CalAIM)",
  "HealthPlus Managed Long Term Care",
  "HealthPlus of Louisiana",
  "HealthPlus of Michigan",
  "HealthSCOPE Benefits, Inc.",
  "HealthScope UMR",
  "HealthShare CCO Dental",
  "HealthSmart Accel",
  "HealthSmart Benefit Solutions (FKA Wells Fargo TPA)",
  "HealthSmart Benefit Solutions (Formerly Pittman and Assoc)",
  "HealthSmart Benefit Solutions (Formerly Pittman and Associates)",
  "HealthSmart Benefit Solutions (formerly American Administrative/Gallagher Benefit)",
  "HealthSmart Benefit Solutions (formerly JSL Administrators)",
  "HealthSmart Benefit Solutions (formerly Wells Fargo TPA/Acordia National)",
  "HealthSmart Benefit Solutions West Virginia",
  "HealthSmart Benefit Solutions",
  "HealthSmart MSO",
  "HealthSmart Preferred Care",
  "HealthSpan Inc",
  "HealthTeam Advantage",
  "Healthcare Group, The",
  "Healthcare Highways Health Plan",
  "Healthcare Highways",
  "Healthcare Management Administrators (HMA)",
  "Healthcare Options",
  "Healthcare Risk Management Solutions - CORVEL",
  "Healthease Kids",
  "Healthease",
  "Healthfirst - New York",
  "Healthfirst 65 Plus",
  "Healthfirst Family Health Plus (FHP)",
  "Healthfirst Health Plan of New Jersey",
  "Healthfirst Inc",
  "Healthfirst PHSP",
  "Healthfirst Tyler, Texas",
  "Healthfirst of New Jersey",
  "Healthfirst of New York",
  "Healthgram",
  "Healthlink PPO",
  "Healthly Medical Group",
  "Healthplex",
  "Healthsource (CIGNA)",
  "Healthsource CMHC",
  "Healthsun Health Plan",
  "Healthways WholeHealth Network",
  "Healthy Blue Dual Advantage Louisiana",
  "Healthy Blue LA",
  "Healthy Blue Missouri",
  "Healthy Blue Nebraska",
  "Healthy Blue North Carolina",
  "Healthy Blue South Carolina",
  "Healthy CT",
  "Healthy Equation",
  "Healthy Families Program",
  "Healthy Montana Kids (HMK)",
  "Healthy Palm Beaches, Inc.",
  "Healthy San Francisco",
  "Healthy Texas",
  "Healthy Valley Provider Network",
  "Heartland Express - CORVEL",
  "Helmsman Management Services",
  "Hemet Community Medical Group (HCMG)",
  "Hennepin Health (Formerly Metropolitan Health Plan)",
  "Hennepin Health (formerly Metropolitan Health Plan)",
  "Hereford Insurance",
  "Heritage Consultants",
  "Heritage IPA",
  "Heritage Physicians Network (HPN)",
  "Heritage Provider Network (Regal)",
  "Heritage Victor Valley Medical Group (Regal)",
  "Hernando Pasco Hospice",
  "Hershey Healthsmile",
  "Hewitt Coleman",
  "High Desert Medical Group (Regal)",
  "Highline MSO - Molina",
  "Highmark Health Options",
  "Highmark Senior - Freedom Blue PPO (Pennsylvania)",
  "Highmark Senior Health Company (Pennsylvania) Institutional",
  "Highmark Senior Health Company (Pennsylvania) Professional",
  "Highmark Senior Health Company (Pennsylvania)",
  "Highmark Senior Solutions Company - West Virginia",
  "Hill Physicians Aetna PPO",
  "Hill Physicians Blue Cross PPO",
  "Hill Physicians Blue Shield PPO",
  "Hill Physicians Cigna PPO",
  "Hill Physicians Health Net PPO",
  "Hill Physicians Medical Group (HPMG)",
  "Hill Physicians United Healthcare PPO",
  "Hill UHCSR PPO",
  "Hilliard Corporation (UCS)",
  "Hillshire Brands - CORVEL",
  "Hilmar Cheese Company - CORVEL",
  "Hinsdale Medicare Advantage",
  "Hinsdale Physicians Healthcare",
  "Hispanic Physician IPA (Encounters Only)",
  "Hispanic Physicians IPA (Fee For Service Only)",
  "Hoag Affiliated Physicians",
  "Hoag Clinic (Hoag Physician Partners)",
  "Hockenberg Equipment",
  "Holista, LLC",
  "Hollywood Pres GLOBAL",
  "Hollywood Presbyterian Medical Center - Preferred (HPMC - PMG)",
  "Hollywood Presbyterian Medical Center - San Judas IPA (HPMC - SJM)",
  "Hollywood Presbyterian Medical Center - St. Vincent's IPA (HPMC - SVI)",
  "Hollywood Presbyterian Medical Group",
  "Hollywood Presbyterian San Judas",
  "Home State (Centene)",
  "Home State Health Plan (Centene)",
  "HomeLink",
  "Homeland Security (AKA ICE Health)",
  "Homelink HealthPartners",
  "Hometown Health Network",
  "Hometown Health Plan Nevada",
  "Homewood Resorts",
  "Hooray Health",
  "Horizon BCBS of New Jersey",
  "Horizon Casualty Services",
  "Horizon NJ Health",
  "Horizon Valley Medical Group San Diego",
  "Horizon Valley Medical Group",
  "Hortica Florist Mutual Insurance",
  "Hortica",
  "Hospital Housekeeping Systems (HHS) - CORVEL",
  "Hotel Employees & Restaurant Employees Health Trust, Group # F19",
  "Housing Authority El Paso (TX)",
  "Houston Hunters Patrol, Inc.",
  "Howard County Government (MD) - CORVEL",
  "Hudson Health Plan (now MVP)",
  "Human Behavior Institute",
  "Humana Behavioral Health (formerly LifeSynch)",
  "Humana Encounters",
  "Humana Gold Choice",
  "Humana Gold Plus",
  "Humana Health Plans of Ohio",
  "Humana Healthy Horizons Oklahoma",
  "Humana LTSS Claims",
  "Humana Long Term Care (LTC)",
  "Humana Ohio 2",
  "Humana Ohio Medicaid",
  "Humana Puerto Rico",
  "Humana",
  "Humboldt Del Norte Foundation IPA",
  "Huntington Park Mission Medical Group",
  "Hutchinson Technology - CORVEL",
  "Hylton Payroll (Benefit Plan Administrators)",
  "I. E. Shaffer",
  "IAC Life",
  "IBC Personal Choice",
  "IBEW Local 6  - San Francisco Electrical Workers",
  "IBEW Local 640 & AZ Chapter NECA Health & Welfare Trust",
  "IBEW: Local No. 1 Health and Welfare Fund (Mental Health)",
  "IBG Administrators, LLC",
  "ICare Health Options",
  "IDS Property Casualty Insurance Company",
  "IDS Property Casualty",
  "IEHP Dental (Admin by LIBERTY Dental Plan)",
  "IHC Health Solutions",
  "IHG Direct",
  "IL Youthcare (Centene)",
  "ILWU Local 21 (FCHN)",
  "IMA (Cotiva)",
  "IMA, Inc",
  "IMCare",
  "IMS - Trial Card (TC-IMS)",
  "IMS Management Services - Texas",
  "IMS TPA",
  "IMS TPA: Parton Lumber (UCS)",
  "IMS TPA: The Spencer Group",
  "IMS TPA: Walker White, Inc. (UCS)",
  "IMT",
  "IN Physician Associates",
  "INDECS Corporation",
  "INS Health Services (AKA ICE Health)",
  "IPMG Claims Management Services",
  "IPS SAGE 1",
  "IQVIA",
  "ISS Facility Services - CORVEL",
  "Ichahn Automotive Group (Pep Boys & AutoPlus) - CORVEL",
  "Illinicare Health Plan (Centene)",
  "Illinois Casualty",
  "Illinois Health Plans (IHP)",
  "Imagework Technologies Corp.",
  "Imagine360 Administrators (GPA)",
  "Impact Health Share Inc.",
  "Imperial County Physicians Medical Group",
  "Imperial Health Holdings Medical Group",
  "Imperial Health Plan of California Encounters",
  "Imperial Health Plan of California, Inc.",
  "Imperial Insurance Companies INC EXCHANGE AZ",
  "Imperial Insurance Companies INC EXCHANGE NV",
  "Imperial Insurance Companies INC EXCHANGE TX",
  "Imperial Insurance Companies INC EXCHANGE UT",
  "Imperial Insurance Companies",
  "Imperial Insurance Encounters",
  "In Physicians Associates – ACTY",
  "IncentiCare",
  "Independence Administrators",
  "Independence American, Ins. Co.",
  "Independence BC (Pennsylvania)",
  "Independence Blue Cross",
  "Independence Care Systems / FIDA-MMP",
  "Independence Medical Group - Kern County",
  "Independence Medical Group - Tulare County",
  "Independent Health",
  "Independent Living System",
  "Independent Living Systems - CA",
  "Indian Health Services / Veteran Affairs",
  "Indiana Farmers Mutual - CORVEL",
  "Indiana Insurance",
  "Indiana ProHealth Network",
  "Indiana University (IU) Health Plan (Commercial)",
  "Indiana University (IU) Health Plan (Medicare)",
  "Indiana University (IU) Health Plan – Commercial/Marketplace",
  "Indiana University (IU) Health Plan – Medicare Advantage",
  "Individual Health Insurance Companies",
  "Infinity IPA",
  "Ingham Health Plan Corp.",
  "Ingles Markets",
  "Inland Empire Electrical Trust (FCHN)",
  "Inland Empire Health Medicare",
  "Inland Empire Health Plan",
  "Inland Empire Health",
  "Inland Empire health Plan  (Covered California)",
  "Inland Empire health Plan (Covered California)",
  "Inland Faculty Medical Group (MV Medical)",
  "Inland Faculty Medical Group Encounters (MV Medical)",
  "Inland Health Organization (IHO)",
  "Innermark TPA",
  "InnovAge",
  "Innovante Benefit Administrator",
  "Innovation Health (Aetna)",
  "Innovative Care Systems (ICS) - San Ramon",
  "Innovative Claim Solutions (ICS) - Rancho Cordova - CORVEL",
  "Innovative Healthware Payer 6",
  "Innovative Integrated Health, Inc.",
  "Inservco",
  "Insurance Administrator of America, Inc. (IAA)",
  "Insurance Benefit System Administrators",
  "Insurance Company of the West (ICW)",
  "Insurance Design Administrators - ClaimsBridge",
  "Insurance Design Administrators",
  "Insurance Management Services",
  "Insurance Program Managers Group (IPMG)",
  "Insurance Systems",
  "Insurance TPA",
  "Insurers Administrative Corp.",
  "Integon General Insurance Corp.",
  "Integra Administrative Group",
  "Integra BMS",
  "Integra Group",
  "IntegraNet SCAN",
  "IntegraNet",
  "Integral Quality Care",
  "Integrated Eye Care Network",
  "Integrated Health Partners (IHP)",
  "Integrated Home Care Services, Inc.",
  "Integrated Medical Solutions",
  "Integrated Mental Health Services",
  "Integrity Mutual Insurance Company",
  "Inter Valley Health Plan (IVHP)",
  "Inter-Americas Insurance",
  "InterCommunity Health CCO (IHN)",
  "InterWest Health PPO",
  "Interactive Medical Systems",
  "Intercare - Sussex",
  "Intercare",
  "Intercommunity Health Net",
  "Intergovernmental Risk Management Agency (IRMA) - CORVEL",
  "Intergroup Services Corporation",
  "Interinsurance Exchange of the Automobile Club - CORVEL",
  "Intermountain Ironworkers Trust Fund",
  "International Benefit Administrator",
  "International Benefits Administrators",
  "International Brotherhood of Boilermakers",
  "International Educational Exchange Service (IEES)",
  "International Medical Group (IMG)",
  "International Medical Group Inc",
  "International Union of Operating Engineers ~ Local 15, 15A, 15C & 15D",
  "Intervalley",
  "Iowa Health Advantage",
  "Iowa Total Care (Centene)",
  "Iron Road Healthcare",
  "Island Home Insurance Company",
  "Itasca Medical Center",
  "J B Hunt Transport Inc.",
  "J. F. Molloy and Associates",
  "J5 National Part A",
  "JADE Health Care Medical Group",
  "JI Specialty Services",
  "JLS Family Enterprises (dba League Medical Concepts)",
  "JP Farley Corporation",
  "JP Specialities",
  "JPS Health Connection",
  "JWF Specialty",
  "Jai Medical Systems",
  "Jefferson Pilot Financial",
  "Jencare Medical",
  "Jericho Share",
  "John Hancock Health Security Plan",
  "John Muir Physician Network",
  "John Muir Trauma Physicians",
  "John P. Pearl & Associates, Ltd.",
  "Johns Hopkins Health Advantage",
  "Johns Hopkins Healthcare (USFHP)",
  "Johns Hopkins Healthcare",
  "Johns Hopkins Home Care Group",
  "KAMG Hospital",
  "KM Strategic Management (KMSM)",
  "KPIC Self-Funded Claims Administrator",
  "KPS Healthplans",
  "KSKJ Life Medicare Supplement",
  "KSKJ Life",
  "Kaiser CSI - California Select for Individuals",
  "Kaiser Foundation Health Plan of Colorado",
  "Kaiser Foundation Health Plan of Georgia",
  "Kaiser Foundation Health Plan of Mid-Atlantic",
  "Kaiser Foundation Health Plan of Northern CA Region",
  "Kaiser Foundation Health Plan of Southern CA Region",
  "Kaiser Foundation Health Plan of Washington",
  "Kaiser Foundation Health Plan of the Mid-Atlantic States",
  "Kaiser Foundation Health Plan of the Northwest",
  "Kaiser Permanente Health Plan of Hawaii",
  "Kaiser Permanente of Northern California",
  "Kaiser Permanente of Southern California",
  "Kaiser Self-Funded",
  "Kalos Health",
  "Kane County IPA",
  "Kansas Health Advantage (aka Kansas Superior Select)",
  "Katy Medical Group",
  "Kaweah Delta HC District Emp Plan",
  "Kaweah Delta Medicare Advantage",
  "Keenan & Associates",
  "Keenan and Associates (Excluding City of Pasadena)",
  "Keenan",
  "Kellogg Companies - CORVEL",
  "Kelsey Seybold",
  "Kemper Health",
  "Kempton Company",
  "Kempton Group Administrators",
  "Kempton Group TPA: Kempton Group Administrators (UCS)",
  "Kentucky Health Cooperative",
  "Kentucky Spirit Health (Centene)",
  "Kern County Healthy Kids",
  "Kern County Hospital Authority KERN",
  "Kern Health Care Network",
  "Kern Health Systems",
  "Kern Legacy Health Plan",
  "Key Benefit Administrators",
  "Key Health Medical Solutions, Inc.",
  "Key Medical Group - Medicare Advantage",
  "Key Medical Group",
  "Key Risk Management Services LLC",
  "Key Select",
  "KeyCare (MD)",
  "Keystone Connect (AmeriHealth)",
  "Keystone First Aligned",
  "Keystone First Community Health Choices",
  "Keystone First Dental - PA",
  "Keystone First VIP Choice",
  "Keystone First",
  "Keystone Health (Lakeside - Glendale, CA) (Regal)",
  "Keystone Health Plan Central",
  "Keystone Health Plan East (KHP)",
  "Keystone Mercy Health Plan",
  "Keystone West (Highmark)",
  "Kikkoman Foods Inc - CORVEL",
  "Kings County Healthy Kids",
  "Kitsap Physician Services",
  "Klais & Company (Repricing for HealthSpan Network Only)",
  "Klais & Company",
  "Koan Risk Solutions",
  "Koch Foods, Inc - CORVEL",
  "Korean American Medical Group",
  "Kova Healthcare, Inc.",
  "Kuspuk School District",
  "LA Care Health Plan",
  "LACH HealthNet by MedPOINT",
  "LADOC CorrectCare",
  "LBA Health Plans",
  "LCTA Workers Comp",
  "LFP Corporation - CORVEL",
  "LHP Claims Unit",
  "LHS/ MedCost Solutions LLC",
  "LUCENT HEALTH",
  "LYNX",
  "LaSalle Medical Associates",
  "Lafayette Consolidated Government",
  "Lake Region Community Health Plan",
  "Lakes Area Community Health Plan (LACH)",
  "Lakeside Community Healthcare (Regal)",
  "Lakeside Medical Group (Regal Lakeside)",
  "Lakewood IPA",
  "Lancaster General Health (LGH)",
  "Land of Lincoln Health",
  "Landmark",
  "Landrys - CORVEL",
  "Lapoyner ISD",
  "Larson Group",
  "Las Vegas Firefighters Health and Welfare Trust",
  "Lassen Municipal Utility District",
  "Lasso Healthcare Msa",
  "Laundry Workers",
  "Lawndale Christian Health Center",
  "Le Mars Insurance Company",
  "League Medical Concepts (aka JLS Family Enterprises)",
  "League of Minnesota Cities Insurance Trust",
  "Leon Health Plans",
  "Leon Medical Center Health Plan",
  "Liberty Advantage",
  "Liberty Bankers",
  "Liberty Dental Plan",
  "Liberty Health Advantage",
  "Liberty Mutual - Auto",
  "Liberty Mutual Insurance Co",
  "Liberty Mutual Middle Markets",
  "Liberty National Life Insurance Company",
  "Liberty Northwest",
  "Liberty Union",
  "Liberty Wausau",
  "Life Assurance Company",
  "Life Benefit Plan",
  "Life Investors Insurance of America - Long Term Care",
  "Life Pittsburgh",
  "Life St. Mary (Trinity Health Pace)",
  "Life Trac",
  "LifeCare Assurance Co",
  "LifeWell Health Plans",
  "LifeWise Assurance Company",
  "LifeWise Healthplan of Oregon (Premera)",
  "LifeWise Healthplan of Washington (Premera)",
  "LifeWorks Advantage (ISNP plan)",
  "Lifeshield National Insurance Co.",
  "Lifetime Benefit Solutions",
  "Lifewise Arizona",
  "Lighthouse Health Plan",
  "Lincoln Financial Group (Salt Lake City)",
  "Lincoln Financial Insurance Company",
  "Lincoln Heritage",
  "Lincoln National of Wisconsin",
  "Lincoln Heritage Medicare Supplement",
  "Linden Oaks Behavioral Health",
  "Line Construction Benefit Fund",
  "Link Snacks (Jacks Links)",
  "Little Company of Mary",
  "Live Well IPA",
  "Local 137 Operating Engineers Welfare Fund",
  "Local 670 Engineers VIP",
  "Local 670 Engineers",
  "Local 670 VIP",
  "Lockard & Williams",
  "Loma Linda University Adventist Health Sciences Center",
  "Loma Linda University Adventist Health Sciences Centers",
  "Loma Linda University Behavorial Medicine Center Employee Health Plan",
  "Loma Linda University Dept of Risk Management",
  "Loma Linda University Healthcare - ManagedCare Claims",
  "Loma Linda University Healthcare Employee Health Plan",
  "Loma Linda University",
  "Lone Star Medical Group PLLC",
  "Lone Star TPA",
  "Long Beach Memorial IPA (MHCLB)",
  "Longevity CO",
  "Longevity FL",
  "Longevity IL",
  "Longevity MI",
  "Longevity NC",
  "Longevity NJ",
  "Longevity OK",
  "Longs Drugs",
  "Loomis - SeeChange Health Insurance",
  "Loren Cook",
  "Los Angeles Medical Center (LAMC) - Encounters",
  "Los Angeles Medical Center (LAMC)",
  "Louisiana Healthcare Connections (Centene)",
  "Louisiana State University - CORVEL",
  "Louisiana Workers Compensation Corporation (LWCC)",
  "Lovelace Salud  (Medicaid)",
  "Lowe's (Bunch CareSolutions)",
  "Lowe's",
  "Lower Kuskokwim School District",
  "Loyola Physician Partners",
  "Lumbermens Underwriting Alliance",
  "Lumico Life Insurance Co Medicare Supplement",
  "Luminare Health (AZ, IL, IN, MD, MN, NC, PA)",
  "Luminare Health AZ, IL, IN, MD, MN, NC, PA",
  "Luminare Health Detroit",
  "Luminare Health Internal",
  "Luminare Health KC",
  "Luminare Health Little Rock",
  "Luminare Health Ohio",
  "Luther Care",
  "Lutheran Preferred",
  "MAHP (MAMSI)",
  "MAMSI Health Plan",
  "MAMSI",
  "MAPFRE Insurance",
  "MBA Benefit Administrators",
  "MBA of Wyoming",
  "MBACLM",
  "MBS (Formerly MedCost Benefit Services)",
  "MCA Administrators, Inc.",
  "MCA-Sedgwick WTCHP NPN",
  "MD Anderson Physician Network",
  "MD Partners IPA",
  "MD Senior Care Medical Group",
  "MDWise",
  "MDX Hawaii",
  "MDwise Health Indiana Plan (DOS on or After 1/1/2019)",
  "MDwise Hoosier Care Connect",
  "MDwise Hoosier Healthwise (DOS on or after 1/1/2019))",
  "MED3000 California",
  "MEMIC Casualty Company",
  "MEMIC Indemnity Company",
  "MHCC Luminare Health",
  "MIC General Insurance Corp.",
  "MIChild Michigan",
  "MMM Healthcare",
  "MMM Multi Health (Reform)",
  "MMM of Florida",
  "MPLAN, Inc. - HealthCare Group",
  "MPM Prospect",
  "MPMBA",
  "MRIPA - AllCare Health Plan",
  "MSA Care Guard",
  "MVP - Ohio",
  "MVP Health Care (Mohawk Valley)",
  "MVP Health Plan of New York - Child Health Plus",
  "MVP Health Plan of New York",
  "MVP Health Plan",
  "Mabuhay Medical Group",
  "MacNeal Health Providers - CHS",
  "Magellan Behavioral Health - Case Rate",
  "Magellan Behavioral Health Services (Medicaid NE)",
  "Magellan Behavioral Health Services (Medicaid VA)",
  "Magellan Behavioral Health Services",
  "Magellan Complete Care Arizona",
  "Magellan Complete Care VA",
  "Magellan HIA-CA",
  "MagnaCare - Oscar Network",
  "MagnaCare",
  "Magnacare",
  "Magnolia Health (Centene)",
  "Magnolia Health Plan (Centene)",
  "Maine Community Health Options",
  "Major League Baseball 8",
  "Managed Care Services",
  "Managed Care Systems",
  "Managed Care of America",
  "Managed Care of North America (Florida)",
  "Managed Care of North America (Kentucky)",
  "Managed DentalGuard",
  "Managed Health Network (MHN)",
  "Managed Health Services - WI State Employees",
  "Managed Health Services Indiana (Centene)",
  "Managed Health Services Wisconsin (Centene)",
  "Managed Healthcare Associates",
  "Manhattan Life",
  "Mansfield I.S.D.",
  "March Vision Care",
  "Maricopa Care Advantage",
  "Maricopa Health Plan (UAHP)",
  "Marpai",
  "Marrick Medical Finance, LLC",
  "Martins Point Health Care",
  "Martins Point US Family Health Plan",
  "Mary Washington Health Plan",
  "Maryland Healthy Smiles - DHMH",
  "Maryland Physicians Care (MPC)",
  "Maryland Physicians Care MCO",
  "Maryland Physicians Care",
  "Maryland Uninsured Employers Fund - CORVEL",
  "Maryland’s Medicaid Public Behavioral Health System",
  "Mashantucket Pequot Tribal Nation",
  "Masonry Industry Trust",
  "Masonry Welfare Trust Fund",
  "Mass Advantage",
  "Mass General Brigham Health Plan",
  "Massachusetts Bay Insurance Company - CORVEL",
  "Massachusetts Behavioral Health",
  "Masters Mates and Pilots Plan",
  "Mathis ISD",
  "Matrix Absence Management",
  "Maverick Medical Group",
  "Mayo Clinic Recovery & Claims Services",
  "McGregor PACE",
  "McLaren Advantage SNP",
  "McLaren Health Advantage",
  "McLaren Health Plan (MHP) Commercial",
  "McLaren Health Plan (MHP) Medicaid",
  "McLaren Medicare Supplement",
  "McMullen County ISD",
  "Meadowbrook Insurance Company",
  "Med-Pay Inc",
  "MedAdmin Solutions (Self funded plans)",
  "MedAdmin Solutions",
  "MedBen",
  "MedCare Partners",
  "MedCom",
  "MedCore HP",
  "MedCost, Inc.",
  "MedDirect",
  "MedLogix MSO - Allied Health Solutions",
  "MedMutual Protect Medicare Supplement",
  "MedPartners Administrative Services",
  "MedPay Assurance",
  "MedRisk",
  "MedSave USA Third Party Administration",
  "MedSolutions, Inc",
  "MedStar Family Care",
  "MedStar Family Choice DC",
  "MedStar Family Choice MD",
  "MedStar Family Choice of DC",
  "MedStar Family Choice of Maryland",
  "MedStar Health Plan",
  "MedXoom",
  "Medcare Partners - Physician Partners IPA",
  "Medequip Inc",
  "Medex Health Network Inc",
  "Medfocus",
  "Medi-Cal (California Medicaid)",
  "Medi-Cal Dental",
  "Medi-Cal UB Type 02 (Long-Term Care)",
  "Medi-Cal UB Type 03 (Inpatient)",
  "MediChoice IPA",
  "MediShare (Christian Care Ministries)",
  "Medica Government Programs",
  "Medica Health Plan Solutions",
  "Medica HealthCare Plan of Florida",
  "Medica Individual and Family (IFB)",
  "Medica Medicare Supplement",
  "Medica",
  "Medica2",
  "Medicaid Alabama",
  "Medicaid Alaska",
  "Medicaid Arizona",
  "Medicaid Arkansas",
  "Medicaid Colorado",
  "Medicaid Connecticut",
  "Medicaid Delaware",
  "Medicaid District of Columbia",
  "Medicaid Florida",
  "Medicaid Georgia - Grady Healthcare, Inc",
  "Medicaid Georgia",
  "Medicaid Hawaii",
  "Medicaid Idaho",
  "Medicaid Illinois",
  "Medicaid Indiana",
  "Medicaid Iowa",
  "Medicaid Kansas",
  "Medicaid Kentucky (Doral Dental / Region #3)",
  "Medicaid Kentucky",
  "Medicaid Louisiana (Ambulance)",
  "Medicaid Louisiana (Durable Medical Equipment)",
  "Medicaid Louisiana",
  "Medicaid Maine",
  "Medicaid Maryland - PhysiciansCare MCO",
  "Medicaid Maryland DHMH",
  "Medicaid Maryland",
  "Medicaid Massachusetts",
  "Medicaid Michigan",
  "Medicaid Minnesota",
  "Medicaid Mississippi",
  "Medicaid Missouri - Blue Advantage",
  "Medicaid Missouri - Community Care Plus - St. Louis",
  "Medicaid Missouri - Community Health Plan",
  "Medicaid Missouri - FirstGuard Health Plan",
  "Medicaid Missouri - HealthCare USA - St. Louis",
  "Medicaid Missouri - HealthNet",
  "Medicaid Missouri - TrumanCare - Family Health Partners",
  "Medicaid Missouri",
  "Medicaid Montana",
  "Medicaid Nebraska - United HealthCare of the Midlands",
  "Medicaid Nebraska",
  "Medicaid Nevada",
  "Medicaid New Hampshire",
  "Medicaid New Jersey",
  "Medicaid New Mexico - Cimmaron Health Plan",
  "Medicaid New Mexico - Lovelace Community Health Plan",
  "Medicaid New Mexico - Presbyterian Salud",
  "Medicaid New Mexico",
  "Medicaid New York (Administrated by Americhoice)",
  "Medicaid New York",
  "Medicaid North Carolina",
  "Medicaid North Dakota",
  "Medicaid Ohio - Emerald HMO and Medicare, Inc.",
  "Medicaid Ohio - Health Power HMO - Cincinnati",
  "Medicaid Ohio - Personal Physicians Care - Cleveland",
  "Medicaid Ohio - United HealthCare, Inc. - Medicare Complete",
  "Medicaid Ohio",
  "Medicaid Oklahoma - Community Care",
  "Medicaid Oklahoma - Heartland Health Plan",
  "Medicaid Oklahoma - Unicare Health Plan",
  "Medicaid Oklahoma",
  "Medicaid Oregon",
  "Medicaid Pennsylvania (Administered by Americhoice)",
  "Medicaid Pennsylvania - Best Health Care - Pittsburgh",
  "Medicaid Pennsylvania - Gateway Health Plan - Pittsburgh",
  "Medicaid Pennsylvania - Health Partners - Philadelphia",
  "Medicaid Pennsylvania - Oaktree",
  "Medicaid Pennsylvania",
  "Medicaid Rhode Island",
  "Medicaid South Carolina",
  "Medicaid South Dakota",
  "Medicaid Tennessee - BlueCare",
  "Medicaid Tennessee / TennCare",
  "Medicaid Tennessee",
  "Medicaid Texas - Delta Dental",
  "Medicaid Texas - MCNA",
  "Medicaid Texas and Texas Health Steps",
  "Medicaid Texas",
  "Medicaid Utah Crossovers",
  "Medicaid Utah",
  "Medicaid Vermont",
  "Medicaid Virginia - HealthKeepers Plus",
  "Medicaid Virginia",
  "Medicaid Washington DC",
  "Medicaid Washington",
  "Medicaid West Virginia",
  "Medicaid Wisconsin (Administered by Doral Dental of Wisconsin)",
  "Medicaid Wisconsin - Humana / TDC",
  "Medicaid Wisconsin - PrimeCare",
  "Medicaid Wisconsin",
  "Medicaid Wyoming",
  "Medical Associates Health Plan",
  "Medical Benefits Administrators, Inc.",
  "Medical Benefits Companies",
  "Medical Benefits Mutual Life Insurance Co.",
  "Medical Benefits Mutual",
  "Medical Card System (MCS)",
  "Medical Mutual of Ohio",
  "Medical Reimbursements of America",
  "Medical Resource Network (MRN)",
  "Medical Safety Net",
  "Medical Service Company (MCS)",
  "Medical Specialties Managers Inc",
  "Medical Value Plan-MVP-OH",
  "Medicare A & B",
  "Medicare Alabama",
  "Medicare Alaska",
  "Medicare Arizona",
  "Medicare Arkansas",
  "Medicare California (North)",
  "Medicare California (South)",
  "Medicare Colorado",
  "Medicare Connecticut",
  "Medicare Delaware",
  "Medicare District of Columbia",
  "Medicare Florida",
  "Medicare Georgia",
  "Medicare Hawaii",
  "Medicare Idaho",
  "Medicare Illinois",
  "Medicare Indiana",
  "Medicare Iowa",
  "Medicare J04911",
  "Medicare J12901 - WPS Legacy Part A",
  "Medicare Kansas City",
  "Medicare Kansas",
  "Medicare Kentucky",
  "Medicare Louisiana",
  "Medicare Maine",
  "Medicare Maryland",
  "Medicare Massachusetts",
  "Medicare Michigan",
  "Medicare Minnesota",
  "Medicare Mississippi",
  "Medicare Missouri (East & West)",
  "Medicare Montana",
  "Medicare Nebraska",
  "Medicare Nevada",
  "Medicare New Hampshire",
  "Medicare New Jersey",
  "Medicare New Mexico",
  "Medicare New York (Downstate)",
  "Medicare New York (Empire)",
  "Medicare New York (Queens)",
  "Medicare New York (Upstate)",
  "Medicare North Carolina",
  "Medicare North Dakota",
  "Medicare Northern Virginia",
  "Medicare Ohio",
  "Medicare Oklahoma",
  "Medicare Oregon",
  "Medicare Pennsylvania",
  "Medicare Railroad",
  "Medicare Rhode Island",
  "Medicare South Carolina",
  "Medicare South Dakota",
  "Medicare Tennessee",
  "Medicare Texas",
  "Medicare Utah",
  "Medicare Vermont",
  "Medicare Virgin Islands",
  "Medicare Virginia (Palmetto)",
  "Medicare Washington DC",
  "Medicare Washington",
  "Medicare West Virginia",
  "Medicare Wisconsin",
  "Medicare Wyoming",
  "Medicare and Much More Florida",
  "Medicare – HHH J11 (Palmetto)",
  "Medicare – HHH J15 (CGS)",
  "Medicina Familiar Medical Group",
  "Medico Insurance",
  "Medigold PPO",
  "Medigold",
  "Mediview Sendero CHIP & STAR",
  "Mediview UTMB Care UB",
  "Mediview UTMB3",
  "Mediview Vista 360 Health",
  "Mega Life & Health Insurance - OKC",
  "Mega Life and Health (Frisco, TX)",
  "Meijer Stores",
  "Memorial Healthcare IPA",
  "Memorial Hermann Health Network (MHHN)",
  "Memorial Integrated Healthcare (Commercial)",
  "Memorial Integrated Healthcare (Medicaid)",
  "Memorial Medical Center - Non Sutter",
  "MemorialCare Medical Foundation Cap Services",
  "MemorialCare Medical Foundation UCI",
  "MemorialCare Medical Foundation",
  "MemorialCare Select Health Plan Encounters",
  "MemorialCare Select Health Plan",
  "Mendota Insurance Company",
  "Menifee Valley Community Medical Group",
  "Mental Health Consultants Inc.",
  "Merced County Healthy Kids",
  "Merchants Insurance Group",
  "Mercury General Corporation - Auto",
  "Mercury",
  "Mercy Benefit Administration",
  "Mercy Care Health Plans – Wisconsin & Illinois",
  "Mercy Care Plan (AHCCCS)",
  "Mercy Care RBHA",
  "Mercy Health Plan of New Jersey",
  "Mercy Maricopa Integrated Care",
  "Mercy Physicians Medical Group (MPMG)",
  "Mercy Provider Network",
  "Meridian Health Plan Illinois Complete",
  "Meridian Health Plan Illinois Medicaid",
  "Meridian Health Plan Michigan Complete",
  "Meridian Health Plan of Illinois",
  "Meridian Health Plan of Iowa",
  "Meridian Health Plan of Michigan",
  "Meridian PACE Solutions",
  "Merit IPA",
  "Meritage Medical Network",
  "Meritain Health (formerly Weyco, Inc)",
  "Meritain Health",
  "MetLife",
  "Metcare Health Plans",
  "Metis Services",
  "Metro Plus Health Plan",
  "Metropolitan Council",
  "Metropolitan Health Plan (now Hennepin Health)",
  "Metropolitan IPA d.b.a Community Family Care IPA",
  "Metropolitan Property and Casualty Insurance Company",
  "Metropolitan Transit Authority",
  "Miami Children's Health Plan",
  "Miami-Dade County (Fl) - CORVEL",
  "Michael Reese (HMO)",
  "Michigan Complete Health (Centene)",
  "Michigan Fidelis Secure Care (Centene)",
  "Michigan Medicaid BCCCNP",
  "Michigan Millers Mutual - CORVEL",
  "Mid Cities IPA",
  "Mid Rogue IPA",
  "Mid Rogue Oregon Health Plan",
  "Mid-America Associates Inc.",
  "Mid-American TPA: AAI, Inc. (UCS)",
  "Mid-Atlantic Medical Services, Inc. (MAMSI)",
  "Mid-County Physicians Medical Group",
  "MidWest Physician Administrative Services (MPAS)",
  "Middlesex Insurance Company",
  "Midlands Choice, Inc.",
  "Midwest Drywall Company",
  "Midwest Family Advantage Insurance Co - CORVEL",
  "Midwest Family Mutual (Auto Only) - CORVEL",
  "Midwest Family Mutual Insurance Co",
  "Midwest Group Benefits",
  "Midwest Health Plans, Inc.",
  "Midwest Insurance",
  "Midwest Operating Engineers Welfare Fund",
  "Midwest Security",
  "Miller Grove ISD",
  "Mills Peninsula Health Services (SPS - Sutter)",
  "Mills Peninsula Medical Group (SPS - Sutter)",
  "Minneapolis Public School - CORVEL",
  "Minnesota Blue Cross Dental (UCCI)",
  "Minnesota Counties Ins Trust (MCIT)",
  "Minnesota Department of Health",
  "Minnesota Department of Labor",
  "Minnesota Health Care Program",
  "Minnesota Insurance Guarantee Association (MIGA)",
  "Minnesota WC Plan",
  "Mirra Healthcare",
  "Mission Community IPA",
  "Mission Heritage Medical Group",
  "Mission Hospital Affiliated Physicians",
  "Mississippi Children's Health Insurance Program",
  "Mississippi Department Of Corrections - CORVEL",
  "Mississippi Public Entity  Employee Benefit Trust (MPEEBT)",
  "Mississippi Select Health Care",
  "Missoula County Medical Benefits Plan",
  "Missouri Health Plan (Community & State)",
  "Missouri Medicare Select",
  "Mitsui Sumitomo Insurance Group",
  "Mitsui Sumitomo Insurance USA Inc.",
  "Mobilitas Insurance Company",
  "Moda Health (Formerly ODS Health Plan)",
  "Molina Complete Care of Arizona",
  "Molina Complete Care of Virginia - Encounters",
  "Molina Complete Care of Virginia",
  "Molina Health Care of New Mexico",
  "Molina Health Care of Ohio",
  "Molina Healthcare Florida",
  "Molina Healthcare Michigan",
  "Molina Healthcare South Carolina",
  "Molina Healthcare Utah",
  "Molina Healthcare Wisconsin",
  "Molina Healthcare of Arizona",
  "Molina Healthcare of CA Encounters",
  "Molina Healthcare of California",
  "Molina Healthcare of Florida - Encounters",
  "Molina Healthcare of Florida",
  "Molina Healthcare of Idaho",
  "Molina Healthcare of Illinois",
  "Molina Healthcare of Iowa",
  "Molina Healthcare of Michigan",
  "Molina Healthcare of Mississippi Marketplace",
  "Molina Healthcare of Mississippi",
  "Molina Healthcare of Missouri",
  "Molina Healthcare of Nebraska",
  "Molina Healthcare of Nevada",
  "Molina Healthcare of New Mexico - Salud",
  "Molina Healthcare of New Mexico",
  "Molina Healthcare of New York (formerly Total Care)",
  "Molina Healthcare of Ohio",
  "Molina Healthcare of Puerto Rico",
  "Molina Healthcare of South Carolina",
  "Molina Healthcare of Texas",
  "Molina Healthcare of Utah (aka American Family Care)",
  "Molina Healthcare of Utah",
  "Molina Healthcare of WA Encounters",
  "Molina Healthcare of Washington",
  "Molina Healthcare of Wisconsin",
  "Molina Medicaid Solutions - Idaho",
  "Molina Medicaid Solutions - Louisiana",
  "Molina Ohio Medicaid",
  "Momentum Insurance Plans, Inc.",
  "Monarch Healthcare IPA",
  "Monitor Life Medicare Supplement",
  "Montana State Fund - WC",
  "Montefiore Contract Management Organization (CMO)",
  "Montgomery County Self-Insurance Program (MD) - CORVEL",
  "Montgomery County Texas",
  "Montgomery",
  "Monument Administrators - Guardian Comp",
  "Monument Administrators - Quality Comp",
  "Monument Administrators - Victory Comp",
  "Monumental Life Insurance Company (MLIC)",
  "Morris Associates",
  "Motion Picture Industry Health Plan",
  "MotivHealth Insurance Company",
  "Mountain Health CO-OP",
  "Mountain States Administrators",
  "Mt. Carmel Health Plan",
  "MultiPlan GEHA",
  "Multicare Connected Care",
  "Multiplan Wisconsin Preferred Network",
  "Multnomah County Other",
  "Multnomah Treatment Fund (Formerly known as Verity Plus)",
  "Municipal Health Benefit Fund",
  "Mutual Assurance",
  "Mutual Health Services",
  "Mutual of Omaha Dental",
  "Mutual of Omaha Medicare Advantage",
  "Mutual of Omaha",
  "Mutually Preferred",
  "My Family Medical Group",
  "My Health Plan",
  "MyChoice IPA (ProCare MSO)",
  "MyNexus - Aetna Home Health",
  "MyNexus, Inc.",
  "N.W. Ironworkers Health & Security Trust Fund, Group # F15",
  "N.W. Roofers & Employers Health & Security Trust Fund, Group# F26",
  "N.W. Textile Processors Group # F14",
  "NBC Universal",
  "NCA Comp",
  "NCA Medical Group",
  "NCAS - Fairfax, Virginia",
  "NCAS Fairfax VA",
  "NCAS",
  "NCH Healthcare Inc.",
  "NCLM - North Carolina League of Municipalities",
  "ND Workforce Safety and Insurance (WSI)",
  "NGS American",
  "NHC Advantage",
  "NJ Carpenters Health Fund",
  "NJ Property Liability Ins Guaranty",
  "NP PHP Commercial",
  "NP PHP Medicare",
  "NP PHP OHP",
  "NP Yamhill County CCO",
  "NTCA - National Telecommunications Cooperative Association",
  "NX Health Network",
  "NXT IPA",
  "NYC Law Department",
  "Nap Tools (via EMC Risk Services)",
  "Naphcare Inc",
  "National Accident & Health General Agency Inc (NAHGA)",
  "National Allied Workers Union Local 831",
  "National American Insurance Co (NAICO)",
  "National Association of Letter Carriers (NALC)",
  "National Association of Letter Carriers",
  "National Capital Preferred Provider Organization (NCPPO)",
  "National Casualty Company",
  "National Claims Admin Services",
  "National Elevator Industry Benefit Plan",
  "National Financial Insurance Company",
  "National Foundation Life Insurance Company",
  "National General Insurance Online, Inc.",
  "National General",
  "National Guardian Life Insurance",
  "National Interstate",
  "National Liability and Fire Insurance Company (DOI before 7/1/14)",
  "National Liability and Fire Insurance Company (DOI on or after 7/1/2014)",
  "National Loss Prevention, Inc.",
  "National Pacific Dental - CALIFORNIA",
  "National Pacific Dental - TEXAS",
  "National Rural Electric Cooperative Assoc.",
  "National Rural Letter Carrier Assoc.",
  "National Telecommunications Cooperative Association (NTCA - Staff)",
  "National Telecommunications Cooperative Association (NTCA)",
  "Nationwide - Auto Only",
  "Nationwide Affinity Insurance Company of America",
  "Nationwide Agribusiness Insurance Co",
  "Nationwide Ins Company of America",
  "Nationwide Mutual Fire Company",
  "Nationwide Specialty Health fka Nationwide Health Plan",
  "Nebraska Plastics Group",
  "Nebraska Total Care (Centene)",
  "Neighborhood Health Partnership (NHP)",
  "Neighborhood Health Partnership",
  "Neighborhood Health Plan (NHPRI)",
  "Neighborhood Health Plan (RI)",
  "Neighborhood Health Plan RI - Exchange Unity Integrity",
  "Neighborhood Health Providers &  Suffolk Health Plan (NHP - SHP)",
  "Nephrology IPA",
  "NetWell",
  "Netcare Life & Health Insurance - Hagatna, Guam",
  "Network Administrators",
  "Network Health Insurance (NHIC) Medicare",
  "Network Health Plan of Wisconsin - Commercial",
  "Network Health Plan",
  "Network Health of WI - Commercial",
  "Network Health of WI - Medicare",
  "Network Medical Management",
  "Network Solutions IPA",
  "NeueHealth",
  "Nevada Dental Benefits",
  "Nevada Pacific Dental",
  "New Avenues",
  "New Century Health - Arizona Integrated Physicians",
  "New Century Health - Avmed - Oncology",
  "New Century Health - Avmed – Radiation Oncology",
  "New Century Health - CarePlus Cardiology",
  "New Century Health - CarePlus Urology Lab",
  "New Century Health - CarePlus Urology",
  "New Century Health - Devoted Health Cardiology",
  "New Century Health - Devoted Health Oncology",
  "New Century Health - Florida Blue Medicare - Radiation Oncology",
  "New Century Health - Florida Blue Medicare – Oncology",
  "New Century Health - HealthSun Cardiology",
  "New Century Health - HealthSun Oncology",
  "New Century Health - Humana Oncology",
  "New Century Health - Humana Radiation Oncology",
  "New Century Health - Simply Health Care Cardiology",
  "New Century Health - Simply Health Care Oncology",
  "New Century Health - Simply Health Care Radiation Oncology",
  "New Century Health - Vista Cardiology - Summit",
  "New Century Health Solutions - CarePlus Oncology",
  "New Century Health Solutions - Vista Oncology",
  "New Century Health Solutions – CarePlus Radiation Oncology",
  "New Century Health Solutions – Vista Radiation Oncology",
  "New Century Health – CAC Centers",
  "New Century Health – Cigna Medical Group AZ",
  "New Directions Behavioral Health (NDBH)",
  "New England Dental Administrators",
  "New England, The",
  "New Era Employee Welfare Benefit",
  "New Era Life - Employee Benefit Plans",
  "New Era Life Insurance Company",
  "New Era Life",
  "New Hampshire Healthy Families (Centene)",
  "New Jersey Manufacturing (NJM) - Auto",
  "New Jersey Manufacturing (NJM)",
  "New Life Medical Group, Inc.",
  "New Mexico District Council of Carpenters",
  "New Mexico Mutual",
  "New Mexico Painters & Allied Trades Local #823",
  "New Mexico West Texas Multi-Craft",
  "New Source Benefits, LLC",
  "New York Hotel Trade Council",
  "New York Life",
  "New York Medical Indemnity Fund",
  "New York Network Management",
  "New York Preferred Care (MVP)",
  "New York State Insurance Fund (NYSIF)",
  "NexTPA",
  "Next Level Administration",
  "Next Level Health Partners",
  "NextBlue",
  "Nexus Health Medical Group",
  "Nexus IPA (ProCare MSO)",
  "Nike, Inc - CORVEL",
  "Nippon Life Benefits",
  "Nippon Life Insurance Company of America",
  "Nivano Physicians IPA",
  "Noble AMA Select IPA",
  "Noble Community Medical Associates LA",
  "Noble Mid OC Orange County  (Health Smart)",
  "Nomi Health",
  "Nonprofit Insurance Trust",
  "NorGUARD Insurance Company - Guard DBA",
  "Nordstrom, Inc.",
  "Norfolk & Dedham Group",
  "Norfolk Public Schools (VA) - CORVEL",
  "North America Administrators (NAA) - Nashville, Tennessee",
  "North American Administrators",
  "North Broward Hospital District",
  "North Carolina Insurance Guaranty Association",
  "North East Independent School District (NEISD)",
  "North East Medical Services	(NEMS)",
  "North Iowa Community Health Plan (NICH)",
  "North River Insurance Company",
  "North Shore - LIJ (Healthfirst)",
  "North Shore - LIJ CareConnect Insurance Company",
  "North Star Mutual Insurance Co. - CORVEL",
  "North Texas Healthcare Network",
  "North West Administrators (FCHN)",
  "North West Orange County Medical Group",
  "NorthEast Delta Dental",
  "Northeast Carpenters Funds",
  "Northeast Georgia Health Services",
  "Northeast Iowa Area Agency",
  "Northern California Advantage Medical Group  (NCAMG)",
  "Northern California Physicians Grp",
  "Northern Illinois Health Plan",
  "Northern Kentucky University",
  "Northern Nevada Operating Engineers Health and Welfare",
  "Northern Nevada Trust Fund (Benefit Plan Admin)",
  "Northland Auto",
  "Northshore Physician Associates",
  "Northwell Direct",
  "Northwest Community Health Partners",
  "Northwest Diagnostic Clinic (NWDC)",
  "Northwestern Medicine Physicians Network",
  "Northwood Healthcare",
  "Nova Healthcare Administrators, Inc.",
  "Nova Saiph Re",
  "NovaNet",
  "NovaSys Health (Centene)",
  "Nuestra Familia Medical Group",
  "Nyhart",
  "O'Reilly Auto Parts",
  "OEA Choice Trust",
  "OLOLRMC Uninsured Patient Services Program",
  "OMNI IPA",
  "OODA Health",
  "OPEIU Locals 30& 537",
  "OSF Health Plans",
  "OSI Group, LLC - CORVEL",
  "OSMA Health (formerly PLICO)",
  "Oak River Insurance Company (Member of BHHC)",
  "Oak West Physician Association",
  "Oasis IPA (Regal)",
  "Occupational Eyewear Network, Inc.",
  "Oceans Acquisition, Inc. - CORVEL",
  "Ochsner Health Plan",
  "Ogden Benefits Administration (EBC)",
  "Ohana Health Plan",
  "Ohio Casualty Insurance",
  "Ohio Health Choice PPO",
  "Ohio PPO Connect",
  "Ohio Valley HMO Cincinnati",
  "OhioHealthy",
  "OhioRISE - Aetna Better Health of Ohio",
  "Ojai Valley Community Medical Group",
  "Oklahoma Complete Health (Centene)",
  "Oklahoma DRS DOC",
  "Oklahoma Employees Group Insurance",
  "Old Republic Insurance Company",
  "Old Surety Life Insurance Co",
  "Olympus Managed Health Care (OMHC)",
  "Omni Healthcare - Humboldt Del Norte, California",
  "Omnicare Medical Group (OMNI)",
  "OnLok Senior Health Services, Inc.",
  "OnTrak Health",
  "Oncology Network of Orange County",
  "Oncology Physicians Network CA PC",
  "One Beacon",
  "One Call Medical - Diagnostic",
  "One Call Medical",
  "One Call Physical Therapy (formerly Align Network)",
  "One Care Connect (OCC)",
  "One Care Connect LTC (OCC)",
  "One Health Plan of California",
  "One Health Plan of Colorado",
  "One Health Plan",
  "One HomeCare Solutions",
  "OneCare (OC)",
  "OneNet PPO (formerly Alliance PPO & MAPSI)",
  "Operating Engineers Health & Welfare Fund",
  "Operating Engineers Local #53",
  "Operating Engineers Local 501 of California",
  "Operating Engineers Locals 302 & 612 Health & Security Fund,  Group # F12",
  "Operating Engineers Locals 302 & 612 Health & Security Fund, Grp. #F12",
  "Ophthalmic Health Alliance",
  "OptiMed",
  "Opticare of Utah",
  "Optima Insurance Company",
  "Optima/Sentara",
  "Optimum Choice of the Carolina's (OCCI)",
  "Optimum Healthcare",
  "Option Services Group",
  "Options Health Plan",
  "Optum / Salt Lake County (Medicaid)",
  "Optum Care Network - Empire Physicians MG",
  "Optum Care Network - Primecare Medical Group",
  "Optum Care Network",
  "Optum Care Network–Corona",
  "Optum Care Network–Hemet Valley",
  "Optum Care Network–Inland Valley",
  "Optum Care Network–Moreno Valley",
  "Optum Care Network–Redlands",
  "Optum Care Network–Riverside",
  "Optum Care Network–Southwestern Valleys",
  "Optum Care Network–Sun City",
  "Optum Care Network–Valley Physicians",
  "Optum Maryland Behavioral Health",
  "Optum MedicalRx",
  "Optum San Diego Public Sector",
  "Optum Speciality",
  "Optum United Health Behavioral Solutions",
  "Optum – Complex Medical Conditions (CMC) (FKA OptumHealth)",
  "Optum – Complex Medical Conditions (CMC) (formerly OptumHealth Care Solutions and United Resource Networks)",
  "OptumCare / AZ, UT (formerly Optum Med Network & Lifeprint)",
  "OptumCare / AZ, UT (formerly Optum Medical Network & Lifeprint Network)",
  "OptumCare Network - Monarch",
  "OptumCare Network of Connecticut",
  "OptumHealth / OptumHealth Behavioral Solutions (formerly United Behavioral Health [UBH] and PacifiCare Behavioral Health )",
  "OptumHealth / OptumHealth Behavioral Solutions of NM",
  "OptumHealth / OptumHealth Physical Health (includes Oxford) (Formerly ACN)",
  "OptumHealth Behavioral Solutions (FKA PacifiCare BH & UBH)",
  "Opus MedStrategies",
  "Opyn Market",
  "Orange Coast Memorial IPA",
  "Orange County Advantage Medical Group",
  "Orange County Fire Authority - CORVEL",
  "Orchid Medical/Careworks Sedgwick",
  "Oregon State Accident Insurance Fund (SAIF) Corporation",
  "Orthonet - Aetna",
  "Orthonet - Uniformed Services Family Health Plan",
  "Oscar Health",
  "Oxford Health Plans",
  "Oxford Life Insurance Company",
  "P.R.I.M.E. Benefit Systems Inc.",
  "P.S.E.W. Trust",
  "P3 Health Partners Arizona",
  "P3 Health Partners Nevada",
  "PA Health and Wellness (Centene)",
  "PACE Center LTC",
  "PACE Center",
  "PACE Greater New Orleans",
  "PACE Southeast Michigan",
  "PALMS Casino Resort (Admin by LIBERTY Dental Plan)",
  "PATH Administrators (Formerly DH Evans)",
  "PBS - Oregon Alaska Industrial Hardware",
  "PBS - Oregon Alaska Rubber & Supply",
  "PBS - Oregon Cascade Rubber Products",
  "PBS - Oregon Construction Machinery Industrial",
  "PBS - Oregon Peninsula Airways",
  "PBS - Oregon TDX Corporation",
  "PCA Health Plan of Texas (Humana)",
  "PCMG of San Luis Obispo (formerly San Luis Obispo Select IPA)",
  "PCMG of Santa Maria (formerly Midcoast Care Inc.)",
  "PDT - Hollywood Presbyterian-St Vincent",
  "PEF Clinic",
  "PGBA TriWest VA CCN",
  "PHCS GEHA",
  "PHP of Michigan",
  "PHP of the Carolinas",
  "PIH Health (formerly Bright Health)",
  "PMG of San Jose",
  "POMCO",
  "PPO Plus LLC",
  "PSKW",
  "Pace Your Life",
  "PacifiCare Dental and Vision HMO Claims",
  "PacifiCare Dental and Vision PPO-IND",
  "Pacific Associates IPA",
  "Pacific Compensation Insurance Company",
  "Pacific Health MSO",
  "Pacific Healthcare IPA",
  "Pacific IPA",
  "Pacific Medical Centers (PACMED USFHP)",
  "Pacific Source Health Plan",
  "Pacific Southwest Administrators (PSWA)",
  "Pacific Sunwear - CORVEL",
  "Pacific Union Dental",
  "PacificSource Community Solutions",
  "PacificSource Health Plans",
  "PacificSource Medicare",
  "Pacifica of the Valley Hospital",
  "Painters Union Insurance Fund",
  "Paladin Managed Care Services, Inc.",
  "Palomar Pomerado Hospital",
  "Pan American Life Insurance Group Puerto Rico",
  "Pan American Life Insurance Group",
  "Paradigm",
  "Paragon Benefits, Inc",
  "Paramount Health Care",
  "Paramount IPA",
  "Parity Healthcare",
  "Park District Risk Management Association (PDRMA)",
  "Parker Services LLC",
  "Parkland Community Health Plan",
  "Partners Behavioral Health",
  "Partners Health Plan",
  "Partners National Health Plan of NC",
  "Partners in Health",
  "Partnership Health Plan of California Healthy Kids",
  "Partnership HealthPlan of California CalAim",
  "Partnership HealthPlan of California",
  "Pasadena Primary Care Physicians Group",
  "Pasco County BOCC",
  "Passport Advantage",
  "Passport Health Plan by Molina Healthcare",
  "Passport Health Plan",
  "Patient Advocates LLC",
  "Patient Physician Cooperatives",
  "Patriot General Insurance Company",
  "Paul Mueller",
  "Payer Compass – ACS Benefits",
  "Payer Fusion Holdings",
  "Peach State Health Plan (Centene)",
  "Peak Health",
  "Peak Pace Solutions",
  "Peak Property and Casualty Insurance Corporation",
  "Pebble Health",
  "Pediatric Associates",
  "Peerless Insurance",
  "Pegasus Medical Group (Regal)",
  "Pegasus Risk Management CA",
  "Pekin Insurance",
  "Peninsula Indemnity Company",
  "Penn Behavioral Health",
  "Penn Millers Insurance Co",
  "Penn National Insurance - Auto",
  "Penn National Insurance",
  "Pennsylvania Health and Wellness (Centene)",
  "Pennsylvania Manufacturing Associates (PMA)",
  "Pennsylvania Pace",
  "People 1st Health Strategies",
  "People 2.0",
  "PeopleOne Health",
  "Peoples Health Network (UHC)",
  "Peoples Health Network",
  "Perennial Advantage of Colorado",
  "Perennial Advantage of Ohio",
  "PerformCare",
  "Performance Food Group - CORVEL",
  "Performance Health",
  "Perlman Medical Group",
  "Personal Insurance Administrators, Inc. (PIA)",
  "Pharmacist Mutual Insurance Co.",
  "Philadelphia American Life Insurance Company",
  "Phoenix Health Plan (Medicare)",
  "PhysMetrics (formerly ChiroMetrics)",
  "PhysMetrics",
  "Physician Associates of Louisiana",
  "Physician Healthcare Integration IPA",
  "Physician Partners IPA - Alameda (ProCare MSO)",
  "Physician Partners IPA - Santa Clara (ProCare MSO)",
  "Physician Partners IPA - South (ProCare MSO)",
  "Physician Partners IPA",
  "Physician's Mutual (Dental)",
  "Physicians Care Network",
  "Physicians Care",
  "Physicians Choice Medical Group of San Luis Obispo",
  "Physicians Choice Medical Group of Santa Maria",
  "Physicians Data Trust (PDT)",
  "Physicians Health Association of Illinois",
  "Physicians Health Network (PHN)",
  "Physicians Health Plan (PHP) - California",
  "Physicians Health Plan (PHP) - Georgia/Atlanta",
  "Physicians Health Plan (PHP) - Global Care",
  "Physicians Health Plan (PHP) - Mid Michigan",
  "Physicians Health Plan (PHP) - Northern Indiana",
  "Physicians Health Plan (PHP) - South Michigan",
  "Physicians Health Plan (PHP) - Sparrow",
  "Physicians Health Plan of Michigan Medicare",
  "Physicians Health Plan",
  "Physicians Healthcare Plans",
  "Physicians Healthways IPA",
  "Physicians Management Group",
  "Physicians Medical Group of San Jose",
  "Physicians Mutual Insurance Company",
  "Physicians Plus Insurance Corporation",
  "Physicians Plus Insurance, Co., Wisconsin State Employees",
  "Physicians United Plan",
  "Physicians of Southwest Washington",
  "Physnet",
  "Pie Insurance Services Inc. - CORVEL",
  "Piedmont Community Health Plan",
  "Piedmont Wellstar Health Plan",
  "Pier 1 Inc. USA",
  "Pierce County WA - CORVEL",
  "Pinnacle Claims Management",
  "Pinnacle Claims Management, Inc.",
  "Pinnacle Health Resources",
  "Pinnacle Medical Group",
  "Pinnacol Assurance",
  "Pioneer Provider Network",
  "Pittman and Associates (Now known as HealthSmart Benefit Solutions)",
  "Plan Handlers",
  "Plan de Salud Menonita Vital",
  "Planned Admin Inc P and C Division",
  "Planned Administrators (Non Preferred Blue Providers)",
  "Planned Administrators, Inc. (PAI)",
  "Planstin",
  "Plexis Healthcare Systems (UCS)",
  "Plymouth Rock",
  "Podi Care Managed Care",
  "Podiatry First",
  "Podiatry Network Florida",
  "Podiatry Plan Inc",
  "Poe & Brown",
  "Point C/Mid-American Benefits",
  "Point Comfort Underwriters (PCU03)",
  "Point Comfort Underwriters",
  "Polish Falcons America",
  "Pomona Valley Medical Group (PVMG)",
  "Pool Administrators Primary",
  "Pool Administrators Secondary",
  "Poppins Health",
  "Populytics (formerly Spectrum Administrators)",
  "Port of Oakland (CA) - CORVEL",
  "Porter Scott",
  "Positive Health Care (PHC) / FL MCO PHC",
  "Prairie States Enterprises",
  "Preferred Admin Childrens Hosp",
  "Preferred Administrator",
  "Preferred Benefit Administrators",
  "Preferred Blue of South Carolina",
  "Preferred Care Partners - Florida",
  "Preferred Community Choice - PCCSelect - CompMed",
  "Preferred Community Choice",
  "Preferred Health Care (PHC)",
  "Preferred Health Plan of the Carolinas",
  "Preferred Health Professionals",
  "Preferred Healthcare System - PPO",
  "Preferred IPA",
  "Preferred Mutual",
  "Preferred Network Access, Inc.",
  "PreferredOne",
  "Premera Blue Cross Blue Shield of Alaska",
  "Premera Blue Cross of Washington",
  "Premier Access Insurance Company",
  "Premier Administrative Solutions",
  "Premier Care of Northern California",
  "Premier Eye Care",
  "Premier Group Insurance - CORVEL",
  "Premier Health Systems",
  "Premier Health",
  "Premier HealthCare Exchange (PHX)  - A-G Admin (SOMA group)",
  "Premier HealthCare Exchange (PHX)  - Auxiant",
  "Premier HealthCare Exchange (PHX)  - BPA – Benefit Plan Administrators",
  "Premier HealthCare Exchange (PHX)  - Commercial Travelers",
  "Premier HealthCare Exchange (PHX)  - First Agency (FIA)",
  "Premier HealthCare Exchange (PHX)  - Mayo Clinic FL/GA",
  "Premier HealthCare Exchange (PHX)  - Med-Pay",
  "Premier HealthCare Exchange (PHX)  - Mississippi Physician Care Network",
  "Premier HealthCare Exchange (PHX) - BMI Kansas",
  "Premier HealthCare Exchange (PHX) - Cypress Benefit Admin",
  "Premier HealthCare Exchange (PHX) - Elmco",
  "Premier HealthCare Exchange (PHX) - Fox Everett/HUB International",
  "Premier HealthCare Exchange (PHX) – Preferred Admin",
  "Premier HealthCare Exchange (PHX) – The Benefit Group",
  "Premier Healthcare Exchange, Inc. (PHX)",
  "Premier Patient Care IPA",
  "Premier Physician Alliance Inc",
  "Premier Physician Alliance",
  "Premier Physician Network (PPN)",
  "Premier Physician Network",
  "PremierCare Health (ProCare MSO)",
  "PremierOne Plus IDS",
  "Premiercare Health Network",
  "Presbyterian Health Plan",
  "Presbyterian Health Plan, Inc. (Magellan)",
  "Presbyterian Intercommunity Hospital",
  "Presbyterian Salud (NM)",
  "Presence ERC",
  "Preserver Insurance Company",
  "Prestige Health Choice",
  "Preventative Choice Plan",
  "Prezzo",
  "Primary Care & Pediatric IPA (PCP IPA)",
  "Primary Care Associates Medical Group (PCAMG)",
  "Primary Care Associates of California (PCAC)",
  "Primary Care Coalition",
  "Primary Care of Joliet",
  "Primary Choice (Aetna Health Plan of NY/NJ)",
  "Primary Health Network",
  "Primary Health of Josephine County",
  "Primary Physician Care",
  "Prime Community Care Central Valley  Encounters (MV Medical)",
  "Prime Community Care Central Valley (MV Medical)",
  "Prime Healthcare - CORVEL",
  "Prime Property & Casulty",
  "Prime West Health Systems",
  "PrimeCare Medical Group - Chino Valley",
  "PrimeCare Medical Network - Citrus Valley",
  "PrimeCare Medical Network",
  "PrimeCare, Wisconsin State and Federal Employees and Medicaid",
  "PrimeWest Health",
  "Primecare Medical Network - San Bernardino",
  "Primewell Health Services",
  "Prince Georges County Government (MD) - CORVEL",
  "Princeton Premier IPA",
  "Principal Financial Group Medicare Supplement (Vision Claims Only)",
  "Principal Financial Group",
  "Principal Mutual Life Insurance Co.",
  "Priority Health",
  "Prism - Univera",
  "Prism Health Networks",
  "ProCare (Prospect)",
  "ProCare (TX)",
  "ProCare - PremierCare IPA",
  "Procare Health Plan - Medicaid",
  "Prodegi",
  "Productive Programming, Inc. (PPI)",
  "Professional Benefit Administrators, Inc.",
  "Professional Claims Management - Canton, Ohio",
  "Professional Health Care Network",
  "Progressive Casualty Insurance Company",
  "Progressive Classic Insurance Company",
  "Progressive Commercial Casualty Company",
  "Progressive Direct Insurance Company",
  "Progressive Insurance",
  "Progressive Max Insurance Company",
  "Progressive Medical Associates, Inc",
  "Progressive Northern Insurance Company",
  "Progressive Northwestern Insurance Company",
  "Progressive Preferred Insurance Company",
  "Progressive Premier Insurance Company",
  "Progressive Specialty Insurance Company",
  "Progyny",
  "Prominence Administrative Services",
  "Prominence Health Plan of Nevada",
  "Prominence Health Plan of Texas",
  "Prominence Health Plan",
  "Prominence Healthfirst Florida",
  "Prominence Preferred Health Insurance Company",
  "Prospect Health Services of Texas",
  "Prospect Medical Group",
  "Prospect Provider Group of Rhode Island",
  "Prospect Sherman Oaks Medical Group",
  "Prosperity Life",
  "Protective Insurance",
  "Protective Life Insurance",
  "Protector 360",
  "ProviDRs Care Network",
  "Providence Facility Claims",
  "Providence Health Assurance Medicare Supplement",
  "Providence Health Assurance",
  "Providence Health Plan",
  "Providence PACE CA OR & WA Elderplace",
  "Providence Pace CA",
  "Providence Risk & Insurance",
  "Provident American Life & Health Insurance (Medicare)",
  "Provident Healthsource (CIGNA)",
  "Provident Life & Accident",
  "Provident Life & Casualty",
  "Provident Preferred Network",
  "Provider Direct Network (HealthSmart Preferred Care)",
  "Provider Partners Health Plan of Maryland",
  "Provider Partners Health Plan of Missouri",
  "Provider Partners Health Plan of North Carolina",
  "Provider Partners Health Plan of Ohio",
  "Provider Partners Health Plan of Pennsylvania",
  "Provider Partners Health Plan of Texas",
  "Provider Partners Missouri Community Plan (HMO I-SNP)",
  "Provider Partners North Carolina Community Plan",
  "Prudent Medical Group",
  "Pruitt Health Premier NC & SC",
  "Pruitt Health Premier",
  "PsycHealth Care Management LLC",
  "Psychiatric Centers at San Diego (PCSD)",
  "Public Alliance Insurance Coverage Fund (PAIC Jif)",
  "Public Employee Benefit Authority",
  "Public Employees Health Plan (PEHP)",
  "Public Employees Health Program",
  "Public Health Medical Services (PHMS)",
  "Public WC Program",
  "Publix Super Markets, Inc.",
  "Puget Sound Benefits Trust, Group# F25",
  "Puget Sound Electrical Workers Trust, Group# F33",
  "Puget Sound Workers Compensation Trust",
  "Pure Insurance",
  "Puritan Life Insurance",
  "Puritan Life Insurance Medicare Supplement",
  "QCA Health Plan",
  "QVI Risk Solutions Inc",
  "Quad Cities Community Health Plan (QCCH)",
  "QualCare (Managed Care Systems)",
  "QualCare Alliance Networks, Inc. (QANI)",
  "QualCare IPA",
  "QualChoice Advantage",
  "QualChoice Health Insurance",
  "QualChoice of Arkansas",
  "Qualexa Healthcare",
  "Quality Care Partners",
  "Quartz",
  "Quest Behavioral Health",
  "Quest EAP",
  "Questcare Medical Services",
  "Quick Trip Corporation",
  "QuikTrip Corporation",
  "RAM Mutual Insurance Company",
  "RAPHA IPA INC",
  "RISRx",
  "RMSCO, Inc.",
  "RTG Medical Group",
  "Rady Childrens Hospital San Diego",
  "Rady Children’s Health Network (RCHN)",
  "Rady’s Children’s Specialists of San Diego",
  "Raleys Family of Fine Stores - CORVEL",
  "Ralph Lauren - CORVEL",
  "Rampart Insurance Company",
  "Ramsey County (MN)",
  "Rancho Santiago Community College District (CA) - CORVEL",
  "Ravenswood Physician Associates",
  "Recology - CORVEL",
  "Recreational Equipment Inc. - CORVEL",
  "Red Oak Independent School District",
  "Redirect Health Administration",
  "Redlands Community Hospital",
  "Redlands-Yucaipa Medical Group",
  "Redwood Community Health Coalition",
  "Redwood Fire and Casualty Insurance Company (Member of BHHC)",
  "Reedy Creek Improvement District",
  "Regal Medical Group",
  "Regence Blue Cross Blue Shield - Oregon",
  "Regence Blue Shield - Idaho",
  "Regence Blue Shield of Washington State",
  "Regence Group Administrators (RGA)",
  "Regency Employee Benefits",
  "Regent Medical Group, Inc. (Regent Family Practice of Glendale)",
  "Region 8 Esc",
  "Regional Care, Inc.",
  "Rehn and Associates",
  "Reliance Community Care Partners",
  "Reliance HMO, INC.",
  "Reliance Standard Life",
  "Reliastar",
  "Religious Order of Jehovah's Witnesses",
  "Renaissance Gulf Quest",
  "Renaissance Life & Health Insurance Company of America",
  "Renaissance Physicians Organization",
  "Renassaince - River Oaks",
  "Renassaince Riveroaks Blue",
  "Republic Franklin Insurance",
  "Republic Indemnity Company of America",
  "Republic Western Ins. Co",
  "Reserve National Insurance",
  "ResourceOne Administrators Tribal",
  "ResourceOne Administrators",
  "Resurrection Health Care Preferred",
  "Resurrection Physicians Group",
  "Retail Business Services (RBS)",
  "Retailer’s Casualty",
  "Retailer’s First",
  "Retro Insulation INC",
  "Return to Work",
  "Revclaims",
  "Richardson ISD (Tristar Risk Management)",
  "Right Care from Scott & White",
  "Right Choice Benefit Administrators",
  "Rios Arizona IPA",
  "Rios Southwest Medical Group",
  "Risk Administration Services",
  "Risk Enterprise Management (REM)",
  "River City Medical Group Senior",
  "RiverSpring Health Plans",
  "Riverport Insurance (MN School Board Association)",
  "Riverside Medical Clinic",
  "Robert F Kennedy Medical Plan",
  "Rocky Mountain HMO",
  "Rocky Mountain Health Plan - Grand Junction",
  "Rocky Mountain Health Plan",
  "Rocky Mountain Pace",
  "Root Insurance",
  "Roseburg Forest Products - CORVEL",
  "Royal Health Care",
  "Royal Healthcare on behalf of Extended MLTC",
  "Royal Neighbors of America",
  "Royal Neighbors of America Medicare Supplement",
  "Royale Resources",
  "Rubicon Health Group",
  "Rush Prudential Health Plans - HMO Only",
  "Rutgers Casualty Insurance for NJ Auto",
  "Ryan White Network (RWN)",
  "Ryder Services",
  "S & S Healthcare Strategies",
  "S. USA Life Medicare Supplement",
  "SA Recycling, LLC - CORVEL",
  "SAMBA",
  "SANTA MONICA UNITE HERE HEALTH BENEFIT PLAN",
  "SBLI Life Medicare Supplement",
  "SBM (Staff Benefits Management & Admin)",
  "SCCIPA Anthem ACO",
  "SCCIPA Anthem PPO",
  "SCCIPA HMO",
  "SCCIPA VHP Covered California",
  "SCHS ALTA Global Care Medical Group",
  "SCHS Alta Pod by MedPOINT - Health Net",
  "SCIF (State Compensation Insurance Fund)",
  "SECURA Insurance A Mutual Company",
  "SGIC",
  "SHC Advantage",
  "SIEBA, LTD",
  "SIHRTE",
  "SIMPRA Advantage",
  "SISCO (RCM&D Self Insured Services Company, Cost Containment Services)",
  "SISCO Benefits",
  "SOMOS Anthem New York",
  "SOMOS Emblem",
  "SPJST - Medicare Supplement",
  "SRC - Strategic Resource Company",
  "SRS - Cargill, Inc.",
  "SVS Vision Inc.",
  "SafeCo Insurance",
  "SafeGuard Health Enterprises, Inc",
  "SafeGuard",
  "Safeco Insurance (Auto Only)",
  "Sagamore Health Network",
  "Sage Program",
  "Sage Technologies",
  "Saint Agnes Medical Group",
  "Saint Johns Health Clinic",
  "Salt Lake City Corporation - CORVEL",
  "Samaritan Advantage (SA01)",
  "Samaritan Choice (SCP)",
  "Samaritan Health Plans",
  "Samaritan Healthy Kids (KID)",
  "Samaritan Ministries International",
  "Samera Health",
  "San Bernardino Medical Group (SBMED)",
  "San Diego Community College District (CA) - CORVEL",
  "San Diego County Medical Services (CMS)",
  "San Diego County Physician Emergency Services (PES)",
  "San Diego County Primary Care Services",
  "San Diego County Ryan White Care Act",
  "San Diego Neighborhood House Association",
  "San Diego PACE",
  "San Diego Physicians Medical Group",
  "San Francisco Electrical Workers - IBEW Local 6",
  "San Francisco Health Plan",
  "San Francisco Healthy Kids",
  "San Francisco Municipal Transportation Agency (SFMTA)",
  "San Joaquin Health Administrators",
  "San Mateo Healthy Kids",
  "Sana Benefits",
  "SandHills Center",
  "Sanford Health Plan",
  "Sansum Clinic",
  "Santa Barbara Select IPA",
  "Santa Clara County IPA ACO",
  "Santa Clara County IPA HMO",
  "Santa Clara County IPA PPO",
  "Santa Clara Family Health Plan (SCFHP)",
  "Santa Clara Family Health Plan",
  "Sante Community Medical Centers",
  "Sante Community Physicians",
  "Sante Medi-Cal",
  "Saudi Health Mission",
  "Savage Services - CORVEL",
  "Save Mart - CORVEL",
  "Scan Health Plan - California",
  "Scan Health Plan",
  "Schaller Anderson Maryland Physicians Care",
  "Schaller Anderson Mercy Care Plan",
  "Schaller Anderson Texas Christus",
  "Scion Dental",
  "Scott & White Health Care Plan",
  "Screen Actors Guild (SAG AFTRA)",
  "Scripps Clinic",
  "Scripps Coastal Medical Center",
  "Scripps Health Plan MSO",
  "Scripps Health Plan Services",
  "Scripps Medical Plan (Aetna)",
  "Scripps Physicians Medical Group",
  "Seabright (SBIC)",
  "Seattle Area Plumbing",
  "Seaview IPA",
  "Secure Health Plans of Georgia, LLC",
  "Secure Health",
  "SecureCare Dental",
  "SecureOne",
  "Security Administrative Services, LLC (Cornerstone)",
  "Security Health Plan",
  "Sedgwick Claims Management Services (CMS)",
  "Select Administrative Services (SAS)",
  "Select Benefits Administrators of America",
  "Select Benefits Administrators",
  "Select Choice (Aetna Health Plan of NY/NJ)",
  "Select Energy Services - CORVEL",
  "Select Health of South Carolina",
  "Select Health of Utah",
  "Select Healthcare System, Inc.",
  "Select Health of South Carolina",
  "Select Senior Clinic",
  "SelectCare Texas Beaumont",
  "SelectCare of Texas (GTPA - Golden Triangle)",
  "SelectCare of Texas (HPN - Heritage Physicians)",
  "SelectCare of Texas (Houston)",
  "SelectCare of Texas (Kelsey-Seybold)",
  "SelectCare of Texas (NWDC)",
  "Selective Insurance",
  "Selective",
  "Self Insured Fund For FL Florists And Nurseries",
  "Self Insured Fund – MA",
  "Self Insured Plans",
  "Self Insured Services Company (SISCO)",
  "Self-Funded Plans, Inc.",
  "Self-Insured Schools of California (SISC)",
  "Selman Tricare Supplement Plans",
  "Selman and Company (Tricare/Champus)",
  "Selman and Company",
  "Sendero IdealCare SHP",
  "Sendero IdealCare",
  "Senior Choice (Aetna Health Plan of NY/NJ)",
  "Senior First IPA (dba ChoiceOne IPA )",
  "Senior Network Health",
  "Senior Whole Health Massachusetts",
  "Senior Whole Health New York (MLTC Only)",
  "Senior Whole Health New York",
  "Sentara Family Plan / Sentara Health Management",
  "Sentara Mental Health Professional",
  "Sentinel Management Services",
  "Sentinel Security Life Insurance Company",
  "Sentry Auto",
  "Sentry Casualty Company",
  "Sentry Insurance A Mutual Company",
  "Sentry Insurance Company Employees",
  "Sentry Insurance a Mutual Co.",
  "Sentry Insurance",
  "Sentry Life Insurance Company",
  "Sentry Select Insurance Company",
  "Seoul Medical Group",
  "Sequoia Health IPA",
  "Serendib Healthways",
  "Service Lloyds Insurance Co",
  "Seton Charity Plan",
  "Seton Health Plan (Employee Plans ONLY)",
  "Seton Health Plan - CHIP",
  "Seton Health Plan Care Program",
  "Seton Map Program (Mediview)",
  "Seton Star",
  "Seven Corners",
  "Share Healthcare",
  "Shared Health Mississippi",
  "Sharp Community Medical Group",
  "Sharp Health Plan",
  "Sharp Rees-Stealy Medical Group",
  "Shasta Administrative Services",
  "Sheakley Unicomp",
  "Sheboygan Falls Insurance Company",
  "Sheet Metal Workers Local 91",
  "Shelter Point Life",
  "Sheltered Workshop Insurance Of Missouri - CORVEL",
  "Shenandoah Life Medicare Supplement",
  "Shenandoah",
  "Show Me Health Administrators LLC",
  "Sidecar Health",
  "Sierra Health Services",
  "Sierra Health and Life (Claims)",
  "Sierra Health and Life - Encounters",
  "Sierra Medical Group (Regal)",
  "Sierra Sacramento Valley Regional",
  "Signature Advantage",
  "Silver Cross Managed Care Organization",
  "Silver Star PACE",
  "SilverSummit Healthplan (Centene)",
  "Simply Health Care Plan",
  "Sinclair Health Plan",
  "SkyGen: DCMED",
  "Smith Administrators",
  "Smoky Mountain Center (now Vaya Health)",
  "Snedeker Risk Management",
  "Society Insurance",
  "Solarte Health",
  "Solidarity Healthshare",
  "Solis Health Plans",
  "Solstice Benefits",
  "Sound Health & Wellness Trust (Aetna)",
  "Sound Health (Sisters of Providence)",
  "Sound Health (now known as First Choice Health Network)",
  "Sound Health Wellness Trust",
  "Soundpath Health (formerly known as Puget Sound Health Partners)",
  "South Atlantic Medical Group",
  "South Carolina Healthy Connections",
  "South Central Preferred",
  "South Coast Area Transit (CA) - CORVEL",
  "South Country Health Alliance",
  "South Dakota Behavioral Health - STARS / State Funding",
  "South Dakota DSS - STARS / State Funding",
  "South Florida Community Care Network - SFCCN (Commercial)",
  "South Florida Community Care Network - SFCCN (Medicaid)",
  "South Florida Musculo Skeletal Care (SFMSC)",
  "South Plains School WC Program",
  "South Tahoe Refuse",
  "SouthCare/Healthcare Preferred",
  "Southeastern Indiana Health (SIHO)",
  "Southern California Children's Healthcare Network",
  "Southern California Dairy",
  "Southern California Healthcare System (SCHS) Alta Pod by MedPOINT - Health Net",
  "Southern California Lumber",
  "Southern California Oncology Associates (SCOA) - Encounters",
  "Southern California Oncology Associates (SCOA)",
  "Southern California Physicians Managed Care Services (SCPMCS)",
  "Southern California UFCW Unions & Food Employers",
  "Southern Illinois Healthcare Association",
  "Southern Mutual Insurance Company",
  "Southern Textile Recycling, Inc. (CA) - CORVEL",
  "Southland Advantage Medical Group, Inc.",
  "Southland IPA",
  "Southland San Gabriel Valley Medical Group, Inc.",
  "Southwest Health Plan (Aetna HMO)",
  "Southwest Key - CORVEL",
  "Southwest MI Behavioral Health - Mental Health",
  "Southwest MI Behavioral Health Substance Use Disorder",
  "Southwest Oregon IPA (DOCS)",
  "Southwest Service Administrators",
  "Southwest Service Life",
  "Southwestern Health Resources Clinically Integrated Network",
  "Space Exploration Technologies Corp - CORVEL",
  "Special Agents Mutual Benefit Association",
  "Specialty Care Program",
  "Spectrum Health",
  "Spencer Stuart (ARM, LTD)",
  "Spina Bifida - VA HAC",
  "Spohn Health",
  "Sprouts Farmers Market, Inc. - CORVEL",
  "Spruce Grove Inc - CORVEL",
  "St James PHO",
  "St Mary's IPA",
  "St Vincent Medical Center (STVMC)",
  "St. Barnabas System Health Plan",
  "St. Francis IPA",
  "St. John's Claim Administration",
  "St. Johns Health Clinic",
  "St. Joseph Heritage Healthcare",
  "St. Joseph Heritage Medical Group",
  "St. Joseph Hospital Affiliated",
  "St. Jude Affiliated Physicians",
  "St. Jude Heritage Medical Group",
  "St. Mary High Desert",
  "St. Mary Medical Center",
  "St. Peter Medical Group, Inc.",
  "St. Therese Physician Associates",
  "St. Thomas Med Ntwk Gulfquest",
  "St. Vincent IPA",
  "Standard Insurance Co. of New York",
  "Standard Insurance",
  "Standard Life & Accident Insurance Company",
  "Standard Life",
  "Standard Life & Accident Insurance Company",
  "Stanley Steemer International Inc - CORVEL",
  "Stanley Works, Inc.",
  "Starlife Holdings",
  "Starmark",
  "State Compensation Insurance Fund (SCIF) of California",
  "State Farm Insurance Company",
  "State Farm Property & Casualty",
  "State Farm",
  "State Fund Mutual Insurance",
  "State Health Plan of North Carolina",
  "State Health Plan of SC",
  "State Mutual",
  "State Of Tennessee - CORVEL",
  "State Office of Risk Management",
  "State of Idaho Women's Health Check",
  "State of Idaho",
  "State of Minnesota - DOER",
  "State of North Carolina - CORVEL",
  "State of Texas Dental Plan (GEHA)",
  "Statewide Insurance Fund",
  "State Mutual - LS Medicare Supplement",
  "State Mutual - SMMS Medicare Supplement",
  "Staywell Guam",
  "Staywell Health Plan Kids",
  "Staywell Health Plan",
  "Sterling Medicare Advantage",
  "Sterling Option One",
  "Stewart C Miller and Company",
  "Stirling Benefits",
  "Stone Trust",
  "Stonebridge Life Insurance Company",
  "Stowe Associates",
  "Streamline Imaging",
  "Student Resources (UnitedHealthcare)",
  "Style Crest Enterprise",
  "Sulphur Bluff ISD",
  "Summacare Health Plan (HMO)",
  "Summit Administration Services, Inc.",
  "Summit America Insurance Services, Inc.",
  "Summit Community Care",
  "Summit Holdings",
  "Sun Life Financial (United Concordia)",
  "Sun Mar Healthcare - CORVEL",
  "Sunflower State (Centene)",
  "Sunshine State Health Plan (Centene)",
  "Superior Choice Medical Group",
  "Superior Dental Care",
  "Superior Health Plan (Centene)",
  "Superior Insurance Services",
  "Superior Point",
  "SureGo Administrative Services LLC",
  "Surest Health Plan",
  "Surgery One IPA",
  "Sussex Insurance Company",
  "Sutter Connect - East Bay Region Hospitals - B&T",
  "Sutter Connect - Palo Alto Medical Foundation",
  "Sutter Connect - Solano Regional Medical Foundation (SRMF)",
  "Sutter Connect - Sutter Delta Medical Group",
  "Sutter Connect - Sutter Gould Medical Foundation (SGMF)",
  "Sutter Connect - Sutter Independent Physicians, Sutter Medical Group, Sutter West Medical Group  (SIP/SMG/SWMG)",
  "Sutter Connect - Sutter Medical Group of the Redwoods, Santa Rosa Network (SMGR/SRN)",
  "Sutter Connect – East Bay Region Hospitals – Non Sutter Groups",
  "Sutter East Bay Medical Foundation",
  "Sutter Health Plus",
  "Sutter Health",
  "Sutter Select",
  "Sutter Senior Care",
  "Sutter and Aetna Insurance Company",
  "Swedish Covenant Hospital",
  "Swift Glass Corporation (UCS)",
  "Syngenta Corporation",
  "T.H.E. Insurance - CORVEL",
  "TCC (Self-Funded)",
  "TCC Benefits Administrator - Pre-Med Defender",
  "TCU-LA MTA",
  "TDC (The Dental Companies)",
  "THIPA",
  "THREE",
  "TKFMC",
  "TLC Advantage of Sioux Falls",
  "TLC Benefit Solutions",
  "TMG Health Insurance",
  "TML IRP - Texas Municipal League - Intergovernmental",
  "TR Paul, Inc.",
  "TRIAD HEALTHCARE INC",
  "TRLHN/AS",
  "TRUSTMARK",
  "TSRDP Dental Claims",
  "Tailored Brands Inc. - CORVEL",
  "TakeCare Insurance Company",
  "Tall Tree Administrators",
  "Tawa Services, Inc. - CORVEL",
  "Taylor Benefit Resource",
  "Teal Premier",
  "Team Choice PNS",
  "Teamcare",
  "Teamster Construction Industry Welfare Trust",
  "Teamsters Miscellaneous Security Trust Fund - Northwest Administrators",
  "Teamsters Miscellaneous Security Trust Fund-Northwest Admin",
  "Teamsters Welfare Trust",
  "Tech-Steel, Inc",
  "Technology Insurance Co",
  "Telamon",
  "Temecula Valley Medical Group",
  "TennCare Select/ BlueCare",
  "Tervist",
  "Texan Plus",
  "TexanPlus of Texas (Houston)",
  "TexanPlus of Texas (Kelsey-Seybold)",
  "Texas Association of School Boards",
  "Texas Children's Health Plan",
  "Texas Childrens Health Plan - Medicaid",
  "Texas Childrens Health Plan",
  "Texas First Health Plans",
  "Texas Health Aetna",
  "Texas Healthspring",
  "Texas Hospital Insurance",
  "Texas Independence Health Plan",
  "Texas Medicaid & Healthcare Partnership (TMHP)",
  "Texas Municipal League - Intergovernmental Risk Pool",
  "Texas Mutual Insurance",
  "Texas Roadhouse, Inc.",
  "The Alliance",
  "The Benefit Group, Inc.",
  "The Boon Group",
  "The CHP Group",
  "The Care Network/The Savannah Business Group",
  "The Cheesecake Factory - CORVEL",
  "The Chesterfield Companies",
  "The City of Odessa",
  "The Colorado Purchasing Alliance",
  "The Gap, Inc. - CORVEL",
  "The Hartford",
  "The Health Exchange (Cerner Corporation)",
  "The Health Plan of Upper Ohio Valley",
  "The Health Plan of West Virginia (Age 21+)",
  "The Health Plan of West Virginia (Age <20)",
  "The Health Plan of West Virginia (Medicaid)",
  "The Health Plan of Western Illinois (HOWI)",
  "The Health Plan",
  "The Jones Financial Companies, LLP - CORVEL",
  "The Loomis Company",
  "The Macaluso Group",
  "The Main Street America Group / Austin Mutual - CORVEL",
  "The Main Street America Group / Grain Dealers Mutual - CORVEL",
  "The Main Street America Group / Great Lakes Casualty - CORVEL",
  "The Main Street America Group / MSA Insurance Protection - CORVEL",
  "The Main Street America Group / MSAIC Surplus/Non-Admitted - CORVEL",
  "The Main Street America Group / Main Street America Assurance - CORVEL",
  "The Main Street America Group / NGM - CORVEL",
  "The Main Street America Group / Old Dominion Insurance Company - CORVEL",
  "The Mohegan Tribe of Indians of Connecticut",
  "The Mutual Group",
  "The Physicians Alliance Corp.",
  "The Plumbers Union Local No. 12 Welfare Fund",
  "The Preferred Healthcare System - PPO",
  "The Ullico Family of Companies",
  "The Vitamin Shoppe - CORVEL",
  "The Zenith - YMCA San Diego",
  "The Zenith",
  "The Zero Card LLC",
  "Therapy Direct Network",
  "Thomas H. Cooper",
  "Thomas McGee",
  "Three Rivers Health Plans (Unison Health Plan)",
  "Three Rivers Preferred",
  "Thrivent Financial Aid Association for Lutheran Medicare",
  "Thrivent Financial Lutheran Brotherhood Medicare",
  "Thrivent Financial",
  "Thrivent",
  "Tiffany & Co - CORVEL",
  "Tift Regional",
  "Todays Health",
  "Todays Options",
  "Together with CCHP",
  "Toll Brothers - CORVEL",
  "Tongass Timber Trust",
  "Tooling and Manufacturing Assocation",
  "Torrance Hospital IPA (THIPA)",
  "Torrance Memorial Medical Center",
  "Total Community Care",
  "Total Plan Concepts",
  "Total Plan Services (via SmartData)",
  "Total Senior Care",
  "Touchpoint Solutions / Touchpoint CRM)",
  "Touchstone Health PSO",
  "Tower Energy Group",
  "Tower Insurance Company Of New York",
  "Tower National Insurance Company",
  "Town of Colma (CA)",
  "Tractor Supply Company",
  "TransChoice - Key Benefit Administrators",
  "TransactRX Infusion and Specialty",
  "TransactRX",
  "Transamerica Financial Life Insurance Company",
  "Transamerica Life Insurance Company (TLIC)",
  "Transamerica Life Insurance",
  "Transamerica Premier Life Insurance Company",
  "Transamerica",
  "Transplant Associates Baylor Billing",
  "Transwestern General Agency",
  "Transwestern Insurance Administrators, Inc",
  "Travelers Auto",
  "Travelers Insurance",
  "Travelers",
  "Travis County MAP - Mediview",
  "Trellis Health Partners",
  "Tri-City Healthcare District",
  "Tri-County Schools Insurance Group JPA",
  "Tri-Valley Medical Group",
  "TriCare for Life",
  "TriCities IPA",
  "TriHealth Physician Solutions",
  "TriStar (formerly Select Benefit Administrators)",
  "TriState Benefit Solutions",
  "TriValley Medical Group Corporation",
  "TriWest Healthcare Alliance",
  "Triad Group LLC",
  "Triangle Insurance",
  "Tribado",
  "Tribute Health Plan of Arkansas",
  "Tribute Health Plan of Oklahoma",
  "Tricare Active Reservists",
  "Tricare Champus",
  "Tricare East",
  "Tricare Overseas",
  "Tricare West",
  "Tricare for Life",
  "Trigon Blue Cross Blue Shield of Colorado",
  "Trigon Blue Cross Blue Shield of Virginia",
  "Trigon Insurance Company",
  "Trillium Community Health Plan (Centene)",
  "Trillium Community Health Plans",
  "Trillium Health Resources",
  "Trillium Staffing Solutions",
  "Trilogy Network",
  "Trinity Universal Insurance Company",
  "Triple-S Advantage",
  "Triple-S Salud",
  "Tristar Risk Management",
  "Troy Medicare",
  "TruAssure Dental",
  "True Health New Mexico",
  "Truli for Health",
  "Trusted Health Plan",
  "Trustee Plans (FCHN)",
  "Trusteed Insurance (FCHN)",
  "Trusteed Plans Service Corporation",
  "Trustmark Insurance Company",
  "Trustmark Small Business Benefits",
  "Tufts Health Plan",
  "Tufts Health Public Plan (Network Health)",
  "Twin City Fire Insurance Co.",
  "Tyson Foods Inc.",
  "UC Davis Health System",
  "UC Health Plan Administrators",
  "UC Irvine Health (MemorialCare)",
  "UC Irvine",
  "UCLA Medical Group",
  "UCS (BASI: Air Design Heating & Cooling)",
  "UCS (BASI: American Biotech Labs)",
  "UCS (BASI: Belue Trucking Company)",
  "UCS (BASI: Big Horn Federal)",
  "UCS (BASI: Ceiling Systems)",
  "UCS (BASI: DC Welding & Construction)",
  "UCS (BASI: Dunham Rubber & Belting Corporation)",
  "UCS (BASI: Empire Lumber Company)",
  "UCS (BASI: Golden Eagle Construction)",
  "UCS (BASI: Great Basin Industrial)",
  "UCS (BASI: Hanson Trucking)",
  "UCS (BASI: Idaho Asphalt Supply)",
  "UCS (BASI: Inland Construction & Development Co.)",
  "UCS (BASI: Innovative Health Care Concepts)",
  "UCS (BASI: Knight Construction & Supply)",
  "UCS (BASI: Nitro Green Helena)",
  "UCS (BASI: Northwest River Supplies)",
  "UCS (BASI: Raphael Health Center)",
  "UCS (BASI: Reading Horizons)",
  "UCS (BASI: Rehab Strategies)",
  "UCS (BASI: Senior Suites at Woodside Village)",
  "UCS (BASI: Somont Oil Company)",
  "UCS (BASI: South State Trailer Supply)",
  "UCS (BASI: Tempest Technologies)",
  "UCS (BASI: Tenowo Inc.)",
  "UCS (BASI: TrailWest Bank)",
  "UCS (BASI: Vans Inc)",
  "UCS (BASI: Whitefish Credit Union)",
  "UCS (ELM-CO LLC)",
  "UCS (Kempton Group TPA: Shawnee Milling Company)",
  "UCS (The City of East Chicago)",
  "UCS - Al Fe Heat",
  "UCS - BASI: Lynco",
  "UCS - BASI: Williams Plumbing & Heating",
  "UCS - Coeur: Connectivity Source",
  "UCS - Coeur: Genesis Park Management",
  "UCS - Coeur: Hermann Sons Life",
  "UCS - Coeur: Leonard Holding Company",
  "UCS - Coeur: Master Mobile Link",
  "UCS - Coeur: Northwest Petroleum",
  "UCS - Coeur: Sercel",
  "UCS - Coeur: The Woodlands Christian Academy",
  "UCS - Insight Benefit Administrators",
  "UCS - International Medical Group: FBOP Otisville",
  "UCS BASI Hotstart",
  "UCS BASI: Carey Johnson Oil",
  "UCS BASI: Consolidated Turbine",
  "UCS BASI: Ellsworth Constructions",
  "UCS BASI: Limric Plumbing, Heating & Air",
  "UCS BASI: Meter Group USA",
  "UCS BASI: Smith Rogers Oil",
  "UCS BASI: T.W. Clark Construction",
  "UCS Benefit Plan Administrators: East Jefferson Hospital",
  "UCS Benovation",
  "UCS Cement Masons",
  "UCS Coeur Humphrey Associates",
  "UCS Core: Nemco",
  "UCS Core: TJR Equipment",
  "UCS Couer: My Management Consulting inc (MY Wireless)",
  "UCS ES Beveridge & Associates Inc: Grace Schools",
  "UCS ES Beveridge & Associates Inc: Worthington Christian School",
  "UCS PBS Oregon",
  "UCS Seminole Tribe of Florida",
  "UCS: Benefit Plan Administrators of Eau Claire, Inc.",
  "UCS: Coeur Plan Services- Pinnacle Medical Systems, Inc",
  "UCS: Diversified Benefit Administrators, Inc.",
  "UCS: E.S. Beveridge & Associate Inc",
  "UCSD Physician Network (UCSDPN)",
  "UCare Individual & Family Plans (DOS on or After 1/1/2019)",
  "UCare Minnesota",
  "UDR, Inc. - CORVEL",
  "UFCW (Local Food & Commercial Workers) Local 400 & Employers Health and Welfare Fund",
  "UFCW Northern California &Drug Employers H & W Trust Fund",
  "UFT Welfare Fund",
  "UHA Health Insurance",
  "UHC Community Plan / Chronic Complete (formerly Evercare)",
  "UHC Community Plan / Dual Complete (formerly Evercare)",
  "UHC Community Plan / Long Term Care (formerly Evercare)",
  "UHC Community Plan / Nursing Home Plan (formerly Evercare)",
  "UHC Medicare Advantage",
  "UHP Management",
  "UHSM-Unite Health Share Ministries",
  "UICI Administrators",
  "UMC Health Plan",
  "UMR (formerly UMR Onalaska/Midwest Security/Harrington)",
  "UMR (formerly UMR Wausau)",
  "UMR - Cincinnati",
  "UMR - Harrington/Columbus",
  "UMR - Lexington",
  "UMR - Onalaska",
  "UMR - San Antonio",
  "UMR - Wausau/UHIS",
  "UMR USNAS",
  "UMR Wausau",
  "UMass Medical School Health & Criminal Justice Program",
  "UNICARE Special Accounts",
  "UNIGARD (FCHN)",
  "UPMC Health Plan (Tristate)",
  "UPMC Health Plan",
  "UPMC Vision Advantage",
  "US Benefits",
  "US Department of Labor - Black Lung",
  "US Department of Labor - Energy",
  "US Department of Labor",
  "US Engagement, LLC",
  "US Family Health Plan of Texas and Louisiana (USFHP)",
  "US Imaging Network II",
  "US Imaging Network",
  "US Network and Administrative Services",
  "US Sugar Corporation",
  "USAA Auto Only",
  "USAA Life Insurance Company",
  "USAA-Medicare Supplemental",
  "USFHP - St. Vincent Catholic Medical Centers of New York",
  "UTMB 3 Share Program",
  "Ultimate Health Plans",
  "Ultra Benefits Inc.",
  "Umpqua Health Alliance",
  "Umwa Health & Retirement Funds",
  "Underwriters Safety and Claims",
  "Underwriters Services Corporation",
  "Unicare of WV",
  "Unicare",
  "Unified Group Services",
  "Unified Health Services (Workman's Comp Only)",
  "Unified Life Insurance Company",
  "Unified Physicians Network",
  "Uniform Medical Plan",
  "Uniformed Services Family Health Plan",
  "Unify HealthCare Administrators",
  "Union Construction Workers' Comp Program",
  "Union Insurance Company of Providence",
  "Union Labor Life Insurance Company - Admin By UBC",
  "Union Pacific Railroad Employees Health Systems (UPREHS)",
  "Unite HERE Health",
  "Unite HERE Health-LV",
  "Unite Health Share Ministries",
  "United Agriculture Benefit Trust",
  "United American Insurance Company",
  "United Benefits (formerly Brown & Brown Benefits)",
  "United Care Medical Group (UCMG) (Regal)",
  "United Claim Solutions",
  "United Concordia (Encounters)",
  "United Concordia - Dental - PA Blue Shield (Provider will need to register with ucci and inform them that EDI Health group is their clearinghouse 800-633-5430 opt 0)",
  "United Concordia - Dental Plus - PA Blue Shield",
  "United Concordia - Dental Plus",
  "United Concordia - Tricare Dental Plan",
  "United Concordia Companies, Inc.",
  "United Dominion Realty Trust",
  "United Employee Benefit Trust (FCHN)",
  "United Financial Casualty Company",
  "United Fire Group (aka Mercer Insurance)",
  "United Food & Commercial Workers Unions and Food Employers Benefit Fund",
  "United Health Services, Inc. dba Pruitt Health - CORVEL",
  "United HealthOne / Golden Rule",
  "United Healthcare Global (Inside U.S.)",
  "United Healthcare Nevada Market",
  "United Healthcare Securehorizons-Medicare Supplemental",
  "United Healthcare Shared Services",
  "United Healthcare",
  "United Heartland",
  "United Medical Alliance (UMA)",
  "United Medical Resources (UMR)",
  "United Medical Resources",
  "United Physicians International",
  "United States Fire Insurance Company",
  "United of Omaha",
  "UnitedHealthOne / Golden Rule",
  "UnitedHealthOne / PacifiCare Life and Health Insurance Company",
  "UnitedHealthOne / UnitedHealthcare Life Insurance Company (formerly American Medical Security)",
  "UnitedHealthOne / UnitedHealthcare Life Insurance Company",
  "UnitedHealthcare (UHC)",
  "UnitedHealthcare / All Savers Alternate Funding",
  "UnitedHealthcare / All Savers Insurance",
  "UnitedHealthcare / Definity Health Plan",
  "UnitedHealthcare / Empire Plan",
  "UnitedHealthcare / MAHP - MD IPA, Optimum Choice and MLH (formerly MAMSI)",
  "UnitedHealthcare / Oxford",
  "UnitedHealthcare / Spectera Eyecare Networks",
  "UnitedHealthcare / UHIS - UnitedHealth Integrated Services",
  "UnitedHealthcare / UnitedHealthcare Plan of the River Valley (formerly John Deere Healthcare)",
  "UnitedHealthcare / UnitedHealthcare Plan of the River Valley",
  "UnitedHealthcare / UnitedHealthcare West (PacifiCare)",
  "UnitedHealthcare / UnitedHealthcare West (formerly PacifiCare)",
  "UnitedHealthcare Community Care - Indiana",
  "UnitedHealthcare Community Plan (AmeriChoice PA PCP)",
  "UnitedHealthcare Community Plan - Arizona",
  "UnitedHealthcare Community Plan - Kansas (KanCare)",
  "UnitedHealthcare Community Plan - New Jersey",
  "UnitedHealthcare Community Plan - Tennessee",
  "UnitedHealthcare Community Plan / AZ (formerly AZ Phys IPA)",
  "UnitedHealthcare Community Plan / AZ (formerly Arizona Physicians IPA and APIPA)",
  "UnitedHealthcare Community Plan / CA, CO, DC, DE, FL, GA, HI, IN, KY, LA, MA, MD, NC, NM, OH, OK, PA, RI, VA, WA (formerly AmeriChoice or Unison)",
  "UnitedHealthcare Community Plan / IA, hawk-i (formerly AmeriChoice of Iowa)",
  "UnitedHealthcare Community Plan / IA, hawk-i",
  "UnitedHealthcare Community Plan / KS (KanCare)",
  "UnitedHealthcare Community Plan / MI (formerly Great Lakes Health Plan)",
  "UnitedHealthcare Community Plan / MS (formerly AmeriChoice MS - CHIP)",
  "UnitedHealthcare Community Plan / MS",
  "UnitedHealthcare Community Plan / NE (formerly Americhoice NE, ShareAdvantage, and UnitedHealthcare of the Midlands)",
  "UnitedHealthcare Community Plan / NE",
  "UnitedHealthcare Community Plan / NJ (formerly AmeriChoice NJ Medicaid, NJ Family Care, NJ Personal Care Plus)",
  "UnitedHealthcare Community Plan / NY (formerly AmeriChoice by UnitedHealthcare, AmeriChoice NY Medicaid & Child Health Plus, AmeriChoice NY Personal Care Plus)",
  "UnitedHealthcare Community Plan / NY",
  "UnitedHealthcare Community Plan / SC (formerly Unison)",
  "UnitedHealthcare Community Plan / TN (formerly AmeriChoice TN: TennCare, Secure Plus Complete)",
  "UnitedHealthcare Community Plan / TN",
  "UnitedHealthcare Community Plan / UnitedHealthcare Dual Complete (formerly Evercare)",
  "UnitedHealthcare Community Plan / UnitedHealthcare Dual Complete - Oxford Medicare Network",
  "UnitedHealthcare Community Plan / UnitedHealthcare Long Term Care (formerly Evercare)",
  "UnitedHealthcare Community Plan / UnitedHealthcare Plan of the River Valley (formerly John Deere Healthcare)",
  "UnitedHealthcare Community Plan of Missouri",
  "UnitedHealthcare Facets Detroit",
  "UnitedHealthcare Medicare Solutions / Chronic Complete",
  "UnitedHealthcare Medicare Solutions / MedicareComplete",
  "UnitedHealthcare Medicare Solutions / UnitedHealthcare Chronic Complete  (formerly Evercare)",
  "UnitedHealthcare Medicare Solutions / UnitedHealthcare Group Medicare Advantage",
  "UnitedHealthcare Medicare Solutions / UnitedHealthcare MedicareComplete (formerly SecureHorizons)",
  "UnitedHealthcare Medicare Solutions / UnitedHealthcare MedicareDirect  (formerly SecureHorizons)",
  "UnitedHealthcare Medicare Solutions / UnitedHealthcare Nursing Home Plan  (formerly Evercare)",
  "UnitedHealthcare NDC Claims",
  "UnitedHealthcare Ohio Medicaid Dental",
  "UnitedHealthcare Ohio Medicaid",
  "UnitedHealthcare Shared Services (UHSS)",
  "UnitedHealthcare",
  "Unity Health Insurance",
  "Unity Health Partners",
  "Univera Healthcare (Excellus)",
  "Univera PPO (Excellus)",
  "Univera",
  "Universal American",
  "Universal Benefits - Admin For Chubb Life Insurance Co",
  "Universal Benefits - Admin For City Of Bridgeport CT",
  "Universal Benefits - Admin For Hartford Life Insurance Co.",
  "Universal Care (FFS)",
  "Universal Care Encounters",
  "Universal Fidelity Administrators Company - Aggregate",
  "Universal HealthCare IPA",
  "University Care Advantage Arizona",
  "University Care Advantage",
  "University Family Care",
  "University Health Alliance",
  "University Health Care Advantage",
  "University Healthcare Group",
  "University Healthcare Marketplace",
  "University Physicians Care Advantage (Arizona)",
  "University Physicians Healthcare Group (Arizona)",
  "University Physicians Healthcare Group",
  "University Texas Medical Board",
  "University Trust",
  "University of Illinois at Chicago Div of Specialized Care for Children",
  "University of Illinois",
  "University of Kentucky Health Services - CORVEL",
  "University of Miami Behavioral Health",
  "University of Utah Health Plans",
  "Upland Medical Group",
  "Upper Peninsula Health Plan",
  "Utah Carpenters",
  "Utah Laborers",
  "Utah Pipe Trades",
  "Utah Uninsured Employers Fund - CORVEL",
  "Utica (Auto only)",
  "Utica Mutual Insurance Co",
  "Utica National Insurance Co of Texas",
  "VA Community Care Network Region 1",
  "VA Community Care Network Region 2",
  "VA Community Care Network Region 3",
  "VA Fee Basis Programs",
  "VA Financial Services Center",
  "VA Health Administration Center",
  "VGM Homelink",
  "VNA Homecare Options",
  "VNS Choice (NY)",
  "VNS Choice SelectCare",
  "Vacorp",
  "Valenz Health",
  "Valir Pace",
  "Valitas Health Services, Inc. dba Corizon Health - CORVEL",
  "Valley Care IPA",
  "Valley Care Program",
  "Valley Health Plan",
  "Valley Presbyterian Hospital Community Family Care VPRESCFC",
  "Valley Presbyterian Hospital Preferred IPA VPRESPREF",
  "Valley Presbyterian Hospital Serra Medical Group VPRESSERRA",
  "Vally Health Plan Encounters",
  "Valor Health Plan",
  "Van Lang IPA",
  "Vanliners Insurance Company",
  "Vantage Health Plan, Inc.",
  "Vantage Medical Group Encounters",
  "Vantage Medical Group",
  "Vantage Plus",
  "Variable Protection Administrators (VPA)",
  "Varipro",
  "Vault AMPS Admin Services",
  "Vaya Health (formerly Smoky Mountain)",
  "Velocity Vehicle Group - CORVEL",
  "Ventura County Health Care Plan (VCHCP)",
  "Verda Healthcare Texas Encounters",
  "Verda Healthcare Texas",
  "Verdegard Administrators",
  "Verdegard Employee Plan",
  "Verdugo Hills Medical Group (Regal)",
  "Verity National Group",
  "VestaCare",
  "Vestica Healthcare",
  "ViCare Health",
  "Vibra Health",
  "Vida Care",
  "VieCare LIFE Butler",
  "VieCare Life Armstrong",
  "VieCare Life Beaver and Life Lawrence Counties",
  "Viking Insurance Company of Wisconsin",
  "Village Health Plan",
  "Village Practice Management Company",
  "VillageCareMAX",
  "VillageMD of Northern Indiana",
  "Virginia Health Network, Inc.",
  "Virginia Mason Group Health",
  "Virginia Premier CompleteCare",
  "Virginia Premier Elite Plus",
  "Virginia Premier Elite",
  "Virginia Premier Gold",
  "Virginia Premier Health Plan (Medicaid)",
  "Virginia Premier Health Plan",
  "Virginia Premier Individual Health Plan",
  "Virginia Premier Medallion 4.0",
  "Virginia Risk Sharing (VRSA)",
  "Vital Protection",
  "Vitality Health Plan of CA FFS",
  "Vitori Health",
  "Vitruvian Care Inc",
  "Viva Health Plan",
  "Vivant Health",
  "Vivida Health",
  "Volunteer States Health Plan",
  "Volunteers of America National Services",
  "Volusia Health Network",
  "Vons / Safeway",
  "Vulcan Materials - CORVEL",
  "Vxtra Health Plan Inc",
  "Vytra Health Plans (Emblem)",
  "W.C. Beeler & Company",
  "WA Teamster",
  "WC Solutions",
  "WE Energies",
  "WEA TRUST",
  "WI State Employees - Managed Health Services",
  "WI State Employees - Physicians Plus Insurance Company",
  "WILLIAMSON COUNTY GOVERNMENT - CORVEL",
  "WINhealth Partners",
  "WJ Cox Associates, Inc.",
  "WPAS (FCHN)",
  "WPPN- HPO (HealthEOS)",
  "WPS Health Insurance",
  "WPS Health Plan",
  "WSHIP - Washington State Health Insurance Pool",
  "Wadena",
  "Wagner Meinert",
  "Wal-Mart Corporate",
  "Washington County General Fund",
  "Washington Dental Service",
  "Washington Gas",
  "Washington National",
  "Washington State Health Insurance Pool  (WSHIP)",
  "Washington Teamster Welfare Trust",
  "Waterstone Benefit Administrators",
  "Watts Health Care",
  "Web TPA / Community Health Electronic claims / CHEC",
  "Web TPA",
  "WebTPA Employer Services LLC.",
  "Wegmans",
  "Weiss Health Providers",
  "WelbeHealth",
  "Welcome Health",
  "Welfare and Pension Administration (WPAS)",
  "Well Sense Health Plan",
  "WellCare HMO",
  "WellCare Health Plan",
  "WellCare Health Plans",
  "WellCare Medicare (formerly Windsor)",
  "WellCare Private FFS",
  "WellCare of Georgia",
  "WellMed Medical (Claims)",
  "WellMed Medical (Encounters)",
  "WellNet Health Plans",
  "WellNet Healthcare Administrators, Inc",
  "WellSpace Nexus LLC",
  "WellSystems, LLC",
  "Wellcare (Admin by LIBERTY Dental Plan)",
  "Wellcare Health Plans - Encounters",
  "Wellcare by Allwell (Centene)",
  "Wellcare by Fidelis Care",
  "Wellcare by Health Net (Centene)",
  "Wellcare by Ohana",
  "Wellcare by Trillium Advantage (Centene)",
  "Wellcare of North Carolina",
  "Wellmark BCBS",
  "Wellpoint - Amerigroup Corporation",
  "Wellpoint - Maryland",
  "Wellpoint Dental Services",
  "Wenatchee Valley Medical Center",
  "West Bend Mutual Insurance",
  "West Covina Medical Group (Regal)",
  "West Regional Physicians Network Inc",
  "West Suburban Health Providers",
  "West Virginia Senior Choice",
  "WestGUARD Insurance Company - Guard DBA",
  "Western Agricultural Insurance Company",
  "Western Growers Assurance Trust",
  "Western Growers Insurance Company",
  "Western Health Advantage (PHTech)",
  "Western Health Advantage by Ayin",
  "Western Health Advantage",
  "Western Mutual Insurance (WMI)",
  "Western National Assurance",
  "Western National Insurance Pioneer Specialties",
  "Western Oregon Advanced Health",
  "Western Pennsylvania Electrical Employees Insurance Trust Fund",
  "Western Sky Community Care (Centene)",
  "Western Southern Financial Group",
  "Western Teamster Welfare Trust",
  "Western Utilities or Local 57",
  "Westfield Insurance",
  "Westlake Financial",
  "White Memorial Medical Center",
  "Wilco Care Indigent/Williamson",
  "William C. Earhart Company",
  "William J Sutton & Co. Ltd (Toronto)",
  "Wilson Mutual Insurance (All States EXCEPT MN/WI)",
  "Wilson-McShane",
  "Wilson-McShane: International Brotherhood of Boilermakers",
  "Windsor Sterling Health Plan",
  "Winston Hospitality",
  "Wisconsin Assigned Risk Plan (HIRSP)",
  "Wisconsin Chronic Disease Program",
  "Wisconsin Department of Corrections",
  "Wisconsin Physicians Service (WPS) Commercial",
  "Wisconsin Well Womens Program",
  "Women's Integrated Network (WIN Health)",
  "Worksite Benefit Services, LLC",
  "Worthington Industries - CORVEL",
  "Writer's Guild Industry Health Plan",
  "Wyoming Health Solutions",
  "Xantus Health Plan",
  "Xcel Energy",
  "Ximed Medical Group",
  "Xylem Inc. - CORVEL",
  "YOUnity Medical Affiliates IPA",
  "Yale University Health Plan",
  "Yamhill CCO",
  "Yamhill COO Physical Health",
  "Yerington Paiute Tribe",
  "York Claim Services",
  "Youthcare Healthchoice Illinois",
  "Yuzu Health",
  "Z-Nat Insurance (Zenith National)",
  "Zachry Construction",
  "Zenith (FCHN)",
  "Zenith American Solutions - ILWU-PMA",
  "Zenith Insurance Co",
  "Zing Health",
  "Zoe Holding Company Inc. - CORVEL",
  "Zurich Farmers",
  "Zurich Insurance",
  "eternalHealth",
  "iCARE (Independent Care Health Plan)",
  "iCircle of New York",
  "iNetico Inc",
  "myNEXUS Anthem",
  "prnLink-MLK",
  "​Allstate - NJ Only",
  "​Amica Insurance Company",
  "​Chubb (legacy Chubb & Son) - Auto",
  "​Chubb (legacy Chubb & Son) - WC",
  "​Cure Auto Insurance For NJ Auto",
  "​Kentucky Health Administrators, Inc.",
  "​Longevity NY",
  "​MetLife New Jersey",
  "​Mutual of Omaha Medicare Advantage",
  "​OSF Healthcare Central",
  "​OSF Healthcare East",
  "​Provider Partners Health Plan of Illinois",
  "​State Auto Mutual Insurance Company",
  "​University of Maryland Health Advantage",
  "​VALLEY PRESBYTERIAN HOSPITAL – HCLA",
  "​VieCare LIFE Butler",
  "​​OMNI Administrators",
  "​​Scottsdale Insurance Co E&S",
];

export default payorNames;
