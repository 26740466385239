import React from "react";
import {
  AlertCircle,
  Clock,
  Shield,
  Hospital,
  PlusCircle,
  Target,
  Search,
  Fingerprint,
  ClipboardList,
} from "lucide-react";

const WhyTest1 = () => {
  return (
    <>
      {/* Respiratory panel */}
      <div className="min-h-screen/4 mt-12">
        <div className="max-w-7xl mx-auto rounded-xl overflow-hidden">
          <div className="flex flex-col justify-between md:flex-row">
            {/* Left side - Image */}
            <div className="w-full md:w-1/4 pb-4 mt-4 lg:w-1/3 flex text-gray-100 items-center justify-center">
              <h1 className="lg:text-5xl text-2xl font-bold">
                Respiratory Panel
              </h1>
            </div>

            {/* Right side - Content */}
            <div className="w-full md:w-1/2 p-2 md:p-8 lg:p-8">
              <div className="space-y-8">
                {/* Did You Know Section */}
                <div className="bg-transparent bg-opacity-30 tracking-wide backdrop-blur-md border border-blue-300 p-4 rounded-lg">
                  <h2 className="text-2xl font-bold text-gray-100 mb-3">
                    Did You Know?
                  </h2>
                  <p className="text-gray-100">
                    Adults experience{" "}
                    <span className="font-bold">
                      2-5 respiratory infections
                    </span>{" "}
                    annually, while children face{" "}
                    <span className="font-bold">7-10 infections</span> per year.
                  </p>
                </div>

                {/* Why Test Section */}
                <div>
                  <h2 className="text-2xl font-bold text-gray-100 mb-4">
                    Why Test?
                  </h2>
                  <div className="space-y-4">
                    <div className="flex items-start space-x-3">
                      <Hospital className="w-6 h-6 text-green-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">Better Patient Care:</span>{" "}
                        Precise diagnosis leads to optimal treatment strategies
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Shield className="w-6 h-6 text-red-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">Prevent Spread:</span> Early
                        detection helps contain infection spread
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Clock className="w-6 h-6 text-blue-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">Timely Treatment:</span>{" "}
                        Quick intervention for better outcomes
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <AlertCircle className="w-6 h-6 text-yellow-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">
                          Avoid Antibiotics Misuse:
                        </span>{" "}
                        Targeted treatment prevents resistance
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <PlusCircle className="w-6 h-6 text-purple-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">
                          Shorter Hospital Stays:
                        </span>{" "}
                        Accelerated recovery through accurate diagnosis
                      </p>
                    </div>
                  </div>
                </div>

                {/* Common Symptoms Section */}
                <div>
                  <h2 className="text-2xl font-bold text-gray-100 mb-4">
                    Common Symptoms
                  </h2>
                  <div className="p-4 rounded-lg">
                    <ul className="grid grid-cols-2 gap-3 text-gray-100">
                      <li className="flex items-center space-x-2">
                        <span className="w-2 h-2 bg-red-500 rounded-full"></span>
                        <span>Coughing</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                        <span>Nasal Discharge</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                        <span>Congestion</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span className="w-2 h-2 bg-yellow-500 rounded-full"></span>
                        <span>Fever</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span className="w-2 h-2 bg-purple-500 rounded-full"></span>
                        <span>Wheezing</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span className="w-2 h-2 bg-orange-500 rounded-full"></span>
                        <span>Headache</span>
                      </li>
                      <li className="flex items-center space-x-2">
                        <span className="w-2 h-2 bg-pink-500 rounded-full"></span>
                        <span>Myalgia</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-gray-100 flex justify-center">
        | &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp;
        &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; -
        &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp;
        &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; -
        &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp;
        &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; -
        &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; |
      </div>

      {/* Urinary Pathogen */}
      <div className="min-h-screen/4 mt-12">
        <div className="max-w-7xl mx-auto rounded-xl overflow-hidden">
          <div className="flex flex-col justify-between md:flex-row">
            {/* Left side - Image */}
            <div className="w-full md:w-1/4 pb-4 mt-4 lg:w-1/3 flex text-gray-100 items-center justify-center">
              <h1 className="lg:text-5xl text-2xl font-bold">Urinary Panel</h1>
            </div>

            {/* Right side - Content */}
            <div className="w-full md:w-1/2 p-2 md:p-8 lg:p-8">
              <div className="space-y-8">
                {/* Test Description Section */}
                <div className="bg-transparent bg-opacity-30 tracking-wide backdrop-blur-md border border-blue-300 p-4 rounded-lg">
                  <h2 className="text-2xl font-bold text-gray-100 mb-3">
                    Urinary Pathogen Test
                  </h2>
                  <p className="text-gray-100">
                    This advanced assay identifies microbial DNA from pathogens
                    responsible for UTIs, delivering precise results and
                    tailored antibiotic guidance.
                  </p>
                </div>

                {/* Benefits Section */}
                <div>
                  <h2 className="text-2xl font-bold text-gray-100 mb-4">
                    Benefits of PCR Technology
                  </h2>
                  <div className="space-y-4">
                    <div className="flex items-start space-x-3">
                      <p className="text-gray-100">
                        <span className="font-bold">Precision:</span> Delivers
                        highly specific pathogen identification
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <p className="text-gray-100">
                        <span className="font-bold">
                          Reduced Time to Results:
                        </span>{" "}
                        Faster diagnosis compared to traditional methods
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <p className="text-gray-100">
                        <span className="font-bold">Accuracy:</span> Minimizes
                        errors, ensuring reliable outcomes
                      </p>
                    </div>
                  </div>
                </div>

                {/* Why Test Section */}
                <div>
                  <h2 className="text-2xl font-bold text-gray-100 mb-4">
                    Why Test?
                  </h2>
                  <div className="space-y-4">
                    <div className="flex items-start space-x-3">
                      <Hospital className="w-6 h-6 text-green-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">Better Patient Care:</span>{" "}
                        Precise diagnosis leads to optimal treatment strategies
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Shield className="w-6 h-6 text-red-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">Prevent Spread:</span> Early
                        detection helps contain infection spread
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Clock className="w-6 h-6 text-blue-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">Timely Treatment:</span>{" "}
                        Quick intervention for better outcomes
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <AlertCircle className="w-6 h-6 text-yellow-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">
                          Avoid Antibiotics Misuse:
                        </span>{" "}
                        Targeted treatment prevents resistance
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <PlusCircle className="w-6 h-6 text-purple-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">
                          Shorter Hospital Stays:
                        </span>{" "}
                        Accelerated recovery through accurate diagnosis
                      </p>
                    </div>
                  </div>
                </div>

                {/* Recommended For Section */}
                <div>
                  <h2 className="text-2xl font-bold text-gray-100 mb-4">
                    This test is recommended for:
                  </h2>
                  <ul className="grid grid-cols-2 gap-3 text-gray-100">
                    <li className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-red-500 rounded-full"></span>
                      <span>Recurrent UTIs</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
                      <span>Interstitial cystitis</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                      <span>Pyelonephritis</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-yellow-500 rounded-full"></span>
                      <span>UTIs in men</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-purple-500 rounded-full"></span>
                      <span>Prostate infections in men</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-orange-500 rounded-full"></span>
                      <span>Pregnancy-related UTIs</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-pink-500 rounded-full"></span>
                      <span>Age 55 or older</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-indigo-500 rounded-full"></span>
                      <span>History of urinary culture</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span className="w-2 h-2 bg-teal-500 rounded-full"></span>
                      <span>Diabetes</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-gray-100 flex justify-center">
        | &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp;
        &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; -
        &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp;
        &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; -
        &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp;
        &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; -
        &nbsp; &nbsp; - &nbsp; &nbsp; - &nbsp; &nbsp; |
      </div>

      {/* Cancer Panel */}
      <div className="min-h-screen/4 mt-12">
        <div className="max-w-7xl mx-auto rounded-xl overflow-hidden">
          <div className="flex flex-col justify-between md:flex-row">
            {/* Left side - Title */}
            <div className="w-full md:w-1/4 pb-4 mt-4 lg:w-1/3 flex text-gray-100 items-center justify-center">
              <h1 className="lg:text-5xl text-2xl font-bold">Cancer Panel</h1>
            </div>

            {/* Right side - Content */}
            <div className="w-full md:w-1/2 p-2 md:p-8 lg:p-8">
              <div className="space-y-8">
                {/* Who Should Consider It Section */}
                <div>
                  <h2 className="text-2xl font-bold text-gray-100 mb-4">
                    Who Should Consider It?
                  </h2>
                  <div className="space-y-4">
                    <div className="flex items-start space-x-3">
                      <Target className="w-6 h-6 text-blue-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        Individuals with a{" "}
                        <span className="font-bold">
                          family history of cancer
                        </span>
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Search className="w-6 h-6 text-green-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        Those seeking{" "}
                        <span className="font-bold">early detection</span> of
                        hereditary cancer risks
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Fingerprint className="w-6 h-6 text-purple-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        Patients diagnosed with cancer needing{" "}
                        <span className="font-bold">
                          personalized treatment options
                        </span>
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Target className="w-6 h-6 text-red-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        People exploring{" "}
                        <span className="font-bold">targeted therapies</span>{" "}
                        based on genetic profiling
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <ClipboardList className="w-6 h-6 text-yellow-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        Anyone interested in{" "}
                        <span className="font-bold">
                          proactive, precision-based healthcare
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                {/* Why Test Section */}
                <div className="bg-transparent bg-opacity-30 tracking-wide backdrop-blur-md border border-blue-300 p-4 rounded-lg">
                  <h2 className="text-2xl font-bold text-gray-100 mb-4">
                    Why Test?
                  </h2>
                  <div className="space-y-4">
                    <div className="flex items-start space-x-3">
                      <Search className="w-6 h-6 text-green-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">Early Detection:</span>{" "}
                        Identify risks before symptoms appear
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Fingerprint className="w-6 h-6 text-blue-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">
                          Personalized Treatment:
                        </span>{" "}
                        Tailor therapies to your genetic profile
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <Target className="w-6 h-6 text-purple-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">
                          Targeted Therapy Options:
                        </span>{" "}
                        Discover effective treatment plans
                      </p>
                    </div>
                    <div className="flex items-start space-x-3">
                      <ClipboardList className="w-6 h-6 text-yellow-600 flex-shrink-0 mt-1" />
                      <p className="text-gray-100">
                        <span className="font-bold">
                          Proactive Health Planning:
                        </span>{" "}
                        Take control of your future
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyTest1;
