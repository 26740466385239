import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, X } from "lucide-react";

function HeroSection({ onFormButtonClick }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="relative flex flex-col items-center min-h-fit overflow-hidden">
        {/* Navbar */}
        <div className="relative w-full z-20">
          <div className="p-4">
            {/* Mobile Menu Button */}
            <button
              onClick={toggleMenu}
              className="md:hidden text-white hover:text-blue-200 transition-colors"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            {/* Navigation Links */}
            <div
              className={`${
                isMenuOpen ? "flex" : "hidden"
              } md:flex flex-col md:flex-row md:space-x-4 absolute backdrop-blur-md  bg-transparent bg-opacity-30 md:static left-0 right-0 top-full bg-gray-900 md:bg-transparent  p-4 space-y-2 md:space-y-0 shadow-lg md:shadow-none transition-all duration-300`}
            >
              <Link to="/wellness-guide" className="w-full md:w-auto">
                <button className="w-full md:w-auto min-w-[120px] bg-blue-000 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full transition duration-300">
                  Wellness Guide
                </button>
              </Link>

              <Link to="/feedback" className="w-full md:w-auto">
                <button className="w-full md:w-auto min-w-[120px] hover:bg-blue-800 text-white font-bold py-1 px-2 rounded-full border-white transition duration-300">
                  Feedback
                </button>
              </Link>
              <Link to="/contact" className="w-full md:w-auto">
                <button className="w-full md:w-auto min-w-[120px] bg-blue-000 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded-full transition duration-300">
                  Contacts
                </button>
              </Link>
              <button
                onClick={onFormButtonClick}
                className="w-full md:w-auto min-w-[120px] hover:bg-blue-800 text-white font-bold py-1 px-2 rounded-full border-white transition duration-300"
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className="relative flex flex-col items-center w-full mx-4 z-10 pt-10 pb-16 md:pt-30 md:pb-16">
          <h1 className="text-3xl md:text-6xl lg:text-7xl text-center tracking-wide animate-slide-in-right">
            <span className="font-semibold items-start font-roman bg-gradient-to-r from-white via-blue-100 to-blue-200 text-transparent bg-clip-text">
              Precision Testings For
            </span>
          </h1>
          <h1 className="text-3xl md:text-5xl lg:text-6xl text-center tracking-wide animate-slide-in-right mt-2">
            <span className="font-semibold font-roman bg-gradient-to-r from-blue-100 via-blue-200 to-blue-300 text-transparent bg-clip-text">
              A Healthier Future
            </span>
          </h1>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
