import React from "react";
import { Link } from "react-router-dom";
// import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-[800px] mx-auto px-8 py-6 bg-white">
      <div className="space-y-12">
        <div className="mb-8">
          <h1 className="text-3xl font-bold mb-2">PRIVACY POLICY</h1>
          <p className="text-gray-600">
            Date of last revision: December 19, 2024
          </p>
        </div>

        <section>
          <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
          <div className="text-gray-800 leading-relaxed">
            <p>
              This Privacy Policy describes the information processing of
              <span className="font-bold">&nbsp;examinemyhealth.com</span>. This
              Privacy Policy applies only to this Site and its associated
              healthcare survey and testing services. It outlines how we
              collect, use, protect, and manage your personal and health
              information. By using our Site, you agree to the terms of this
              Privacy Policy and consent to the collection, use, and disclosure
              of your information as described below.
            </p>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-bold mb-4">
            2. Information Collection Practices
          </h2>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3">
              I. Types of Information Collected
            </h3>
            <div className="text-gray-800 leading-relaxed space-y-4">
              <p>
                Personal Information refers to information that you provide to
                us when using our Site or communicating with our customer care
                team. This includes your name, mailing address, email address,
                telephone number, date of birth, insurance information, and
                other identifying information necessary for our services. We
                also collect health-related information including your medical
                history, current health conditions, medications, treatments,
                family medical history, test results, and responses to health
                surveys.
              </p>
              <p>
                Technical Information is automatically collected when you visit
                our Site. This includes your IP address, browser type and
                language, access times, operating system, referring website
                addresses, and the pages you visit on our Site. We use this
                information to improve our services and ensure proper site
                functionality.
              </p>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3">
              II. Uses of Information Collected
            </h3>
            <p className="text-gray-800 leading-relaxed">
              We use your information to operate and maintain our healthcare
              survey and testing services. This includes processing your test
              appointments, creating and maintaining your account, communicating
              test results, coordinating with insurance providers, and sending
              important notifications about your healthcare services. We may
              also use your information to improve our services, conduct
              research using anonymized data, and comply with legal and
              regulatory requirements.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3">
              III. Cookies and Web Beacons
            </h3>
            <p className="text-gray-800 leading-relaxed">
              Our Site uses cookies and similar tracking technologies to enhance
              your experience. These technologies help us understand site
              traffic patterns, maintain security, remember your preferences,
              and provide you with relevant content. You can control cookie
              settings through your browser preferences, though this may impact
              some Site functionality.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3">
              IV. Sharing of Personal Information
            </h3>
            <p className="text-gray-800 leading-relaxed">
              We may share your information with healthcare providers involved
              in your care, insurance companies for billing purposes, laboratory
              and testing facilities, and regulatory authorities as required by
              law. We also work with service providers who assist in operating
              our Site and providing our services. These providers are bound by
              confidentiality agreements and are prohibited from using your
              information for other purposes.
            </p>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-bold mb-4">
            3. Confidentiality and Security of Personal Information
          </h2>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3">
              I. User Access and Control
            </h3>
            <p className="text-gray-800 leading-relaxed">
              You can access and update your personal information through your
              account settings on our Site. This includes your contact
              information, insurance details, and communication preferences. If
              you need assistance managing your information, our customer care
              team is available to help. You may also request a copy of your
              health records or restrict certain information sharing.
            </p>
          </div>

          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-3">
              II. Security Measures
            </h3>
            <p className="text-gray-800 leading-relaxed">
              We implement comprehensive security measures to protect your
              information. This includes encryption of sensitive data, strict
              access controls, regular security audits, employee training
              programs, and secure data storage systems. While we maintain
              appropriate safeguards, no system is completely secure, and we
              cannot guarantee the absolute security of your information.
            </p>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-bold mb-4">4. Third-Party Sites</h2>
          <p className="text-gray-800 leading-relaxed">
            Our Site may contain links to other websites not operated by us.
            This Privacy Policy does not apply to third-party sites. We
            recommend reviewing the privacy policies of any external sites you
            visit through links on our Site.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-bold mb-4">
            5. Updates to Privacy Policy
          </h2>
          <p className="text-gray-800 leading-relaxed">
            We reserve the right to update this Privacy Policy at any time. Any
            changes will be posted on this page with a revised "last updated"
            date. Continued use of our Site after such changes constitutes
            acceptance of the updated Privacy Policy.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-bold mb-4">6. Children's Information</h2>
          <p className="text-gray-800 leading-relaxed">
            Our services are not intended for users under 16 years of age. We do
            not knowingly collect personal information from children. If we
            discover that we have collected information from a child under 16,
            we will promptly delete such information.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-bold mb-4">
            7. Healthcare Privacy Rights
          </h2>
          <p className="text-gray-800 leading-relaxed">
            Under healthcare privacy laws, you have specific rights regarding
            your health information. You may request access to your health
            records, obtain copies of test results, restrict certain information
            sharing, and receive confidential communications about your
            healthcare. You also have the right to file complaints about privacy
            practices with relevant authorities.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-bold mb-4">8. HIPAA Compliance</h2>
          <p className="text-gray-800 leading-relaxed">
            Our practices comply with the Health Insurance Portability and
            Accountability Act (HIPAA). We maintain administrative safeguards,
            physical security measures, and technical protections as required by
            law. Our staff receives regular training on privacy procedures, and
            we conduct routine compliance audits to ensure proper handling of
            protected health information.
          </p>
        </section>
        <section>
          <h2 className="text-2xl font-bold mb-4">9. Contact Information</h2>
          <div className="text-gray-800 leading-relaxed">
            <p className="mb-4 font-bold">
              Email:&nbsp;
              <a
                href="mailto:contactus@rsbhealthcareconsulting.com"
                className="text-blue-600 hover:text-blue-800"
              >
                contactus@examinemyhealth.com
              </a>
            </p>
          </div>
        </section>

        <p className="text-gray-600 mt-8">
          This Privacy Policy was last updated on December 19, 2024.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
