import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner.js";

function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    type: "contact",
    name: "",
    email: "",
    message: "",
  });

  AWS.config.update({
    region: "ap-south-1",
    credentials: new AWS.Credentials({
      accessKeyId: process.env.REACT_APP_LAMBDA_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_LAMBDA_ACCESS_SECRET,
    }),
  });

  const lambda = new AWS.Lambda();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      const alphabetOnly = value.replace(/[^A-Za-z\s]/g, "");
      setFormData({
        ...formData,
        [name]: alphabetOnly,
      });
    } else if (name === "email") {
      const validValue = value.replace(/[^A-Za-z0-9.@]/g, "");

      setFormData({
        ...formData,
        [name]: validValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;

    const validValue = value.replace(/[^A-Za-z0-9.]/g, "");

    setFormData((prev) => ({
      ...prev,
      email: validValue + "@gmail.com",
    }));
  };
  const getDisplayEmail = (email) => {
    return email ? email.replace("@gmail.com", "") : "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setFormData({
      type: "contact",
      name: "",
      email: "",
      message: "",
    });

    const params = {
      FunctionName: "insert_surveyform_response",
      Payload: JSON.stringify(formData),
    };

    try {
      const response = await lambda.invoke(params).promise();

      if (response.FunctionError) {
        throw new Error(
          JSON.parse(response.Payload).errorMessage ||
            "An error occurred while processing your submission."
        );
      }

      setIsLoading(false);
      navigate("/");
    } catch (error) {
      alert("Error is occurred");
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-400 p-4">
      <div className="bg-slate-200 shadow-lg rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">
          Contact Us
        </h2>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-10">
            <LoadingSpinner />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-semibold text-gray-600"
            >
              Name
            </label>
            <input
              type="text"
              placeholder="Your name"
              id="name"
              name="name"
              required
              value={formData.name}
              onChange={handleInputChange}
              className="w-full p-2 bg-slate-100 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-gray-600"
            >
              Email
            </label>
            <div>
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="relative flex-grow flex items-center">
                  <input
                    type="text"
                    className="w-full p-2 border bg-slate-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="*Email Id"
                    id="email"
                    name="email"
                    value={getDisplayEmail(formData.email)}
                    onChange={handleEmailChange}
                    minLength={6}
                    maxLength={30}
                    required={true}
                    pattern="^[A-Za-z0-9]{6,30}$"
                    autoComplete="off"
                  />
                  <span className="text-gray-600 absolute right-3 pointer-events-none">
                    @gmail.com
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="message"
              className="block text-sm font-semibold text-gray-600"
            >
              Message
            </label>
            <textarea
              id="message"
              placeholder="Type here..."
              name="message"
              required
              rows="4"
              value={formData.message}
              onChange={handleInputChange}
              className="w-full p-2 border bg-slate-100 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 transition duration-200"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
