import React, { useRef } from "react";
import HeroSection from "../heroSection/HeroSection.js";
import Tests from "../tests/Tests.js";
import Whytest1 from "../whytest1/Whytest1.js";
import Review from "../review/Review.js";
import Footer from "../footer/Footer.js";
import Workflow from "../workflow/Workflow.js";

import bgImg9 from "../../images/bgImg9.jpg";

function Landing() {
  const testsRef = useRef(null);

  const scrollToTests = () => {
    testsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="relative w-full h-full">
      <div
        className="fixed inset-0 h-full w-full -z-10 bg-cover bg-center"
        style={{
          backgroundImage: `url(${bgImg9})`,
          filter: "blur(3px)",
          opacity: 50,
        }}
      ></div>

      <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/60 to-black/80" />
      <div className="absolute inset-0 bg-gradient-to-b from-blue-900/30 via-blue-800/20 to-blue-900/5" />

      <div className="relative w-full h-full overflow-y-auto">
        <HeroSection onFormButtonClick={scrollToTests} />

        <div ref={testsRef}>
          <Tests />
        </div>

        <Workflow />
        <Whytest1 />
        <Review />
      </div>

      <Footer />
    </div>
  );
}

export default Landing;
