import React from 'react';

const ResponsiveTerms = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 px-6 py-8 sm:px-8">
            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white">
              Terms of Service
            </h1>
            <p className="mt-2 text-gray-300">Last Updated: December 19, 2024</p>
          </div>

          {/* Content Sections */}
          <div className="p-6 sm:p-8 space-y-8">
            {/* Survey Objectives Section */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">
                1. Survey Objectives and Scope
              </h2>
              
              <div className="space-y-6">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    1.1 Primary Research Goals
                  </h3>
                  <p className="text-gray-700 mb-4">
                    Our research initiative focuses on comprehensive market analysis, including detailed product interest 
                    assessment and market demand evaluation. We conduct in-depth studies to understand feature preferences 
                    and price sensitivity across different market segments.
                  </p>
                  <p className="text-gray-700">
                    Through systematic brand awareness measurement and competitive positioning analysis, we aim to better 
                    understand our market position and identify opportunities for growth. Customer segmentation studies 
                    help us tailor our offerings to specific market needs.
                  </p>
                </div>

                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    1.2 Data Collection Purpose
                  </h3>
                  <p className="text-gray-700 mb-4">
                    The collected data directly influences our product development process and helps optimize our marketing 
                    strategies. We use these insights to enhance customer experience and improve our distribution planning.
                  </p>
                  <p className="text-gray-700">
                    This information is crucial for developing effective pricing strategies and refining our brand positioning 
                    in the market, ensuring we meet customer needs while maintaining competitive advantage.
                  </p>
                </div>
              </div>
            </section>

            {/* Information Collection Section */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">
                2. Information Collection and Usage
              </h2>
              
              <div className="space-y-6">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    2.1 Personal Information
                  </h3>
                  <p className="text-gray-700 mb-4">
                    We collect essential professional information including names, titles, and contact details of participants. 
                    This extends to company affiliations and industry sector information to ensure accurate market segmentation.
                  </p>
                  <p className="text-gray-700">
                    Geographic location data and professional role information help us understand regional trends and 
                    decision-making patterns. Purchase authority information ensures we gather insights from relevant 
                    stakeholders.
                  </p>
                </div>

                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    2.2 Preference Information
                  </h3>
                  <p className="text-gray-700 mb-4">
                    We gather detailed information about product interests and feature priorities to guide our development 
                    process. Budget parameters and purchase timeframes help us understand market constraints and opportunities.
                  </p>
                  <p className="text-gray-700">
                    Understanding usage scenarios and technical requirements enables us to better align our solutions with 
                    customer needs. Service preferences information helps optimize our support offerings.
                  </p>
                </div>
              </div>
            </section>

            {/* Data Protection Section */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">
                3. Data Protection and Privacy
              </h2>
              
              <div className="space-y-6">
                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    3.1 Security Measures
                  </h3>
                  <p className="text-gray-700 mb-4">
                    We implement robust security measures including advanced encryption protocols and strict access controls 
                    to protect your data. Our data anonymization processes ensure confidentiality while maintaining 
                    analytical value.
                  </p>
                  <p className="text-gray-700">
                    Regular security audits and breach prevention measures are conducted to maintain data integrity. 
                    We have comprehensive recovery procedures in place to address any potential security incidents.
                  </p>
                </div>

                <div className="bg-gray-50 p-6 rounded-lg">
                  <h3 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                    3.2 Data Usage Policies
                  </h3>
                  <p className="text-gray-700 mb-4">
                    Collected data is primarily used for internal analysis and market research purposes. This information 
                    drives our product development process and helps optimize our marketing strategies.
                  </p>
                  <p className="text-gray-700">
                    We maintain strict quality control measures and ensure regulatory compliance in all our data handling 
                    processes. Your information helps us continuously improve our customer service standards.
                  </p>
                </div>
              </div>
            </section>

            {/* Contact Section */}
            <section>
              <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-6">
                6. Contact Information
              </h2>
              
              <div className="bg-gray-50 p-6 rounded-lg">
                <p className="text-gray-700 mb-4">
                  For any questions or concerns regarding these terms of service, please contact us at:
                </p>
                <p className="text-lg font-semibold">
                  <a href="mailto:contactus@examinemyhealth.com" 
                     className="text-blue-600 hover:text-blue-800 transition-colors">
                    contactus@examinemyhealth.com
                  </a>
                </p>
              </div>
            </section>

            {/* Footer Section */}
            <div className="mt-8 pt-6 border-t border-gray-200">
              <p className="text-sm text-gray-600">
                By participating in this survey, you acknowledge understanding and acceptance of these terms and conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveTerms;