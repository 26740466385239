import React from "react";
import {
  Activity,
  ThermometerSun,
  Home,
  Users,
  Shield,
  TestTube,
  HeartPulse,
} from "lucide-react";
import { Link } from "react-router-dom";
import Covidimg from "../../images/covid.jpg";
import { AnimatedButton } from "./Animatedbtn";

function InfoBox({ title, children, icon: Icon }) {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow border border-gray-700">
      <div className="flex items-center gap-3 mb-4">
        <Icon className="w-6 h-6 text-purple-400" />
        <h3 className="text-xl font-bold text-white">{title}</h3>
      </div>
      <div className="text-gray-300">{children}</div>
    </div>
  );
}

function Covid() {
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center py-24"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Covidimg})`,
        }}
      >
        <div className="container mx-auto px-6">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-purple-400">
            COVID-19: Why Early Detection Matters More Than Ever
          </h1>
          <p className="text-xl md:text-2xl max-w-3xl text-gray-300">
            Understanding the importance of early detection in managing COVID-19
            and protecting our communities.
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-6 py-12">
        {/* Introduction */}
        <div className="prose prose-invert max-w-none mb-12">
          <p className="text-xl text-gray-300 leading-relaxed">
            The moment you feel that slight scratch in your throat or unusual
            fatigue, your mind might drift to the possibility of COVID-19. While
            we've learned to live alongside this virus, understanding its impact
            and taking prompt action remains crucial for our collective
            well-being.
          </p>
        </div>

        {/* Symptoms Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          <InfoBox title="Respiratory Changes" icon={Activity}>
            <ul className="list-disc list-inside space-y-2">
              <li>Persistent dry cough</li>
              <li>Shortness of breath</li>
              <li>Changes in smell or taste</li>
            </ul>
          </InfoBox>

          <InfoBox title="Physical Discomfort" icon={ThermometerSun}>
            <ul className="list-disc list-inside space-y-2">
              <li>Unusual fatigue</li>
              <li>Body aches</li>
              <li>Fever or temperature elevation</li>
            </ul>
          </InfoBox>

          <InfoBox title="Gastrointestinal Issues" icon={Home}>
            <ul className="list-disc list-inside space-y-2">
              <li>Unexpected loss of appetite</li>
              <li>Nausea or digestive discomfort</li>
              <li>Changes in bowel habits</li>
            </ul>
          </InfoBox>
        </div>

        {/* Benefits Section */}
        <div className="bg-gray-800 rounded-xl p-8 mb-12">
          <h2 className="text-3xl font-bold mb-6 text-purple-400">
            Why Early Testing Matters
          </h2>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 mb-10 gap-6">
            <div className="flex gap-4">
              <Shield className="w-8 h-8 text-purple-400 flex-shrink-0" />
              <p className="text-gray-300">
                Prevent spreading to vulnerable family members
              </p>
            </div>
            <div className="flex gap-4">
              <TestTube className="w-8 h-8 text-purple-400 flex-shrink-0" />
              <p className="text-gray-300">Access early treatment options</p>
            </div>
            <div className="flex gap-4">
              <Users className="w-8 h-8 text-purple-400 flex-shrink-0" />
              <p className="text-gray-300">Alert close contacts promptly</p>
            </div>
          </div>

          <AnimatedButton>
            <Link to="/form/covid" className="w-full sm:w-auto">
              <div className="flex items-center gap-2">
                <HeartPulse size={20} />
                <span>Get Tested</span>
              </div>
            </Link>
          </AnimatedButton>
        </div>
      </div>
    </div>
  );
}

export default Covid;
